import { useCallback, useEffect } from 'react';
import { TSignInOption } from '../../components/organisms/auth/Login/SignInOptions';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AuthState, authActions } from './slice';
import { MfaOption } from '../../types/auth';

type AuthServiceOperators = AuthState & {
	login: (email: string, password: string, signInOption: TSignInOption) => void;
	verifyLogin: (
		code: string[],
		mfaToken: string,
		oobCode?: string | null
	) => void;
	logout: () => void;
	clearError: () => void;
	sendMfa: (mfaToken: string, selectedMfaOption: MfaOption) => void;
};

const useAuthService = (): Readonly<AuthServiceOperators> => {
	const dispatch = useAppDispatch();
	const authState = useAppSelector((state) => state.auth as AuthState);

	useEffect(() => {
		if (!authState.data.isAuthenticated && !authState.loading.refreshToken) {
			dispatch(authActions.refreshTokenRequest());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.data.isAuthenticated]);

	useEffect(() => {
		if (
			authState.data.isVerified &&
			!authState.data.user &&
			!authState.loading.user
		) {
			dispatch(authActions.currentUserRequest());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.data.isVerified]);

	return {
		data: authState.data,
		loading: authState.loading,
		error: authState.error,
		login: useCallback(
			(email, password, signInOption) => {
				dispatch(authActions.loginRequest({ email, password, signInOption }));
			},
			[dispatch]
		),
		verifyLogin: useCallback(
			(code: string[], mfaToken: string, oobCode?: string | null) => {
				dispatch(authActions.verificationRequest({ code, mfaToken, oobCode }));
			},
			[dispatch]
		),
		sendMfa: useCallback(
			(mfaToken: string, selectedMfaOption: MfaOption) => {
				dispatch(authActions.sendMfaRequest({ mfaToken, selectedMfaOption }));
			},
			[dispatch]
		),
		logout: useCallback(() => {
			dispatch(authActions.logout());
			localStorage.removeItem('accessToken'); // temporary while waiting for CSRF Token mechanism
		}, [dispatch]),
		clearError: useCallback(() => {
			dispatch(authActions.clearError());
		}, [dispatch]),
	};
};

export default useAuthService;
