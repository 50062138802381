import { ReactNode } from 'react';
import Tippy from '@tippyjs/react';
import { cx } from 'class-variance-authority';

interface ReactTooltipProps {
	className?: string;
	children: React.ReactNode;
	content?: ReactNode;
}

export const Tooltip = ({
	children,
	content,
	className,
}: ReactTooltipProps) => {
	if (!content) {
		return children;
	}
	return (
		<Tippy content={content}>
			<div className={cx('w-fit h-fit', className)}>{children}</div>
		</Tippy>
	);
};
