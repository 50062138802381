export const cryptocurrencies = [
	'BTC',
	'ETH',
	'USDT',
	'BNB',
	'SOL',
	'XRP',
	'TRX',
	'MATIC',
	'NEAR',
	'DOT',
	'LEO',
	'ETC',
	'CRO',
	'ATOM',
];

export const currencies = ['USD', 'NZD', 'EUR', 'BOB', 'AUD', 'CAD', 'UAN'];

export const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const addresses = [
	'bc1d4x0w7v9h8u4ks8lw5j7yq3h6p9lz2d',
	'bc1d4s9z5r2m8v0t6q1a3w7j9e4k2y8xpq',
	'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
	'bc1d4p6z7w3m8k5n2r1a9t4j2y0v8xqj5',
	'bc1d4n5t8v2r0p6k4a7m3j9y1x2z4w8q7',
];

export const riskLevels = ['low', 'medium', 'high', 'critical'];

export const statuses = ['in process', 'completed', 'rejected', 'expired'];

export const guarantee = ['yes', 'no'];
