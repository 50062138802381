import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { router } from './routes/router';
import { store } from './store/config.store';
import { Toaster } from 'react-hot-toast';
import './index.css';

import 'react-datepicker/dist/react-datepicker.css';

import 'tippy.js/dist/tippy.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { REOWN_PROJECT_ID } from './config';

import { createAppKit } from '@reown/appkit/react';
import { arbitrum, mainnet } from '@reown/appkit/networks';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { WagmiConfig } from 'wagmi';

const wagmiAdapter = new WagmiAdapter({
	networks: [mainnet, arbitrum],
	projectId: REOWN_PROJECT_ID,
});

createAppKit({
	adapters: [wagmiAdapter],
	networks: [mainnet, arbitrum],
	metadata: {
		name: 'Sailo Tech',
		description: 'Secure your Crypto with Money Back Guarantee',
		url: 'https://sailo.tech/',
		icons: ['https://sailo.tech/wp-content/uploads/2023/04/sailo_logo.png'],
	},
	projectId: REOWN_PROJECT_ID,
	features: {
		analytics: true,
		socials: false,
		email: false,
	},
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<WagmiConfig config={wagmiAdapter.wagmiConfig}>
			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<RouterProvider router={router} />
				</Provider>
			</QueryClientProvider>
		</WagmiConfig>
		<Toaster />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
