import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseNotificationData } from '../../types/notification';
import { GetNotificationsParams } from './api';

export type NotificationState = {
	data: {
		notification: ResponseNotificationData | null;
	};
	loading: {
		notification: boolean;
	};
	error: {
		notification: string | null;
	};
};

const initialState: NotificationState = {
	data: {
		notification: null,
	},
	loading: {
		notification: false,
	},
	error: {
		notification: null,
	},
};

const notificationSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		fetchNotificationsRequest: (
			state,
			_action: PayloadAction<GetNotificationsParams | undefined>
		) => {
			state.loading.notification = true;
			state.error.notification = null;
		},
		fetchNotificationsSuccess: (
			state,
			action: PayloadAction<ResponseNotificationData>
		) => {
			state.loading.notification = false;
			state.data.notification = action.payload;
		},
		fetchNotificationsFailure: (state, action: PayloadAction<string>) => {
			state.loading.notification = false;
			state.error.notification = action.payload;
		},
	},
});

export const notificationActions = {
	fetchNotificationsRequest:
		notificationSlice.actions.fetchNotificationsRequest,
	fetchNotificationsSuccess:
		notificationSlice.actions.fetchNotificationsSuccess,
	fetchNotificationsFailure:
		notificationSlice.actions.fetchNotificationsFailure,
};

export default notificationSlice.reducer;
