export const CHAIN_OPTIONS_CONFIG: Record<
	string,
	Array<{
		coin: string;
		type?: string;
	}>
> = {
	BTC: [
		{
			coin: 'BTC',
		},
	],
	ETH: [
		{
			coin: 'ETH',
		},
		{
			coin: 'USDT',
			type: 'ERC',
		},
	],
	TRX: [{ coin: 'TRX' }, { coin: 'USDT', type: 'TRC' }],
};
