const API_BASE_AUTH_URL = process.env.REACT_APP_API_BASE_AUTH_URL as string;
const API_BASE_TRANSACTION_URL = process.env
	.REACT_APP_API_BASE_TRANSACTION_URL as string;
const API_BASE_WALLET_URL = process.env.REACT_APP_API_BASE_WALLET_URL as string;
const API_BASE_MEMBER_URL = process.env.REACT_APP_API_BASE_MEMBER_URL as string;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY as string;
const REOWN_PROJECT_ID = process.env.REACT_APP_REOWN_PROJECT_ID as string;

export {
	API_BASE_AUTH_URL,
	API_BASE_TRANSACTION_URL,
	API_BASE_WALLET_URL,
	API_BASE_MEMBER_URL,
	SECRET_KEY,
	REOWN_PROJECT_ID,
};
