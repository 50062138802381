import * as React from 'react';

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuTrigger,
} from '../../atoms/DropdownMenu';
import ResponsiveIcon from '../../atoms/Icon';

import { iconMap } from '../../../theme/Icons';

import { cn } from '../../../libs/cn';

const DropdownInputFilter: React.FC<{
	items: string[];
	onChange?: (value: string) => void;
}> = ({ items, onChange }) => {
	const [selected, setSelected] = React.useState<string>('top');

	const handleSelect = (value: string) => {
		setSelected(value);
		onChange?.(value);
	};
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<div>
					<ResponsiveIcon
						icon={iconMap.filter}
						className={cn('hover:cursor-pointer')}
					/>
				</div>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56">
				<DropdownMenuRadioGroup value={selected} onValueChange={handleSelect}>
					{items.map((item) => (
						<DropdownMenuRadioItem key={item} value={item} className="gap-3">
							<ResponsiveIcon
								icon={iconMap.wallets}
								color={'var(--color-primary-300)'}
								size={30}
							/>
							<span className="text-base">{item}</span>
						</DropdownMenuRadioItem>
					))}
				</DropdownMenuRadioGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export { DropdownInputFilter };
