import React, {
	ComponentProps,
	KeyboardEventHandler,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { cn } from '../../libs/cn';
import { iconMap } from '../../theme/Icons';
// import { Button } from '../atoms/Button';
import { Badge } from '../atoms/Badge';
import ResponsiveIcon from '../atoms/Icon';
import { Input } from '../atoms/Input';
import {
	MultiSelector,
	MultiSelectorContent,
	MultiSelectorItem,
	MultiSelectorList,
	MultiSelectorTrigger,
} from '../atoms/MultiSelect';
import BaseCard from '../organisms/BaseCard';

import useSendTransactionsService from '../../controllers/transactions/service';
import { ResponsePostTransaction, RiskLvl } from '../../types/transaction';
import { RecursivePartial } from '../../types/util';
import { formatNumberWithPrefix } from '../../utils/number';
import { Button } from '../atoms/Button';
// import AddRecipientDialog from './dialogs/AddRecipientDialog';
import { useAppKit, useAppKitAccount } from '@reown/appkit/react';
import { parseEther } from 'viem';
import { useSendTransaction } from 'wagmi';
import { CHAIN_OPTIONS_CONFIG } from '../../constants/transactions';
import { getTransactionProgress } from '../../controllers/transactions/api';
import { getWalletExposure } from '../../controllers/wallet/api';
import { usePromise } from '../../hooks/usePromise';
import { useToast } from '../../hooks/useToast';
import { getWalletChain, WalletValidationResult } from '../../utils/chain';
import ProgressBar from '../atoms/ProgressBar';
import { RadioGroup, RadioGroupItem } from '../atoms/RadioGroup';
import RisksPopover from './popovers/RisksPopover';
import BackGuaranteeWidget from './widgets/BackGuaranteeWidget';

type QuickTransactionType = {
	onSend?: () => void;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

const quickTransactionWidgetBgImageClass =
	'bg-[url("https://s3-alpha-sig.figma.com/img/a28b/0eb3/cbe640868d6d2a981c03acca6a32292f?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eDj-416TXRPqYVFc~BLTsg~jXEA2MCM23vY7zYK4CRv6raAb2obX8T3RLLt1limVsqQW9q72b1yhcy1OTVzypkq75mMHZSiQc2G6p0~HumRftjFBRPnj-7Jg0lr6ezgdE3ZGVxstrPzJdc2z1gemXIm0-Uu3fa0fOFZV9H3KAjW2~l0lMxoCPvT9DmacONGGW1kd8d5m6pO15ZmB4wB84WZ-01hDpug0dEK9lZO1-zhNg9dFWrOF0HvayzCbVtW~LN67zdcsiOolMYgVzizNIoON70xr1Ru6p6s0ZUWp-7gvRuY6hit5dOUggSA1aWpor79SiKCWwh5Nzp78pHBCpQ__")]';

const CurrencyCheckBox = ({
	chain,
	width,
	multiSelectorProps,
	multiSelectorTriggerProps,
	multiSelectorListProps,
	multiSelectorItemProps,
}: {
	chain?: string;
	width?: number;
	multiSelectorProps?: RecursivePartial<ComponentProps<typeof MultiSelector>>;
	multiSelectorTriggerProps?: RecursivePartial<
		ComponentProps<typeof MultiSelectorTrigger>
	>;
	multiSelectorListProps?: RecursivePartial<
		ComponentProps<typeof MultiSelectorList>
	>;
	multiSelectorItemProps?: RecursivePartial<
		ComponentProps<typeof MultiSelectorItem>
	>;
}) => {
	const { supportedCoinsList } = useSendTransactionsService();

	const handleValueChange = (value: [string]) => {
		const lastItem = value.pop();
		const newValue = lastItem ? [lastItem] : [];
		// setValue(newValue);
		if (multiSelectorProps?.onValuesChange) {
			(multiSelectorProps as any).onValuesChange(newValue);
		}
	};

	const disabledOptions = useMemo(() => {
		if (!chain) return [];

		const chainOptions = CHAIN_OPTIONS_CONFIG[chain];

		return supportedCoinsList.filter(
			(coin) =>
				!chainOptions.find(
					(option) => option.coin?.toUpperCase() === coin?.toUpperCase()
				)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chain, supportedCoinsList]);

	return (
		<MultiSelector
			values={[multiSelectorProps?.value || supportedCoinsList?.[0]]}
			onValuesChange={handleValueChange}
			loop
			className={cn(
				'text-white w-[5.7rem] relative',
				multiSelectorProps?.className
			)}
			disabled={multiSelectorProps?.disabled}
		>
			<div className="absolute h-[28px] top-2 bg-gray-300 border-l"></div>
			<MultiSelectorTrigger
				className={cn(
					'border rounded-tl-none rounded-bl-none border-l-0 border-white',
					multiSelectorTriggerProps?.className
				)}
			/>
			<MultiSelectorContent>
				<MultiSelectorList
					className={cn(
						'bg-black w-[5.5rem] p-0 absolute top-0 right-0',
						multiSelectorListProps?.className
					)}
					style={{ width: width && width > 0 ? width : 'initial' }}
				>
					{supportedCoinsList.map((item, index) => (
						<MultiSelectorItem
							key={index}
							value={item}
							color={multiSelectorItemProps?.color || 'white'}
							className="gap-2"
							checkBoxClassName={cn(
								'border-white',
								multiSelectorItemProps?.checkBoxClassName
							)}
							disabled={disabledOptions.includes(item)}
						>
							{item}
						</MultiSelectorItem>
					))}
				</MultiSelectorList>
			</MultiSelectorContent>
		</MultiSelector>
	);
};

const CurrencyConvertor = ({
	className,
	triggerClassName,
}: {
	className?: string;
	triggerClassName?: string;
}) => {
	return (
		<MultiSelector
			values={['USD']}
			onValuesChange={() => {}}
			loop
			className={cn('text-white w-[5.7rem] relative', className)}
		>
			<div className="absolute h-[28px] top-2 bg-gray-300 border-l"></div>
			<MultiSelectorTrigger
				hideArrow
				className={cn(
					'border rounded-tl-none rounded-bl-none border-l-0 border-white mt-0',
					triggerClassName
				)}
			/>
		</MultiSelector>
	);
};

interface SelectInputProps {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: () => void;
	onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
	width?: number;
	className?: string;
	loading?: boolean;
	disabled?: boolean;
	currencyCheckBoxProps?: ComponentProps<typeof CurrencyCheckBox>;
}

export const SelectInput = React.forwardRef<HTMLDivElement, SelectInputProps>(
	(
		{
			value,
			onBlur,
			onKeyDown,
			disabled,
			onChange,
			width,
			loading,
			className,
			currencyCheckBoxProps,
		},
		ref
	) => {
		return (
			<div ref={ref} className="flex items-center sm:w-1/2">
				<Input
					className={cn(
						'border-white text-white rounded-br-none rounded-tr-none border-r-0 !text-sm min-h-[42px]',
						className
					)}
					rootClassName="w-full"
					value={value ?? ''}
					onChange={onChange}
					type="number"
					disabled={disabled}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					icon={loading ? iconMap.spinner : undefined}
					iconProps={{
						color: 'white',
						className:
							'animate-spin w-6 h-6 [&>path:nth-child(1)]:fill-white [&>path:nth-child(2)]:fill-wallets-lowtext',
					}}
				/>
				<CurrencyCheckBox {...currencyCheckBoxProps} width={width} />
			</div>
		);
	}
);
SelectInput.displayName = 'SelectInput';

interface CurrencyInputProps {
	value: string | undefined;
	onChange: () => void;
	className?: string;
	currencyConvertorProps?: {
		className?: string;
		triggerClassName?: string;
	};
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
	value,
	onChange,
	className,
	currencyConvertorProps,
}) => {
	return (
		<div className="flex items-center sm:w-1/2">
			<Input
				className={cn(
					'border-white text-white rounded-br-none rounded-tr-none border-r-0 cursor-not-allowed',
					className
				)}
				rootClassName="w-full"
				value={value ? value : ''}
				onChange={onChange}
			/>
			<CurrencyConvertor
				className={currencyConvertorProps?.className}
				triggerClassName={currencyConvertorProps?.triggerClassName}
			/>
		</div>
	);
};

interface FeesBannerProps {
	hideMoneyBack: boolean;
	coin?: string;
	fee?: number;
	usdRate?: number;
}

const FeesBanner: React.FC<FeesBannerProps> = (props) => {
	const { coin, fee: bitCoin = 0, usdRate = 0 } = props;

	const icon = iconMap[coin?.toLowerCase() as keyof typeof iconMap];

	return (
		<div className="flex gap-4 sm:gap-8 text-white flex-wrap">
			<div className="flex gap-6 pt-8">
				<p className="flex gap-1 items-center">
					<ResponsiveIcon icon={iconMap.hourglass} />
					<span>Transaction Fees</span>
				</p>
				<div className="flex gap-3">
					<p className="flex gap-1 items-center">
						{icon && (
							<ResponsiveIcon icon={icon} className="h-[18px] stroke-white" />
						)}
						{!icon && !!coin && <>{coin}</>}
						<span>{formatNumberWithPrefix(bitCoin)}</span>
					</p>
					<p className="flex gap-1 items-center">
						<ResponsiveIcon icon={iconMap.dollar} />
						<span>{formatNumberWithPrefix(bitCoin * usdRate)}</span>
					</p>
				</div>
				{/* {hideMoneyBack && (
				<p className="flex gap-1 items-center">
					<ResponsiveIcon icon={iconMap.hourglass} />
					<span className="leading-5">
						Money Back <br /> Guarantee Fees{' '}
					</span>
				</p>
			)} */}
			</div>
			<div className="flex flex-col gap-6">
				{/* {hideMoneyBack && (
				<div className="flex gap-3">
					<p className="flex gap-1 items-center">
						<ResponsiveIcon icon={iconMap.bitcoin} />
						<span>0.05</span>
					</p>
					<p className="flex gap-1 items-center">
						<ResponsiveIcon icon={iconMap.dollar} />
						<span>3265.05</span>
					</p>
				</div>
			)} */}
			</div>
		</div>
	);
};

export const InformationBanner = ({
	value,
	risks,
	onClick,
	className,
}: {
	value: RiskLvl;
	risks?: string[];
	onClick: () => void;
	className?: string;
}) => (
	<div className={cn('flex gap-5 text-white mb-8', className)}>
		<Badge variant={value} onClick={onClick}>
			{value}
		</Badge>
		<RisksPopover risks={risks} />
	</div>
);

const QuickTransaction: React.FC<QuickTransactionType> = () => {
	const [recipient, setRecipient] = useState('');
	const [usdtChain, setUsdtChain] = useState('');
	const [currencyAmount, setCurrencyAmount] = useState('');
	const [convertedAmount, setConvertedAmount] = useState<number>(0);
	const [coin, setCoin] = useState<string>('');
	const [checked, setChecked] = useState(false);
	const [status, setStatus] = useState<RiskLvl>();
	const [width, setWidth] = useState(0);
	const [walletChain, setWalletChain] = useState<WalletValidationResult | null>(
		null
	);
	const { warning } = useToast();

	const { open } = useAppKit();
	const { isConnected } = useAppKitAccount();

	const { sendTransactionAsync, isPending: isWalletConnectPending } =
		useSendTransaction();

	const isNonBTC = coin !== 'BTC';
	const isDisconnectedAndNonBTC = isNonBTC && !isConnected;

	const {
		sendQuickTransaction,
		fetchMarketDataPrice,
		data,
		loading,
		clearQuickTransaction,
	} = useSendTransactionsService();

	const clearFields = () => {
		setRecipient('');
		setCurrencyAmount('');
		setConvertedAmount(0);
		setChecked(false);
		setStatus(undefined);
		clearQuickTransaction();
	};

	const isFormDisabled = !recipient || !currencyAmount || !coin;

	const [transactionDetails, setTransactionDetails] =
		useState<ResponsePostTransaction | null>();
	const [isTransactionDetailsLoading, setIsTransactionDetailsLoading] =
		useState(false);

	const fetchWalletExposure = usePromise({
		promiseFunction: async (walletAddress: string, chain: string) => {
			const response = await getWalletExposure({
				walletAddress,
				chain,
			});
			setStatus(response.data.data.risk_score);
			return response.data;
		},
	});

	const handleSubmitTransaction = async () => {
		try {
			if (
				!recipient ||
				!currencyAmount ||
				!coin ||
				(coin?.toUpperCase() === 'USDT' && !usdtChain?.length)
			)
				return;

			setTransactionDetails(null);
			setIsTransactionDetailsLoading(true);
			const response = await sendQuickTransaction({
				address: recipient,
				amount: Number(currencyAmount),
				coin,
				chain: coin?.toUpperCase() === 'USDT' ? usdtChain : coin,
				useMbg: checked,
			});

			setStatus(
				response.data.outputs?.[0]?.risk_score?.toLowerCase() as RiskLvl
			);

			setTransactionDetails(response.data);
		} catch (error: any) {
			warning(error?.toString());
		} finally {
			setIsTransactionDetailsLoading(false);
		}
	};

	const handleSubmitWallet = async () => {
		if (isDisconnectedAndNonBTC) {
			await open();
			return;
		}
		let isSuccess = false;
		try {
			if (!transactionDetails) {
				return;
			}

			if (coin === 'BTC') {
				isSuccess = await handleBitcoinTransaction(
					transactionDetails.input_address,
					transactionDetails.total_amount
				);
			} else {
				isSuccess = await handleWalletConnect(
					transactionDetails.input_address,
					transactionDetails.total_amount.toString()
				);
			}
		} catch (error) {
			console.error(error);
		}
		if (isSuccess) {
			setTransactionDetails(null);
			setCurrencyAmount('');
			setConvertedAmount(0);
		}
	};

	const selectRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (coin?.length) {
			fetchMarketDataPrice(coin, 'USD');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coin]);

	useEffect(() => {
		if (usdtChain?.length) {
			handleSubmitTransaction();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usdtChain]);

	useEffect(() => {
		if (currencyAmount) {
			calculateConvertedAmount(Number(currencyAmount));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.marketDataPrice?.usd]);

	useEffect(() => {
		if (selectRef?.current) {
			setWidth(selectRef.current.clientWidth);

			const getwidth = () => {
				if (selectRef?.current) {
					setWidth(selectRef.current.clientWidth);
				}
			};
			window.addEventListener('resize', getwidth);
			return () => window.removeEventListener('resize', getwidth);
		}
	}, []);

	useEffect(() => {
		if (recipient) {
			const address = recipient.trim();
			const walletChain = getWalletChain(address);
			setWalletChain(walletChain);

			if (walletChain?.isValid) {
				const defaultSelectedCoin =
					CHAIN_OPTIONS_CONFIG[walletChain.chain]?.[0];

				setCoin(defaultSelectedCoin.coin);
				setUsdtChain(defaultSelectedCoin.type || '');
			}
		} else {
			setWalletChain(null);
			setCoin('');
		}
	}, [recipient]);

	const currentChainConfig = useMemo(() => {
		if (walletChain?.isValid) {
			return CHAIN_OPTIONS_CONFIG[walletChain.chain];
		}
		return null;
	}, [walletChain]);

	const calculateConvertedAmount = (value: number) => {
		const convertedValue = Number(value) * (data.marketDataPrice?.usd || 0);

		if (convertedValue && !isNaN(convertedValue) && convertedValue > 0) {
			setConvertedAmount(convertedValue);
		} else {
			setConvertedAmount(0);
		}
	};

	const handleRiskStatus = () => {
		return;
		// mock function to set risk status to 'critical'
		// setStatus(RiskLvl.critical);
	};

	const handleChecked = () => {
		setChecked((prev) => !prev);
	};

	const [isError, setIsError] = useState(false);
	// const [processingTime] = useState(5);

	const toggleErrorMessage = () => {
		setIsError((prev) => !prev);
	};

	const handleCurrencyAmountChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		calculateConvertedAmount(Number(e.target.value));
		setCurrencyAmount(e.target.value);
		if (!e.target.value) {
			clearQuickTransaction();
		}
	};

	const handleBitcoinTransaction = (
		inputAddress: string,
		totalAmount: string
	) => {
		try {
			const bitcoinUrl = `bitcoin:${inputAddress}?amount=${totalAmount}`;
			window.location.href = bitcoinUrl;
			return true;
		} catch (error) {
			window.open('https://bitcoin.org/en/choose-your-wallet', '_blank');
			return false;
		}
	};

	const handleWalletConnect = async (
		recipient: string,
		currencyAmount: string
	) => {
		try {
			await sendTransactionAsync({
				to: recipient as any,
				value: parseEther(currencyAmount),
			});
			return true;
		} catch (error) {
			console.error('WalletConnect error:', error);
			return false;
		}
	};

	const isCritical = status === RiskLvl.critical;
	const hideInfoPanel = isCritical || !data.quickTransaction?.data.fee;

	const isSendButtonDisabled =
		isWalletConnectPending ||
		isFormDisabled ||
		loading.quickTransaction ||
		!transactionDetails ||
		isTransactionDetailsLoading ||
		(coin?.toUpperCase() === 'USDT' && !usdtChain?.length);

	const handleFetchExposure = (newAddress: string, newChain: string) => {
		if (!fetchWalletExposure.pending && newAddress && newChain) {
			fetchWalletExposure.call(newAddress, newChain);
		}
	};

	return (
		<BaseCard
			title="Quick Transaction"
			// cardContentStyle="h-full"
			cardHeaderStyle="text-primary-300 mb-4"
			cardStyle={cn(
				'bg-grey-800 bg-cover bg-quick-transaction text-white',
				quickTransactionWidgetBgImageClass
			)}
			Footer={
				<div className="flex flex-col gap-10 w-full">
					{!hideInfoPanel && (
						<>
							{transactionDetails?.transaction_id && (
								<TransactionTime
									transactionId={transactionDetails.transaction_id}
									onClick={toggleErrorMessage}
									isError={isError}
								/>
							)}

							<FeesBanner
								hideMoneyBack={checked}
								coin={coin}
								fee={Number(data.quickTransaction?.data.fee || 0)}
								usdRate={data.marketDataPrice?.usd}
							/>
						</>
					)}

					<div className="flex items-center gap-6 mb-4 justify-between">
						{/* {!hideInfoPanel && (
							<p className="text-white">
								System will automatically connect to your wallet
							</p>
						)} */}
						<Button variant="link" onClick={clearFields} className="ml-auto">
							Clear
						</Button>
						<Button
							className="mx-auto"
							onClick={handleSubmitWallet}
							disabled={isSendButtonDisabled}
						>
							<>
								{isWalletConnectPending ? (
									'Approving...'
								) : (
									<>
										{isDisconnectedAndNonBTC || !isNonBTC ? (
											'Connect Wallet'
										) : (
											<>
												Send <ResponsiveIcon icon={iconMap.arrow} />
											</>
										)}
									</>
								)}
							</>
						</Button>
					</div>
				</div>
			}
		>
			<p className="font-bold">To</p>
			<div className="flex gap-3 mb-4">
				<Input
					className="border-white select-none"
					rootClassName="w-full"
					onChange={(e) => setRecipient(e.target.value)}
					value={recipient}
					disabled={fetchWalletExposure.pending}
					onKeyDown={(e) => {
						if (
							e.key === 'Enter' &&
							walletChain?.chain &&
							walletChain?.isValid
						) {
							handleFetchExposure(e.currentTarget.value, walletChain?.chain);
						}
					}}
					onBlur={(e) => {
						if (walletChain?.chain && walletChain?.isValid) {
							handleFetchExposure(e.target.value, walletChain.chain);
						}
					}}
					icon={fetchWalletExposure.pending ? iconMap.spinner : undefined}
					iconProps={{
						color: 'white',
						className:
							'animate-spin w-6 h-6 [&>path:nth-child(1)]:fill-white [&>path:nth-child(2)]:fill-wallets-lowtext',
					}}
				/>
				{/* <AddRecipientDialog setRecipient={setRecipient} /> */}
			</div>
			{!fetchWalletExposure.pending &&
				status &&
				!!recipient?.length &&
				!!coin?.length && (
					<InformationBanner
						value={status}
						onClick={handleRiskStatus}
						risks={fetchWalletExposure.value?.data?.risk_factors}
					/>
				)}
			<div className="flex flex-col gap-3 mb-12 relative">
				<p className="text-white font-bold">Amount</p>
				<div className="flex gap-4 justify-between flex-col sm:flex-row">
					<SelectInput
						value={currencyAmount}
						onChange={handleCurrencyAmountChange}
						onBlur={handleSubmitTransaction}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSubmitTransaction();
							}
						}}
						disabled={isTransactionDetailsLoading}
						loading={isTransactionDetailsLoading}
						currencyCheckBoxProps={{
							chain: walletChain?.isValid ? walletChain?.chain : undefined,
							multiSelectorProps: {
								value: coin,
								disabled: fetchWalletExposure.pending,
								onValuesChange: (value: [string]) => {
									setCoin(value[0]);
									fetchMarketDataPrice(value[0], 'USD');
									handleSubmitTransaction();
									if (walletChain?.chain && walletChain?.isValid) {
										handleFetchExposure(recipient, walletChain?.chain);
									}
									if (
										walletChain?.chain &&
										value[0]?.toUpperCase() === 'USDT'
									) {
										setUsdtChain(
											currentChainConfig?.find(
												(coin) => coin.coin?.toUpperCase() === 'USDT'
											)?.type || ''
										);
									}
								},
							},
						}}
						ref={selectRef}
						width={width}
					/>
					<CurrencyInput
						value={formatNumberWithPrefix(convertedAmount)}
						onChange={() => {}}
					/>
				</div>
				{coin?.toUpperCase() === 'USDT' && (
					<RadioGroup
						value={usdtChain}
						onValueChange={setUsdtChain}
						className="flex gap-x-4"
					>
						{['ERC', 'TRC'].map((option) => (
							<div className="flex items-center gap-x-1" key={option}>
								<RadioGroupItem
									value={option}
									disabled={
										walletChain &&
										currentChainConfig?.find((coin) => coin.coin === 'USDT')
											?.type === option
											? false
											: true
									}
								></RadioGroupItem>
								<label>{option}</label>
							</div>
						))}
					</RadioGroup>
				)}
			</div>

			<BackGuaranteeWidget checked={checked} onChange={handleChecked} />
		</BaseCard>
	);
};

export default QuickTransaction;

interface TransactionTimeProps {
	transactionId: string;
	onClick: () => void;
	isError: boolean;
}

const TransactionTime: React.FC<TransactionTimeProps> = ({
	isError,
	onClick,
	transactionId,
}) => {
	const processingMessage = 'Time To Complete Transaction';
	const errorMessage = 'Transaction Time Expired. Please Try Again.';

	const intervalIdRef = useRef<NodeJS.Timeout>();

	const getTransactionProgressRequest = usePromise({
		promiseFunction: async (transactionId: string) => {
			const response = await getTransactionProgress(transactionId);
			return response;
		},
	});

	useEffect(() => {
		const pollTransaction = async (id: string) => {
			try {
				await getTransactionProgressRequest.call(id);
			} catch (error) {
				console.error('Error polling transaction:', error);
			}
		};

		if (intervalIdRef.current) {
			clearInterval(intervalIdRef.current);
		}

		pollTransaction(transactionId);

		intervalIdRef.current = setInterval(
			() => {
				pollTransaction(transactionId);
			},
			2 * 60 * 1000 // 2 minutes
		);

		return () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current);
				intervalIdRef.current = undefined;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionId]);

	const progressPercentage =
		getTransactionProgressRequest.value?.data?.percent_progress || 0;

	return (
		<div
			className="flex flex-col w-full text-white text-[15px]"
			onClick={onClick}
		>
			{isError ? (
				<p className="bg-gradient-high bg-clip-text text-transparent-fill">
					{errorMessage}
				</p>
			) : (
				<p>{processingMessage}</p>
			)}
			<ProgressBar
				type="line"
				isError={isError}
				progress={progressPercentage}
			/>
			<div className="flex justify-between items-center text-xs mt-1">
				<span>{progressPercentage} %</span>
				{/* <span>{time} min</span> */}
			</div>
		</div>
	);
};
