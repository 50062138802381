import { riskLevelColorMap } from '../pages/wallets/WalletsPage';
import WalletCard from '../molecules/WalletCard';
import { ResponseAsset, Wallet } from '../../types/wallets';
import { cn } from '../../libs/cn';
import { useState } from 'react';
import WalletPopUp from '../molecules/WalletPopUp';

interface WalletsGridProps {
	data: Wallet[];
	className?: string;
	goToWalletDetails: (id: string) => void;
	onDeleteClick?: (wallet: Wallet) => void;
	onEditTitleClick?: (wallet: Wallet) => void;
}

const WalletsGrid: React.FC<WalletsGridProps> = ({
	data,
	className,
	goToWalletDetails,
	onDeleteClick,
	onEditTitleClick,
}) => {
	const [isOpenPopUp, setIsOpenPopUp] = useState<
		(Wallet & { assets?: ResponseAsset[] }) | null
	>(null);
	return (
		<div
			className={cn(
				'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4',
				className
			)}
		>
			{data?.map((wallet) => {
				const factorBg = riskLevelColorMap[wallet?.riskLevel] || 'bg-gray-100';
				return (
					<WalletCard
						key={wallet?.id}
						item={wallet}
						bgColor={factorBg}
						goToWalletDetails={goToWalletDetails}
						handleMigrate={(item, assets) =>
							setIsOpenPopUp({
								...item,
								assets,
							})
						}
						onDeleteClick={onDeleteClick}
						onEditTitleClick={onEditTitleClick}
					/>
				);
			})}
			{isOpenPopUp && (
				<WalletPopUp
					walletName={isOpenPopUp.name}
					riskLevel={isOpenPopUp.riskLevel}
					assets={isOpenPopUp.assets}
					walletAddress={isOpenPopUp.address}
					handleClose={() => setIsOpenPopUp(null)}
				/>
			)}
		</div>
	);
};

export default WalletsGrid;
