import { Outlet } from 'react-router-dom';

import ResponsiveIcon from '../atoms/Icon';
import { iconMap } from '../../theme/Icons';
import Logo from '../atoms/Logo';
import maskLeft from '../../assets/images/auth-mask-1.png';
import maskTop from '../../assets/images/auth-mask-2.png';
import maskRight from '../../assets/images/auth-mask-3.png';

import { cn } from '../../libs/cn';

const highlights = [
	{
		icon: iconMap.secure,
		title: '100%',
		description: 'Money Back Guarantee',
		className: 'max-w-[190px]',
	},
	{
		icon: iconMap.calendar,
		title: '24/7',
		description: 'Monitoring service for your wallets',
		className: 'max-w-[220px]',
	},
	{
		icon: iconMap.bullet,
		title: 'A bulletproof',
		description: 'Technology privacy against cyber threats',
		className: 'max-w-[330px]',
	},
];

const links = [
	{ title: 'Privacy policy', href: '/privacy-policy' },
	{ title: 'Terms of use', href: '/terms-of-use' },
	{ title: 'Help and support', href: '/help-and-support' },
	{ title: 'Contact us', href: '/contact-us' },
	{ title: 'Sailotech.com', href: 'https://sailotech.com' },
];

const AuthLayout = () => {
	return (
		<div className="flex flex-col min-h-screen bg-black relative">
			<img
				src={maskLeft}
				alt="Background"
				className="absolute bottom-0 left-0 w-1/5 h-auto"
			/>
			<img
				src={maskTop}
				alt="Background"
				className="absolute t-0 right-10 w-1/2 h-auto"
			/>
			<img
				src={maskRight}
				alt="Background"
				className="absolute bottom-0 right-0 w-1/6 h-auto"
			/>

			<main className="flex-grow flex items-start justify-center p-4 pt-12 2xl:pt-28 max-w-screen-xl 2xl:max-w-screen-2xl mx-auto">
				<div className="flex gap-16 2xl:gap-20 flex-col xl:flex-row">
					<div className="max-w-[942px] 3xl:max-w-[1200px] flex flex-col gap-12 text-white">
						<div className="flex flex-col gap-8">
							<Logo
								className={'h-[100px] w-[170px] 3xl:h-[150px] 3xl:w-[250px]'}
							/>

							<p className="font-bold text-5xl xl:text-6xl 3xl:text-8xl text-grey-600 opacity-50 break-words leading-tight">
								The New Security & Privacy Standard for On-Chain Transactions
							</p>
						</div>

						<div className="flex gap-8 xl:gap-4 flex-col md:flex-row justify-between">
							{highlights.map((highlight, index) => (
								<div
									key={index}
									className={cn('flex flex-col gap-3', highlight.className)}
								>
									<div className="flex items-center gap-2">
										<ResponsiveIcon
											icon={highlight.icon}
											colors={['#1CE7DC', '#1CE7AC', '#1CE7A2']}
										/>
										<span className="text-4xl 2xl:text-5xl leading-none whitespace-nowrap">
											{highlight.title}
										</span>
									</div>
									<p className="text-lg 2xl:text-xl leading-7">
										{highlight.description}
									</p>
								</div>
							))}
						</div>
					</div>

					<div className="flex flex-col bg-gradient-primary p-6 sm:p-8 sm:pt-10 sm:mx-auto sm:w-full sm:max-w-md lg:max-w-lg gap-y-8 3xl:gap-y-12 rounded-2xl xl:mt-20 3xl:mt-28 relative z-10">
						<Outlet />
					</div>
				</div>
			</main>

			<footer className="p-4">
				<div className="text-grey-600 flex gap-6 lg:gap-12 opacity-50 justify-center flex-wrap">
					{links.map((link, index) => (
						<a key={index} href={link.href} target="_blank" rel="noreferrer">
							{link.title}
						</a>
					))}
				</div>
			</footer>
		</div>
	);
};

export default AuthLayout;
