export const _transactionsInRisk = [
	{ name: 'Critical risk', value: 5, group: 'rejected', code: 'CRITICAL' },
	{ name: 'Medium risk', value: 10, group: 'pending', code: 'MEDIUM' },
	{ name: 'High risk', value: 10, group: 'pending', code: 'HIGH' },
	{ name: 'Low risk', value: 8, group: 'completed', code: 'LOW' },
];

export const _transactionsStatus = [
	{ name: 'rejected', value: 10 },
	{ name: 'pending', value: 30 },
	{ name: 'completed', value: 25 },
];

export const _volume = [
	{ name: 'Last 24 hours', value: 21, type: 'last_24', total: 38 },
	{
		name: 'Previous matching period',
		value: 22,
		total: 32,
		type: 'prev_matching_period',
	},
];

export const _monitoredWallets = [
	{ name: 'Wallet 1', address: 'bc1d4m3b8n7p0k5h6r2v4j9t', status: 'critical' },
	{ name: 'Wallet 2', address: 'bc1d4m3b8n7p0k5h6r2v4j9t', status: 'high' },
	{ name: 'Wallet 3', address: 'bc1d4m3b8n7p0k5h6r2v4j9t', status: 'low' },
	{ name: 'Wallet 4', address: 'bc1d4m3b8n7p0k5h6r2v4j9t', status: 'low' },
	{ name: 'Wallet 5', address: 'bc1d4m3b8n7p0k5h6r2v4j9t', status: 'low' },
];
