interface LegendWrapperProps {
	children: React.ReactNode;
}

const LegendWrapper: React.FC<LegendWrapperProps> = ({ children }) => {
	return (
		<div className="w-full flex justify-around border-t border-[#C9C9C9] pt-2 font-sm">
			{children}
		</div>
	);
};

export { LegendWrapper };
