import React from 'react';
import {
	Column,
	Header,
	Row,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import {
	BaseTableProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from './Table';
import ResponsiveIcon from '../../atoms/Icon';
import { iconMap } from '../../../theme/Icons';
import { cn } from '../../../libs/cn';
import { DropdownInputFilter } from '../FilterInputs/DropdownInputFilter';
import { RangeInputFilter } from '../FilterInputs/RangeInputFilter';
import { EmptyPlaceholder } from '../../atoms/EmptyPlaceholder';

function renderHeader<TData>(header: Header<TData, unknown>) {
	const handler = header.column.getToggleSortingHandler();
	const { enableSorting } = header.column.columnDef;
	// const size = header.column.getSize();

	return (
		<TableHead
			key={header.id}
			className="top-0 text-base font-normal text-grey-600 align-bottom"
		>
			<div
				className="flex gap-1 items-end"
				// style={size ? { width: `${size}px` } : {}}
			>
				{header.isPlaceholder
					? null
					: flexRender(header.column.columnDef.header, header.getContext())}
				{enableSorting && (
					<div className="flex items-center">
						<Filter column={header.column} />
						<SortControl
							isSorted={header.column.getIsSorted() === 'asc'}
							onOrderChange={handler}
						/>
					</div>
				)}
			</div>

			<TableRow className="h-2" />
		</TableHead>
	);
}

interface RenderTableRowsProps<TData> {
	row: Row<TData>;
	getRowProps?: any;
	renderExpandedRowContent?: (row: Row<TData>) => JSX.Element;
}

function renderTableRows({ row, getRowProps }: RenderTableRowsProps<any>) {
	const visibleCells = row.getVisibleCells();
	const className = getRowProps?.(row)?.className;
	return (
		<>
			<TableRow
				key={row.id}
				className={cn('shadow-lg relative', className)}
				style={{
					height: row.getIsExpanded() ? '8rem' : '0',
					transitionProperty: 'height',
					transitionDuration: '0.4s',
					transitionTimingFunction: 'ease-in-out',
					transform: 'translate(0)',
					clipPath: 'inset(5px -5x -5px -5px)',
				}}
				onClick={() => {
					if (row.getCanExpand()) {
						row.toggleExpanded();
					}
				}}
			>
				{visibleCells.map((cell) => {
					const columnId = cell.getContext().column.id;

					return (
						<TableCell
							key={cell.id}
							className={cn(
								'first:rounded-l-lg last:rounded-r-lg align-baseline text-grey-600 text-sm',
								(columnId === 'status' || columnId === 'actions') &&
									'no-opacity'
							)}
						>
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</TableCell>
					);
				})}
			</TableRow>
			<TableRow className="h-2" />
		</>
	);
}

const getFilterMap = (data: any): Record<string, any> => ({
	singleValue: <DropdownInputFilter items={data} />,
	range: <RangeInputFilter items={data} />,
});

export type TypeRangeFilterData = { range: number[]; dropdownItems?: string[] };

export enum FilterTypeEnum {
	SingleValue = 'singleValue',
	range = 'range',
}

export type FilterOptionsType<TData> = {
	data: TData;
	type: FilterTypeEnum;
};

const Filter = ({ column }: { column: Column<any, unknown> }) => {
	const { filter } = (column.columnDef.meta as {
		filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
	}) ?? { filter: null };
	const filterMap = getFilterMap(filter?.data ?? []);

	return filterMap?.[filter?.type || ''] || null;
};

const SortControl = ({
	onOrderChange,
	isSorted,
}: {
	onOrderChange?: (ev: unknown) => void;
	isSorted: boolean;
}) => {
	return (
		<ResponsiveIcon
			onClick={onOrderChange}
			icon={iconMap.filterArrowUp}
			color={'#9B9B9B'}
			className={cn(
				'hover:cursor-pointer transition-all',
				isSorted && 'rotate-180'
			)}
		/>
	);
};

export function BaseTable<TData, TValue>({
	columns,
	data,
	isLoading,
	error,
	getRowProps,
	renderRowSubComponent,
	headerStyle,
	enableExpanding,
	tableHeaderStyle,
}: BaseTableProps<TData, TValue> & {
	isLoading: boolean;
	error: string | null;
	headerStyle?: string;
	renderExpandedRowContent?: (row: Row<TData>) => JSX.Element;
	enableExpanding?: boolean;
	tableHeaderStyle?: string;
}) {
	const table = useReactTable({
		data,
		columns,
		filterFns: {},
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableExpanding: enableExpanding,
	});

	const headerGroups = table.getHeaderGroups();
	const tableRows = table.getRowModel().rows;

	return (
		<Table>
			<TableHeader className={cn('sticky top-0 z-20', tableHeaderStyle)}>
				{headerGroups.map((headerGroup) => (
					<TableRow key={headerGroup.id} className={headerStyle}>
						{headerGroup.headers.map(renderHeader)}
					</TableRow>
				))}
			</TableHeader>

			<TableBody className="overflow-auto">
				<TableRow className="h-3" />
				{isLoading ? (
					<TableRow className="h-full border-none">
						<TableCell colSpan={columns.length} className="h-52 text-center">
							Loading...
						</TableCell>
					</TableRow>
				) : error ? (
					<TableRow className="h-full border-none">
						<TableCell colSpan={columns.length} className="h-52 text-center">
							{error}
						</TableCell>
					</TableRow>
				) : tableRows.length ? (
					tableRows.map((row) => (
						<React.Fragment key={row.id}>
							{renderTableRows({
								row,
								getRowProps,
							})}
							{renderRowSubComponent && renderRowSubComponent({ row })}
						</React.Fragment>
					))
				) : (
					<TableRow className="h-full border-none">
						<TableCell colSpan={columns.length} className="h-52 text-center">
							<EmptyPlaceholder className="flex-1 min-h-[450px]">
								<EmptyPlaceholder.Icon
									icon={iconMap.noTransactionShadow}
									size={100}
								/>
								<EmptyPlaceholder.Description description="You haven't made any transactions yet" />
							</EmptyPlaceholder>
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
}
