import { ColumnDef, ColumnMeta } from '@tanstack/react-table';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { postTransactionApi } from '../../../controllers/transactions/api';
import useSendTransactionsService from '../../../controllers/transactions/service';
import {
	SendTransaction,
	sendTransactionsActions,
	SendTransactionWithResponse,
} from '../../../controllers/transactions/slice';
import { getWalletExposure } from '../../../controllers/wallet/api';
import { usePromise } from '../../../hooks/usePromise';
import { useToast } from '../../../hooks/useToast';
import { iconMap } from '../../../theme/Icons';
import { formatNumberWithPrefix } from '../../../utils/number';
import { Badge } from '../../atoms/Badge';
import { Button } from '../../atoms/Button';
import { Card } from '../../atoms/Card';
import { Checkbox } from '../../atoms/Checkbox';
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../atoms/Form';
import ResponsiveIcon from '../../atoms/Icon';
import { Input } from '../../atoms/Input';
import { RadioGroup, RadioGroupItem } from '../../atoms/RadioGroup';
import { getDateTimeCell } from '../../organisms/Table/baseConfig';
import {
	BaseTable,
	FilterOptionsType,
	TypeRangeFilterData,
} from '../../organisms/Table/BaseTable';
import TransactionSummaryDialog from '../../specialized/dialogs/TransactionSummaryDialog';
import { CurrencyInput, SelectInput } from '../../specialized/QuickTransaction';
import { ColumnStyleType } from '../../specialized/TransactionTable/columns';
import { getWalletChain, WalletValidationResult } from '../../../utils/chain';
import { CHAIN_OPTIONS_CONFIG } from '../../../constants/transactions';

type SendTransactionColumn = SendTransaction;

const SendPage: React.FC = () => {
	const {
		data,
		loading,
		addDraftTransaction,
		removeDraftTransaction,
		fetchMarketDataPrice,
		sendTransactions,
	} = useSendTransactionsService();

	const [searchParams] = useSearchParams();

	const dispatch = useDispatch();

	const defaultCoin = (searchParams?.get('coin') as string) || 'BTC';
	const defaultAmount = parseFloat(`${searchParams?.get('amount') || 0}`);

	const [loadingTransactionIds, setLoadingTransactionIds] = useState<string[]>(
		[]
	);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [walletChain, setWalletChain] = useState<WalletValidationResult | null>(
		null
	);
	const [isAddingToList, setIsAddingToList] = useState(false);
	const [showTransactionSummary, setShowTransactionSummary] = useState<{
		transactions: SendTransactionWithResponse[];
	} | null>(null);

	const { success, warning } = useToast();

	const postTransactionsRequest = usePromise({
		promiseFunction: async (transactions: SendTransaction[]) => {
			const response = await postTransactionApi(transactions);

			const handleTransactionResult = () => {
				const { failed, successful } = response;
				const isBatchTransaction = transactions.length > 1;

				if (!failed.length) {
					success('Transactions sent successfully');
					return response;
				}

				const errorMessages = failed
					.map((f) => f.error?.data?.error)
					.join(',\n');

				if (isBatchTransaction && successful.length) {
					warning(`Some transactions failed to send: ${errorMessages}`);
					return response;
				}

				warning(
					`${isBatchTransaction ? 'All transactions' : 'Transaction'} failed to send: ${errorMessages}`
				);
				throw new Error('Transaction failed to send');
			};

			return handleTransactionResult();
		},
	});

	const form = useForm<
		SendTransaction & {
			convertedAmount: number;
			usdtChain?: string;
		}
	>({
		defaultValues: {
			useMbg: false,
			coin: defaultCoin,
			amount: defaultAmount,
		},
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [coin, amount, convertedAmount, address, usdtChain] = form.watch([
		'coin',
		'amount',
		'convertedAmount',
		'address',
		'usdtChain',
	]);

	const { isSubmitting, isValid } = form.formState;

	const calculateConvertedAmount = (value: number) => {
		const convertedValue = Number(value) * (data.marketDataPrice?.usd || 0);

		if (convertedValue && !isNaN(convertedValue) && convertedValue > 0) {
			form.setValue('convertedAmount', convertedValue);
		} else {
			form.setValue('convertedAmount', 0);
		}
	};

	useEffect(() => {
		if (address) {
			const walletChain = getWalletChain(address.trim());
			setWalletChain(walletChain);

			if (walletChain?.isValid) {
				const defaultSelectedCoin =
					CHAIN_OPTIONS_CONFIG[walletChain.chain]?.[0];

				form.setValue('coin', defaultSelectedCoin.coin);
				form.setValue('usdtChain', defaultSelectedCoin.type || '');
			}
		} else {
			setWalletChain(null);
			form.setValue('coin', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address]);

	const currentChainConfig = useMemo(() => {
		if (walletChain?.isValid) {
			return CHAIN_OPTIONS_CONFIG[walletChain.chain];
		}
		return null;
	}, [walletChain]);
	useEffect(() => {
		if (coin?.length) {
			fetchMarketDataPrice(coin, 'USD');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coin]);

	useEffect(() => {
		calculateConvertedAmount(Number(amount || 0));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.marketDataPrice?.usd]);

	const handleSendMultipleTransactions = async () => {
		try {
			const transactions = data.draftTransactions?.filter((draftTransaction) =>
				validSelectedIds.includes(draftTransaction.id)
			);

			if (!transactions?.length) return;

			const result = await sendTransactions(transactions);

			setShowTransactionSummary({
				transactions: result,
			});
		} catch (error: any) {
			console.error(error.response.data?.error || error.message);
		}
	};

	const handleSendSingleTransaction = async (id: string) => {
		try {
			const transaction = data.draftTransactions?.find(
				(draftTransaction) => draftTransaction.id === id
			);

			if (!transaction) return;

			setLoadingTransactionIds([...loadingTransactionIds, id]);

			const result = await postTransactionsRequest.call([transaction]);
			if (result.successful.length) {
				setShowTransactionSummary({
					transactions: [result.successful[0]],
				});
			}
		} catch (error: any) {
			console.error(error.response.data?.error || error.message);
		} finally {
			setLoadingTransactionIds(loadingTransactionIds.filter((i) => i !== id));
		}
	};

	const activeDraftCoin = data?.draftTransactions
		?.find((draft) => draft.id === selectedIds?.[0])
		?.coin?.toLowerCase();

	const columns: (ColumnDef<
		SendTransactionColumn,
		string | Record<string, string>
	> & {
		style?: ColumnStyleType;
		meta?: ColumnMeta<
			{
				filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
			},
			unknown
		>;
	})[] = [
		{
			accessorKey: 'id',
			size: 0,
			header: '',
			cell: ({ row }) => {
				const id = row.getValue<string>('id');
				const coin = row.getValue<string>('coin');
				const isLoading = loading.transactionsSent.includes(id);
				const isEnabled =
					!activeDraftCoin || activeDraftCoin === coin.toLowerCase();
				return (
					<Checkbox
						checked={selectedIds.includes(id)}
						onCheckedChange={(checked) => {
							if (checked) {
								setSelectedIds([...selectedIds, id]);
							} else {
								setSelectedIds(selectedIds.filter((i) => i !== id));
							}
						}}
						color="black"
						disabled={isSubmitting || isLoading || !isEnabled}
						iconClassNames="text-black"
					/>
				);
			},
		},
		{
			accessorKey: 'addedDate',
			header: () => <span className="block">Added</span>,
			cell: getDateTimeCell,
			size: 140,
		},
		{
			accessorKey: 'address',
			header: 'To',
			// cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
			cell: (props) => <div>{props.getValue<string>()}</div>,
			size: 80,
		},
		{
			accessorKey: 'amount',
			header: () => 'Total Amount',
			cell: (props) => {
				const totalAmount = props.getValue<number>();
				const coin = props.row.getValue<string>('coin');
				const icon =
					iconMap[coin?.toLowerCase().trim() as keyof typeof iconMap];
				const usdRate = data.marketDataPrice?.usd || 0;

				return (
					<div className="flex gap-3 items-center justify-center">
						<div className="flex gap-2">
							<div className="flex gap-1 items-center">
								{icon && (
									<ResponsiveIcon
										icon={icon}
										className="h-[18px] aspect-square stroke-white"
									/>
								)}
								{!icon && !!coin && <>{coin}</>}
								<div>{formatNumberWithPrefix(totalAmount)}</div>
							</div>
							<div className="flex gap-1 items-center">
								<ResponsiveIcon icon={iconMap.dollar} />
								<span>{formatNumberWithPrefix(totalAmount * usdRate)}</span>
							</div>
						</div>
					</div>
				);
			},
			size: 120,
		},
		{
			accessorKey: 'coin',
			header: () => <span>Risk Level</span>,
			cell: ({ row }) => {
				const id = row.getValue<string>('id');
				const address = row.getValue<string>('address');
				const chain = row.getValue<string>('coin');
				return <WalletExposureCell key={id} address={address} chain={chain} />;
			},
		},
		{
			accessorKey: 'useMbg',
			header: () => <span>Money Back Guarantee</span>,
			cell: (props) => <div>{props.getValue<boolean>() ? 'Yes' : 'No'}</div>,
			size: 130,
		},
		{
			accessorKey: 'actions',
			header: '',
			size: 140,
			cell: ({ row }) => {
				const id = row.getValue<string>('id');
				const isLoading = loadingTransactionIds.includes(id);
				return (
					<div className="w-full h-[52px] flex items-center gap-x-2">
						<Button
							className="ml-auto"
							onClick={() => handleSendSingleTransaction(id)}
							disabled={loading.transactionsSent?.length > 0}
							loading={isLoading}
						>
							Send <ResponsiveIcon icon={iconMap.arrow} />
						</Button>
						<Button
							variant="outline"
							onClick={() => removeDraftTransaction(id)}
							disabled={isLoading}
						>
							<ResponsiveIcon
								className="w-[25px] h-[25px]"
								icon={iconMap.delete}
							/>
						</Button>
					</div>
				);
			},
		},
	];

	const handleAddToList = async () => {
		const isValid = await form.trigger();
		if (!isValid) {
			return;
		}
		setIsAddingToList(true);
		const newValues = form.getValues();
		await addDraftTransaction({
			...newValues,
			chain: `${newValues.coin?.toUpperCase() === 'USDT' ? newValues.usdtChain : newValues.coin}`,
		});
		setIsAddingToList(false);
		form.reset({
			coin,
		});
	};

	const validSelectedIds = selectedIds.filter((selectedId) =>
		data.draftTransactions?.some(
			(draftTransaction) => draftTransaction.id === selectedId
		)
	);

	return (
		<div className="flex flex-col gap-y-4 h-full">
			<FormProvider {...form}>
				<div className="px-3 w-full space-y-4">
					<div className="font-roboto text-sm	text-grey-600">
						Add new items to the queue and send all transactions when
						you&apos;re ready. <br /> After sending, all transactions will
						appear in Transactions list.
					</div>
					<div className="rounded-xl bg-grey-10 shadow-lg  px-[29px] py-[22px] flex flex-col gap-y-2">
						<div className="flex  justify-between items-center gap-x-6">
							<FormField
								rules={{
									required: 'This field is required',
								}}
								control={form.control}
								name="address"
								render={({ field }) => (
									<FormItem className="space-y-1 grow w-1/2">
										<div className="flex flex-col gap-y-4">
											<FormLabel>To</FormLabel>
											<FormControl>
												<Input
													className="py-2 border-black"
													disabled={isSubmitting}
													{...field}
													onChange={field.onChange}
													value={field.value ?? ''}
												/>
											</FormControl>
										</div>
										<FormMessage />
									</FormItem>
								)}
							/>
							<div className="flex flex-col gap-y-4 !space-y-[0]">
								<FormLabel>Amount</FormLabel>
								<FormItem className="flex gap-x-2 !space-y-[0px">
									<FormField
										rules={{
											required: 'This field is required',
										}}
										control={form.control}
										name="amount"
										render={({ field }) => (
											<FormControl>
												<div className="flex gap-4 justify-between flex-col sm:flex-row">
													<SelectInput
														value={form.getValues('amount')?.toString()}
														className="!text-grey-800 !border-grey-800"
														onChange={(e) => {
															field.onChange(e.target.value);
															calculateConvertedAmount(Number(e.target.value));
														}}
														currencyCheckBoxProps={{
															chain: walletChain?.isValid
																? walletChain?.chain
																: undefined,
															multiSelectorProps: {
																value: form.getValues('coin')?.toString(),
																className: '!text-grey-800',
																onValuesChange: (values: string[]) => {
																	form.setValue('coin', values?.[0]);
																	fetchMarketDataPrice(values[0], 'USD');
																	if (
																		walletChain?.chain &&
																		values?.[0]?.toUpperCase() === 'USDT'
																	) {
																		form.setValue(
																			'usdtChain',
																			currentChainConfig?.find(
																				(coin) =>
																					coin.coin?.toUpperCase() === 'USDT'
																			)?.type || ''
																		);
																	}
																},
															},
															multiSelectorTriggerProps: {
																className: '!border-grey-800',
															},
															multiSelectorListProps: {
																className: '!bg-white',
															},
															multiSelectorItemProps: {
																checkBoxClassName: '!border-grey-800',
																color: 'black',
															},
														}}
													/>
													<CurrencyInput
														className="!text-grey-800 !border-grey-800"
														currencyConvertorProps={{
															className: 'text-grey-800 border-grey-800',
															triggerClassName: 'text-grey-800 border-grey-800',
														}}
														value={`${convertedAmount || ''}`}
														onChange={() => {}}
													/>
												</div>
											</FormControl>
										)}
									/>
								</FormItem>
								<FormField
									control={form.control}
									name="usdtChain"
									rules={{
										required: {
											value: coin?.toUpperCase() === 'USDT',
											message: 'Required',
										},
									}}
									render={({ field }) => (
										<>
											{coin?.toUpperCase() === 'USDT' && (
												<RadioGroup
													onValueChange={field.onChange}
													className="flex gap-x-6"
												>
													{['ERC', 'TRC'].map((option) => (
														<div
															className="flex items-center gap-x-1"
															key={option}
														>
															<RadioGroupItem
																value={option}
																disabled={
																	walletChain &&
																	currentChainConfig?.find(
																		(coin) => coin.coin === 'USDT'
																	)?.type === option
																		? false
																		: true
																}
															></RadioGroupItem>
															<label>{option}</label>
														</div>
													))}
												</RadioGroup>
											)}
										</>
									)}
								></FormField>
							</div>
							<div>
								<div>Secure your Crypto with Money Back Guarantee</div>
								<FormField
									control={form.control}
									name="useMbg"
									render={({ field }) => (
										<FormItem className="space-y-1">
											<div className="flex flex-col gap-y-4">
												<FormControl>
													<div className="flex items-center gap-2">
														<Checkbox
															checked={field.value}
															onCheckedChange={(checked) =>
																field.onChange(checked)
															}
															color="black"
															disabled={isSubmitting}
															iconClassNames="text-black"
															// className="border-white data-[state=checked]:bg-white"
														/>
														<p className="font-bold">I&apos;ll Take It!</p>
													</div>
												</FormControl>
											</div>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<Button
								size="sm"
								disabled={!isValid}
								onClick={async (e) => {
									e.preventDefault();
									await handleAddToList();
								}}
								loading={isSubmitting || isAddingToList}
							>
								Add to List
							</Button>
						</div>
					</div>
				</div>
			</FormProvider>
			<div className="grow">
				<BaseTable
					columns={columns}
					data={data.draftTransactions}
					isLoading={false}
					error={null}
					enableExpanding={false}
				/>
			</div>
			{!!validSelectedIds.length && (
				<Card className="w-full mt-auto">
					<Button
						className="ml-auto flex"
						onClick={handleSendMultipleTransactions}
						loading={loading.transactionsSent.length > 0}
						disabled={loadingTransactionIds.length > 0}
					>
						Send ({validSelectedIds.length}/{data.draftTransactions.length}){' '}
						<ResponsiveIcon icon={iconMap.arrow} />
					</Button>
				</Card>
			)}
			<TransactionSummaryDialog
				isOpen={!!showTransactionSummary?.transactions?.length}
				chain={showTransactionSummary?.transactions?.[0]?.chain}
				transactions={showTransactionSummary?.transactions?.filter((txn) =>
					data.draftTransactions?.some((draft) => draft.id === txn.id)
				)}
				handleClose={() => setShowTransactionSummary(null)}
				onComplete={(transactionId: string) => {
					dispatch(sendTransactionsActions.removeTransaction(transactionId));
					setSelectedIds(selectedIds.filter((i) => i !== transactionId));
				}}
			/>
		</div>
	);
};

const exposureCache = new Map<
	string,
	{
		data: { risk_score: string };
		timestamp: number;
	}
>();
const CACHE_DURATION = 5 * 60 * 1000;

// eslint-disable-next-line react/display-name
const WalletExposureCell = memo(
	({ address, chain }: { address: string; chain: string }) => {
		const [exposure, setExposure] = useState<{ risk_score: string } | null>(
			null
		);
		const fetchWalletExposure = usePromise({
			promiseFunction: async (walletAddress: string, chain: string) => {
				const response = await getWalletExposure({
					walletAddress,
					chain,
				});
				return response.data;
			},
		});

		const cacheKey = `${address}-${chain}`;

		useEffect(() => {
			const fetchExposure = async () => {
				// Check cache first
				const cachedResult = exposureCache.get(cacheKey);
				const now = Date.now();

				if (cachedResult && now - cachedResult.timestamp < CACHE_DURATION) {
					setExposure(cachedResult.data);
					return;
				}

				try {
					const response = await fetchWalletExposure.call(address, chain);

					exposureCache.set(cacheKey, {
						data: response.data,
						timestamp: now,
					});

					setExposure(response.data);
				} catch (error) {
					console.error('Failed to fetch wallet exposure:', error);
				}
			};

			if (address && chain) {
				fetchExposure();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [address, chain, cacheKey]);

		if (fetchWalletExposure.pending || !exposure?.risk_score) {
			return (
				<div className="w-full flex justify-center items-center">
					<ResponsiveIcon
						size={5}
						className="animate-spin"
						icon={iconMap.spinner}
					/>
				</div>
			);
		}

		return (
			<Badge variant={exposure.risk_score as any}>{exposure?.risk_score}</Badge>
		);
	},
	(prevProps, nextProps) =>
		prevProps.address === nextProps.address &&
		prevProps.chain === nextProps.chain
);

export default SendPage;
