import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import ResponsiveIcon from './Icon';
import { iconMap } from '../../theme/Icons';

import { cn } from '../../libs/cn';

const Slider = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
	<div className="w-full">
		<SliderPrimitive.Root
			ref={ref}
			className={cn(
				'relative flex w-full touch-none select-none items-center my-3',
				className
			)}
			{...props}
		>
			<SliderPrimitive.Track className="relative h-[2px] w-full grow overflow-hidden rounded-full bg-grey-500">
				<SliderPrimitive.Range className="absolute h-full bg-transparent" />
			</SliderPrimitive.Track>

			<SliderPrimitive.Thumb className="h-6 w-6 rounded-full bg-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 flex items-center justify-center">
				<ResponsiveIcon icon={iconMap.slideArrow} color={'#1CE7A2'} />
			</SliderPrimitive.Thumb>

			<SliderPrimitive.Thumb className="h-6 w-6 rounded-full bg-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 flex items-center justify-center">
				<ResponsiveIcon
					icon={iconMap.slideArrow}
					color={'#1CE7A2'}
					className="rotate-180"
				/>
			</SliderPrimitive.Thumb>
		</SliderPrimitive.Root>

		<div className="flex justify-between mt-2 text-sm text-gray-500">
			<span>{props?.min}</span>
			<span>{props?.max}</span>
		</div>
	</div>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
