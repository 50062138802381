import { cn } from '../../libs/cn';

const Spinner = ({ className }: { className?: string }) => {
	return (
		<div className="backdrop-blur-md flex items-center justify-center absolute inset-0 z-10 m-auto">
			<div
				className={cn(
					'w-12 h-12 border-4 border-primary-100 border-t-transparent rounded-full animate-spin',
					className
				)}
			></div>
		</div>
	);
};

export { Spinner };
