import { CellContext, ColumnDef, ColumnMeta } from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';

import { iconMap } from '../../../theme/Icons';
import {
	Transaction,
	TransactionStatus,
	WalletDataType,
} from '../../../types/transaction';

import { buildExplorerLink, shortenString } from '../../../utils/string';
import { Button } from '../../atoms/Button';
import ResponsiveIcon from '../../atoms/Icon';
import { Status, StatusProps } from '../../atoms/Status';

import {
	getPauseEligibility,
	getTransactionProgress,
} from '../../../controllers/transactions/api';
import { cn } from '../../../libs/cn';
import {
	getDateTimeCell,
	getGuaranteeCell,
	getRiskLevelandFactorsCell,
	getTotalAmountCell,
	WalletCodeCell,
} from '../../organisms/Table/baseConfig';
import {
	FilterOptionsType,
	FilterTypeEnum,
	TypeRangeFilterData,
} from '../../organisms/Table/BaseTable';
import { usePromise } from '../../../hooks/usePromise';
import ProgressBar from '../../atoms/ProgressBar';
import { Tooltip } from '../../atoms/Tooltip';

const StatusCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	const status = value.toLocaleLowerCase()?.replace(' ', '');
	const [loading, setLoading] = useState<boolean>(false);
	const [isPausable, setIsPausable] = useState<boolean>(false);

	const transactionId = (props.row.original as any).id;

	const shouldInquirePauseEligibility = [
		TransactionStatus.rejected.toLowerCase(),
		TransactionStatus.completed.toLowerCase(),
	].includes(value.toLowerCase());

	const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(
		status as TransactionStatus
	);

	const handlePause = () => {
		setTransactionStatus(TransactionStatus.paused);
	};

	const handleResend = () => {
		setTransactionStatus(TransactionStatus.transferring);
	};

	const fetchPauseEligibility = async () => {
		try {
			setLoading(true);
			const response = await getPauseEligibility(transactionId);
			setIsPausable(!!response);
		} finally {
			setLoading(false);
		}
	};

	const getTransactionProgressRequest = usePromise({
		promiseFunction: async (transactionId: string) => {
			const response = await getTransactionProgress(transactionId);
			return response;
		},
	});

	useEffect(() => {
		if (!loading && shouldInquirePauseEligibility && transactionId) {
			fetchPauseEligibility();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldInquirePauseEligibility, transactionId]);

	const isTransactionStatusPausable =
		value &&
		[TransactionStatus.paused, TransactionStatus.transferring].includes(
			value as unknown as TransactionStatus
		);

	const intervalIdRef = useRef<NodeJS.Timeout>();
	useEffect(() => {
		const pollTransaction = async (id: string) => {
			try {
				await getTransactionProgressRequest.call(id);
			} catch (error) {
				console.error('Error polling transaction:', error);
			}
		};

		if (isTransactionStatusPausable) {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current);
			}

			pollTransaction(transactionId);

			intervalIdRef.current = setInterval(
				() => {
					pollTransaction(transactionId);
				},
				2 * 60 * 1000 // 2 minutes
			);
		}

		return () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current);
				intervalIdRef.current = undefined;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionId, value, isTransactionStatusPausable]);

	return (
		<div className="flex justify-between transition-all w-44 relative">
			<div className="w-2/3">
				{value === TransactionStatus.rejected ? (
					<>
						Rejected
						<Button
							className="hover:gap-2 bg-grey-table px-3 py-0 rounded-2xl border-transparent text-sm"
							onClick={handleResend}
							icon={<ResponsiveIcon icon={iconMap.refresh} />}
							iconPosition="right"
						>
							Resend
						</Button>
					</>
				) : value === TransactionStatus.unknown ? (
					<div className="flex items-center group gap-1">
						<ResponsiveIcon
							icon={iconMap.hourglass}
							className="h-5 w-5 transform transition-transform duration-500 ease-in-out group-hover:rotate-180"
						/>
						<p>Waiting for User Action</p>
					</div>
				) : transactionStatus === TransactionStatus.paused ? (
					<>
						<div className="flex flex-col gap-y-1">
							Paused
							<ProgressBar
								progress={
									getTransactionProgressRequest.value?.data?.percent_progress ||
									0
								}
								className={cn({
									grayscale: transactionStatus === TransactionStatus.paused,
								})}
							/>
						</div>
					</>
				) : (
					<div
						className={cn(
							'flex gap-1 items-center capitalize',
							transactionStatus === TransactionStatus.transferring &&
								'flex-row-reverse items-center justify-center w-full group-hover:opacity-50'
						)}
					>
						<Status
							variant={transactionStatus as StatusProps['variant']}
							progress={
								getTransactionProgressRequest.value?.data.percent_progress
							}
						/>
						{transactionStatus === TransactionStatus.transferring
							? `${transactionStatus}...`
							: transactionStatus}
					</div>
				)}
			</div>
			{isPausable && isTransactionStatusPausable && (
				<div className="hidden group-hover:flex absolute right-0 inset-y-0 items-center justify-center">
					<Button
						className="hover:gap-2 bg-grey-table px-3 py-0 rounded-2xl border-transparent text-sm"
						onClick={handlePause}
					>
						{value === TransactionStatus.paused ? (
							<>
								Resume
								<ResponsiveIcon icon={iconMap.pause} />
								{/* TODO: replace with resume icon when available */}
							</>
						) : (
							<>
								Pause
								<ResponsiveIcon icon={iconMap.pause} />
							</>
						)}
					</Button>
				</div>
			)}
		</div>
	);
};

///////////////

export type ColumnType = ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType | undefined;
};

export type ColumnStyleType = {
	width?: string | number;
};

export const dashboardColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType;
})[] = [
	{
		accessorKey: 'dateTime',
		header: 'Sent',
		cell: getDateTimeCell,
		size: 120,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => {
			const wallet = props.getValue<WalletDataType>();
			return (
				<Tooltip content={wallet.code || wallet.wallet}>
					<WalletCodeCell {...wallet} />
				</Tooltip>
			);
		},
		size: 80,
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => {
			const code = props.getValue<string>();
			return (
				<Tooltip content={code}>
					<WalletCodeCell
						code={props.getValue<string>()}
						className={cn({
							truncate: !props.row.getIsExpanded(),
							'break-words': props.row.getIsExpanded(),
						})}
					/>
				</Tooltip>
			);
		},
		size: 80,
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			// const value = props.getValue<string>();
			// console.log('dashboard page', props.row.original);
			// return <span>{value?.length ? shortenString(value) : '-'}</span>;
			const value = props.getValue<string>();
			// const coinName = props.row.original.coinName?.toLowerCase();
			const chainId = props.row.original.chainId?.toLowerCase();
			const outputs = props.row.original.outputs;
			const hashValue =
				value && value?.length
					? value
					: outputs && outputs?.length === 1
						? outputs[0].hash
						: '';
			const hashLink = buildExplorerLink(hashValue, chainId);
			return (
				<a
					href={hashLink}
					rel="noreferrer"
					target="_blank"
					className={cn({
						underline: !!hashValue,
						'pointer-events-none': !hashValue,
					})}
				>
					{hashValue?.length ? shortenString(hashValue) : '-'}
				</a>
			);
		},
		size: 100,
	},
	{
		accessorKey: 'totalAmount',
		header: () => <span className="whitespace-nowrap">Total Amount</span>,
		cell: getTotalAmountCell,
		size: 100,
	},
	{
		accessorKey: 'riskLevel',
		header: () => <span className="block truncate">Risk Level & Factors</span>,
		cell: getRiskLevelandFactorsCell,
		size: 100,
	},
	{
		accessorKey: 'riskFactor',
		header: '',
		// cell: getRiskLevelandFactorsCell,
		size: 0,
	},
	{
		accessorKey: 'status',
		header: () => <span className="block">Status</span>,
		cell: StatusCell,
		size: 220,
	},
	{
		accessorKey: 'moneyBackGuarantee',
		header: () => <div className="flex-1 truncate">Money Back Guarantee</div>,
		cell: getGuaranteeCell,
		size: 120,
	},
	{
		accessorKey: 'actions',
		header: '',
		size: 20,
		cell: ({ row }) => (
			<div
				className="cursor-pointer h-[52px] flex items-center"
				onClick={() => row.toggleExpanded()}
			>
				<ResponsiveIcon
					icon={iconMap.outlinedArrowDown}
					className={row.getIsExpanded() ? 'rotate-180' : ''}
				/>
				{/* {<RenderExpandedRowContent row={row} />} */}
			</div>
		),
	},
];

export const transactionColumns = (
	supportedCoinsList?: string[]
): (ColumnDef<Transaction, string | Record<string, string>> & {
	style?: ColumnStyleType;
	meta?: ColumnMeta<
		{
			filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
		},
		unknown
	>;
})[] => [
	{
		accessorKey: 'dateTime',
		header: () => <span className="block">Sent</span>,
		cell: getDateTimeCell,
		size: 140,
		enableSorting: true,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => {
			const wallet = props.getValue<WalletDataType>();
			return (
				<Tooltip content={wallet.code}>
					<WalletCodeCell wallet={wallet.code} />
				</Tooltip>
			);
		},
		enableSorting: true,
		size: 100,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => {
			const wallet = props.getValue<string>();
			return (
				<Tooltip content={wallet}>
					<WalletCodeCell
						code={wallet}
						className={cn({
							truncate: !props.row.getIsExpanded(),
							'break-words': props.row.getIsExpanded(),
						})}
					/>
				</Tooltip>
			);
		},
		enableSorting: true,
		size: 80,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			const value = props.getValue<string>();
			// const coinName = props.row.original.coinName?.toLowerCase();
			const chainId = props.row.original.chainId?.toLowerCase();
			const hashLink = buildExplorerLink(value, chainId);
			return (
				<a
					href={hashLink}
					rel="noreferrer"
					target="_blank"
					className={cn({ underline: !!value, 'pointer-events-none': !value })}
				>
					{value?.length ? shortenString(value) : '-'}
				</a>
			);
			// const value = props.getValue<string>();
			// console.log('transactions page', props.row.original);
			// if (!value) {
			// 	return <div className="text-center w-full">-</div>;
			// }
			// return <span className="underline">{shortenString(value)}</span>;
		},
		size: 100,
		enableSorting: true,
	},
	{
		accessorKey: 'totalAmount',
		header: () => 'Total Amount',
		cell: getTotalAmountCell,
		enableSorting: true,
		size: 120,
		meta: {
			expandableContent: true,
			filter: {
				type: FilterTypeEnum.range,
				data: {
					dropdownItems: supportedCoinsList?.map<string>((item) =>
						item.toString()
					),
					range: [0, 100],
				},
			},
		},
	},
	// {
	// 	accessorKey: 'riskLevel',
	// 	header: 'Risk Level',
	// 	cell: getRiskLevelCell,
	// 	enableSorting: true,
	// 	size: 120,
	// },
	{
		accessorKey: 'riskLevel',
		header: () => <span className="block truncate">Risk Level & Factors</span>,
		cell: getRiskLevelandFactorsCell,
		size: 100,
	},
	{
		accessorKey: 'riskFactor',
		header: '',
		cell: () => {},
		size: 0,
	},
	// {
	// 	accessorKey: 'riskFactor',
	// 	header: () => <span className="whitespace-nowrap">Risk Factors</span>,
	// 	cell: (props) => {
	// 		const value = props.getValue<string[]>();
	// 		return (
	// 			<div
	// 				className={cn({
	// 					'w-full text-center': !value?.length,
	// 				})}
	// 			>
	// 				{value?.length || '-'}
	// 			</div>
	// 		);
	// 	},
	// 	size: 120,
	// 	enableSorting: true,
	// },
	{
		accessorKey: 'status',
		header: 'Status',
		cell: StatusCell,
		enableSorting: true,
		size: 220,
	},
	{
		accessorKey: 'moneyBackGuarantee',
		header: () => <span>Money Back Guarantee</span>,
		cell: getGuaranteeCell,
		size: 130,
		enableSorting: true,
	},
	{
		accessorKey: 'actions',
		header: '',
		enableSorting: false,
		size: 20,
		cell: ({ row }) => (
			<div
				className="cursor-pointer h-[52px] flex items-center"
				onClick={() => row.toggleExpanded()}
			>
				<ResponsiveIcon
					icon={iconMap.outlinedArrowDown}
					className={row.getIsExpanded() ? 'rotate-180' : ''}
				/>
				{/* {<RenderExpandedRowContent row={row} />} */}
			</div>
		),
	},
];
