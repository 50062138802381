import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';

export enum DisplayMode {
	grid = 'grid',
	list = 'list',
}

interface DisplayModeSwitcherProps {
	modes: DisplayMode[];
	selectedMode: DisplayMode;
	onChangeDisplayMode: (mode: DisplayMode) => void;
	icons: Record<DisplayMode, any>;
	title?: string;
}

const DisplayModeSwitcher: React.FC<DisplayModeSwitcherProps> = ({
	modes,
	selectedMode,
	onChangeDisplayMode,
	icons,
	title,
}) => {
	return (
		<div className="flex items-center gap-1 self-end">
			{title && <span className="mr-1">{title}</span>}

			{modes.map((mode) => (
				<Button
					key={mode}
					size={'icon'}
					variant={'icon'}
					className="bg-transparent border-none"
					onClick={() => onChangeDisplayMode(mode)}
					icon={
						<ResponsiveIcon
							icon={icons[mode]}
							colors={
								selectedMode === mode ? ['#1CE7DC', '#1CE7AC', '#1CE7A2'] : []
							}
							className="w-6 h-6"
						/>
					}
				/>
			))}
		</div>
	);
};

export { DisplayModeSwitcher };
