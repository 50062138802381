import axios from 'axios';
import { ResponseData } from '../../types/common';
import {
	MfaOption,
	CurrentUserResponse,
	ResponseAuth,
	ResponseSelectMfa,
	ResponseToken,
} from '../../types/auth';
import { API_BASE_AUTH_URL, API_BASE_MEMBER_URL } from '../../config';
import { axiosInstanceApi } from '../../services/axios';

interface LoginProps {
	email: string;
	password: string;
	signInOption: string;
}

export const mfaApi = async ({
	mfaToken,
	selectedMfaOption,
}: {
	mfaToken: string;
	selectedMfaOption?: MfaOption;
}) => {
	try {
		const response = await axios.post<ResponseData<ResponseSelectMfa>>(
			`${API_BASE_AUTH_URL}/v1/mfa/select`,
			{
				mfa_token: mfaToken,
				selected_option: selectedMfaOption,
			}
		);
		return response.data;
	} catch (error) {
		throw new Error('Failed to request MFA');
	}
};
export const loginApi = async ({
	email,
	password,
	signInOption,
}: LoginProps) => {
	try {
		const response = await axios.post<ResponseData<ResponseToken>>(
			`${API_BASE_AUTH_URL}/v1/token`,
			{
				username: email,
				password,
			}
		);
		const tokenResponse = response.data;
		const mfaToken = tokenResponse.data.mfa_token;
		let oobCode;
		let selectedMfaOption;

		if (signInOption === 'digital-code') {
			selectedMfaOption = tokenResponse.data.mfa_options.find(
				(mfaOption) => mfaOption.type === 'sms' && mfaOption.confirmed
			);
			const mfaResponse = await mfaApi({
				mfaToken,
				selectedMfaOption,
			});
			oobCode = mfaResponse.data.oob_code;
		}
		return {
			mfaToken,
			oobCode,
			selectedMfaOption,
		};
	} catch (error) {
		throw new Error('Failed to log in');
	}
};

export const verifyLoginApi = async ({
	code,
	mfaToken,
	oobCode,
}: {
	code: string[];
	mfaToken: string;
	oobCode?: string | null;
}) => {
	const otpCode = code.join('');

	const response = await axios.post<ResponseData<ResponseAuth>>(
		`${API_BASE_AUTH_URL}/v1/mfa/auth`,
		{
			mfa_token: mfaToken,
			otp: otpCode,
			oob_code: oobCode,
		}
	);
	return {
		idToken: response.data.data.id_token,
		accessToken: response.data.data.access_token,
		refreshToken: response.data.data.refresh_token,
	};
};

export const refreshTokenApi = async ({
	refreshToken,
}: {
	refreshToken: string;
}) => {
	const response = await axios.post<ResponseData<ResponseAuth>>(
		`${API_BASE_AUTH_URL}/v1/token/refresh`,
		{
			refresh_token: refreshToken,
		}
	);

	return {
		idToken: response.data.data.id_token,
		accessToken: response.data.data.access_token,
		refreshToken: response.data.data.refresh_token,
	};
};

export const getCurrentUser = async () => {
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<CurrentUserResponse>
		>(`${API_BASE_MEMBER_URL}/v1/member/me`, {});
		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const forgotPasswordApi = async (email: string) => {
	const response = await axios.post<ResponseData<ResponseAuth>>(
		`${API_BASE_MEMBER_URL}/v1/member/forget-password`,
		{
			email: email,
		}
	);

	return response.data;
};
