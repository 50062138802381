import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { iconMap } from '../../theme/Icons';
import { cn } from '../../libs/cn';
import ResponsiveIcon from './Icon';

const guaranteeVariants = cva(
	'inline-flex items-center rounded-full border p-1',
	{
		variants: {
			variant: {
				default:
					'border-transparent bg-gradient-primary text-primary-foreground hover:bg-gradient-primary/80',
				yes: 'border-transparent bg-gradient-primary text-secondary-foreground hover:bg-secondary/80',
				no: 'border-transparent bg-gradient-critical text-white hover:bg-gradient-critical/80',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	}
);

export interface GuaranteeProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof guaranteeVariants> {}

function Guarantee({ className, variant, ...props }: GuaranteeProps) {
	return (
		<div className={cn(guaranteeVariants({ variant }), className)} {...props}>
			{props.children}
			{variant === 'yes' && <ResponsiveIcon icon={iconMap.checked} />}
			{variant === 'no' && <ResponsiveIcon icon={iconMap.cross} />}
		</div>
	);
}

export { Guarantee, guaranteeVariants };
