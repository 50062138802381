import { useEffect } from 'react';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';

import VerifyCodeForm, {
	formSchema,
} from '../../organisms/auth/Verify/VerifyCodeForm';
import useAuthService from '../../../controllers/auth/service';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../controllers/auth/slice';

const VerifyCodePage: React.FC = () => {
	const navigate = useNavigate();
	const { mfaToken, oobCode } = useSelector(
		(state: { auth: AuthState }) => state.auth.data
	);

	const {
		verifyLogin,
		data: { isVerified },
	} = useAuthService();

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		try {
			const code = Object.values(values);
			if (mfaToken && code) {
				verifyLogin(code, mfaToken, oobCode);
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		if (isVerified) {
			navigate('/dashboard');
		}
	}, [isVerified, navigate]);

	return (
		<>
			<h2 className="text-5xl font-bold leading-none">
				Enter <br /> verification code
			</h2>
			<p className="leading-5">
				We&apos;ve sent a 6-digit code to your phone. Enter the code to complete
				your sign in.
			</p>

			<VerifyCodeForm onVerifyCodeSuccess={onSubmit} />
		</>
	);
};

export default VerifyCodePage;
