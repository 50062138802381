import { useState } from 'react';
import BaseMultiSelect from '../BaseMultiSelect';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '../../atoms/DropdownMenu';
import ResponsiveIcon from '../../atoms/Icon';
import { Slider } from '../../atoms/Slider';
import { Input } from '../../atoms/Input';
import { Label } from '../../atoms/Label';

import { iconMap } from '../../../theme/Icons';

import { cn } from '../../../libs/cn';

type RangeInputFilterProps = {
	items: {
		dropdownItems?: string[];
		range: number[];
	};
};

const RangeInputFilter: React.FC<RangeInputFilterProps> = ({ items }) => {
	const { dropdownItems, range } = items;

	const [selectedCurrency, setSelectedCurrency] = useState<string[]>([]);
	const [selectedRangeValue, setSelectedRangeValue] = useState<number[]>(range);

	const handleValueChange = (values: number[]) => {
		setSelectedRangeValue(values);
	};

	// const handleValueCommit = (e) => {};

	const handleFromChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(ev.target.value);
		if (value < range[0] || value > selectedRangeValue[1]) return;
		setSelectedRangeValue((prev) => [value, prev[1]]);
	};

	const handleToChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(ev.target.value);
		if (value < selectedRangeValue[0] || value > range[1]) return;
		setSelectedRangeValue((prev) => [prev[0], value]);
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<ResponsiveIcon
					icon={iconMap.filter}
					className={cn('hover:cursor-pointer')}
				/>
			</DropdownMenuTrigger>

			<DropdownMenuContent className="w-56 flex flex-col gap-6">
				{dropdownItems && (
					<BaseMultiSelect
						selectedValue={selectedCurrency}
						setSelectedValue={setSelectedCurrency}
						values={dropdownItems}
						className="w-full"
						triggerClassName="min-h-8 text-sm w-full"
					/>
				)}

				{range && (
					<>
						<Slider
							defaultValue={range}
							min={range[0]}
							max={range[1]}
							step={1}
							value={selectedRangeValue}
							onValueChange={handleValueChange}
							// onValueCommit={handleValueCommit}
						/>

						<div className="flex flex-col gap-1">
							<Label className="text-xs">From</Label>
							<Input
								className="rounded-sm py-1"
								type="number"
								max={selectedRangeValue[1]}
								min={range[0]}
								value={selectedRangeValue[0]}
								onChange={handleFromChange}
							/>
						</div>

						<div className="flex flex-col gap-1">
							<Label className="text-xs">To</Label>
							<Input
								className="rounded-sm py-1"
								type="number"
								min={selectedRangeValue[0]}
								max={range[1]}
								value={selectedRangeValue[1]}
								onChange={handleToChange}
							/>
						</div>
					</>
				)}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export { RangeInputFilter };
