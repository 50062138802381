import { API_BASE_WALLET_URL } from '../../../config';
import { usePromise } from '../../../hooks/usePromise';
import { useToast } from '../../../hooks/useToast';
import { cn } from '../../../libs/cn';
import { axiosInstanceApi } from '../../../services/axios';
import { iconMap } from '../../../theme/Icons';
import { Button } from '../../atoms/Button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '../../atoms/Dialog';
import ResponsiveIcon from '../../atoms/Icon';

const DeleteWalletModal: React.FC<{
	isOpen?: boolean;
	walletId: string;
	handleClose?: () => void;
	onDelete?: () => void;
}> = ({ walletId, isOpen, handleClose, onDelete }) => {
	const { warning } = useToast();
	const deleteWallet = usePromise({
		promiseFunction: async () => {
			try {
				const response = await axiosInstanceApi.delete(
					`${API_BASE_WALLET_URL}/v1/wallet`,
					{
						params: {
							wallet_id: walletId,
						},
					}
				);
				return response.data;
			} catch (error: any) {
				warning(error.response.data.message);
				throw new Error(error.response.data.message);
			}
		},
	});

	const handleConfirm = async () => {
		await deleteWallet.call();
		deleteWallet.reset();
		onDelete && onDelete();
		handleClose && handleClose();
	};

	return (
		<Dialog open={isOpen} onOpenChange={handleClose}>
			<DialogContent className="sm:max-w-[600px] py-12">
				<DialogHeader>
					<DialogTitle className="flex flex-col sm:flex-row gap-2 sm:gap-5 items-center text-2xl font-bold text-center border-b border-[#A7D0C7] pb-3">
						<ResponsiveIcon
							icon={iconMap.wallets}
							color={'var(--color-primary-300)'}
							size={32}
						/>
						<p>Delete Wallet</p>
					</DialogTitle>
				</DialogHeader>

				<DialogDescription>
					{`Are you sure you want to delete
					${walletId ? `"${walletId}"` : 'this wallet'}? This action cannot
					be undone`}
				</DialogDescription>

				<DialogFooter className="mt-4">
					<Button
						className="text-lg"
						variant="outline"
						disabled={deleteWallet.pending}
						onClick={handleClose}
					>
						Cancel
					</Button>
					<Button
						className="text-lg"
						onClick={handleConfirm}
						disabled={deleteWallet.pending}
					>
						Confirm{' '}
						<ResponsiveIcon
							size={5}
							className={cn('animate-spin', {
								hidden: !deleteWallet.pending,
							})}
							icon={iconMap.spinner}
						/>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteWalletModal;
