import { cn } from '../../libs/cn';
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from '../atoms/Card';

export type BaseCardProps = {
	children: React.ReactNode;
	className?: string;
	Footer?: React.ReactNode;
	cardStyle?: string;
	cardHeaderStyle?: string;
	cardTitleStyle?: string;
	cardContentStyle?: string;
	cardFooterStyle?: string;
	cardContentWrapperStyle?: string;
	title?: string | JSX.Element;
	subtitle?: string;
};

const BaseCard: React.FC<BaseCardProps> = ({
	children,
	cardStyle,
	cardHeaderStyle,
	cardTitleStyle,
	cardContentStyle,
	cardFooterStyle,
	cardContentWrapperStyle,
	title,
	subtitle,
	Footer,
}) => {
	return (
		<Card className={cn('flex flex-col h-full', cardStyle)}>
			<CardHeader className={cn('mb-3', cardHeaderStyle)}>
				{title && (
					<CardTitle
						className={cn(
							'font-normal font-roboto-condensed text-xl',
							cardTitleStyle
						)}
					>
						{title}
					</CardTitle>
				)}
				{subtitle && (
					<p className="border-b border-[#C9C9C9] mt-0 pb-3">{subtitle}</p>
				)}
			</CardHeader>

			<div className={cardContentWrapperStyle}>
				<CardContent className={cardContentStyle}>{children}</CardContent>

				{Footer && (
					<CardFooter
						className={cn(
							'justify-end lg:justify-end items-end pt-3',
							cardFooterStyle
						)}
					>
						{Footer}
					</CardFooter>
				)}
			</div>
		</Card>
	);
};

export default BaseCard;
