import { useEffect, useMemo, useRef, useState } from 'react';
import { Card, CardContent } from '../../atoms/Card';
import { formatNumberWithPrefix } from '../../../utils/number';
import { getMarketDataPrice } from '../../../controllers/transactions/api';

const CurrenciesWidget = () => {
	const marqueeRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [marqueeWidth, setMarqueeWidth] = useState<number>(0);
	const [containerWidth, setContainerWidth] = useState<number>(0);
	const [transactionServiceData, setTransactionServiceData] = useState<
		Record<
			string,
			{
				USD: number;
			}
		>
	>();

	useEffect(() => {
		(async () => {
			const response = await getMarketDataPrice();
			setTransactionServiceData(response?.data as any);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const bannerData = useMemo(() => {
		return transactionServiceData
			? Object.keys(transactionServiceData).map((key) => ({
					cryptocurrency: key.toUpperCase(),
					amount: {
						value: transactionServiceData?.[key].USD,
					},
				}))
			: [];
	}, [transactionServiceData]);

	useEffect(() => {
		if (marqueeRef.current && containerRef.current) {
			const containerWidth = containerRef.current.offsetWidth;
			const contentWidth = marqueeRef.current.scrollWidth;

			setContainerWidth(containerWidth);
			setMarqueeWidth(contentWidth);
		}
	}, [bannerData]);

	return (
		<Card ref={containerRef} className="overflow-hidden p-2 sm:p-2 w-full">
			<div className="marquee-wrapper">
				<CardContent
					ref={marqueeRef}
					className="marquee flex items-center divide-x pl-3"
				>
					{[...bannerData, ...bannerData].map((item, index) => (
						<div
							key={index}
							className="flex gap-5 justify-center items-center px-5 whitespace-nowrap"
						>
							<p>{item?.cryptocurrency}</p>
							<p>
								${formatNumberWithPrefix(item?.amount?.value || 0, '', 16, 2)}
							</p>
						</div>
					))}
				</CardContent>
			</div>

			<style>{`
        .marquee-wrapper {
          display: flex;
          overflow: hidden;
          position: relative;
        }

        .marquee {
          display: inline-flex;
          animation: marquee ${marqueeWidth / 25}s linear infinite;
        }

        @keyframes marquee {
          0% {
            transform: translateX(${containerWidth}px);
          }
          100% {
            transform: translateX(-${marqueeWidth}px);
          }
        }
      `}</style>
		</Card>
	);
};

export default CurrenciesWidget;
