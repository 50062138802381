import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { WalletsState, walletsActions } from './slice';

type WalletsServiceOperators = WalletsState & {
	getWallets: () => void;
};

const useWalletsService = (): Readonly<WalletsServiceOperators> => {
	const dispatch = useAppDispatch();
	const walletsState = useAppSelector((state) => state.wallets as WalletsState);

	const fetchSupportedChains = useCallback(() => {
		dispatch(walletsActions.getSupportedChainsRequest());
	}, [dispatch]);

	useEffect(() => {
		if (
			!walletsState.data?.supportedChains?.chains?.length &&
			!walletsState.loading.supportedChains
		) {
			fetchSupportedChains();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		data: walletsState.data,
		loading: walletsState.loading,
		error: walletsState.error,
		getWallets: useCallback(() => {
			dispatch(walletsActions.fetchWalletsRequest());
		}, [dispatch]),
	};
};

export default useWalletsService;
