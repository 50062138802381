export const formatNumber = (num: number, decimals: number = 2) => {
	let result;
	if (num < 1000) {
		result = formatNumberWithPrefix(num, '', decimals, decimals);
	} else if (num >= 1000 && num < 1000000) {
		result = formatNumberWithPrefix(num, '', decimals, decimals) + 'K';
	} else if (num >= 1000000) {
		result =
			formatNumberWithPrefix(num / 1000000, '', decimals, decimals) + 'M';
	} else {
		result = formatNumberWithPrefix(num, '', decimals, decimals);
	}

	if (num > 0 && !Number(result)) {
		result = `${'<0.'.padEnd(decimals + 2, '0')}1`;
	}

	return result;
};

export const formatNumberWithPrefix = (
	value: number,
	prefix = '',
	maxDecimals: number = 2,
	minDecimals: number = 2
) => {
	const sanitizedValue = Number(value || 0);

	const actualDecimals =
		Number(sanitizedValue || 0)
			.toString()
			.split('.')[1]?.length || 0;

	const hasSignificantDigits =
		actualDecimals > minDecimals &&
		Number(sanitizedValue.toFixed(maxDecimals)) !== 0;

	const formattedNumber = sanitizedValue.toLocaleString(undefined, {
		minimumFractionDigits: minDecimals,
		maximumFractionDigits: hasSignificantDigits ? maxDecimals : minDecimals,
	});

	return `${prefix}${formattedNumber}`;
};
