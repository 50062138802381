import { useNavigate, useParams } from 'react-router-dom';

import { useEffect, useState } from 'react';
import useWalletService from '../../../controllers/wallet/service';
import { iconMap } from '../../../theme/Icons';
import { Badge } from '../../atoms/Badge';
import { Button } from '../../atoms/Button';
import { Card, CardContent, CardFooter } from '../../atoms/Card';
import ResponsiveIcon from '../../atoms/Icon';
import { Progress } from '../../atoms/Progress';
import TransactionHistoryTable from '../../specialized/wallet/TransactionHistoryTable';
import { historyColumns } from '../../specialized/wallet/transactionHistoryColumns';
import { riskLevelColorMap } from './WalletsPage';

import migrate from '../../../assets/images/migrate.png';
import useSendTransactionsService from '../../../controllers/transactions/service';
import { cn } from '../../../libs/cn';
import { RiskLvl } from '../../../types/transaction';
import { ResponseAsset } from '../../../types/wallets';
import { Spinner } from '../../atoms/Spinner';

const WalletDetailsPage: React.FC = () => {
	const navigate = useNavigate();

	const {
		getWalletById,
		getTransactionsByWalletId,
		getWalletAssets,
		data: { walletById, transactions, assets },
		pagination,
		loading,
		error,
	} = useWalletService();

	const { supportedCoinsList } = useSendTransactionsService();
	const [page, setPage] = useState(1);

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getWalletById(id);
			getWalletAssets(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getWalletById, id]);

	useEffect(() => {
		if (id) {
			getTransactionsByWalletId(id, {
				ps: 10,
				pn: page,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, id]);

	if (loading.walletById || loading.transactions) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	const handleMigrate = (item: ResponseAsset) => {
		const params = new URLSearchParams({
			amount: item.amount?.toString(),
			coin: item.symbol.toUpperCase(),
		});
		navigate(`/send?${params.toString()}`);
	};

	const formattedTransactions = transactions?.map((transaction) => {
		return {
			...transaction,
			chainId: walletById?.chainId,
		};
	});

	return (
		<div className="gap-4 h-full flex flex-col xl:grid grid-cols-4">
			<Card className="col-span-1 flex flex-col justify-between bg-grey-10 !p-2.5">
				<CardContent className="gap-8 flex flex-col">
					<div
						className={`bg-wallets-${walletById?.riskLevel}bg rounded-[10px] p-4`}
					>
						<div className="flex justify-between items-center gap-2 flex-col sm:flex-row flex-wrap">
							<p className="text-xl font-bold">{walletById?.name}</p>
							<p className="text-sm font-normal font-robotto text-grey-600">
								Safely migrate your cryptocurrency to a new wallet address.{' '}
							</p>
						</div>

						<div
							className={cn('relative', {
								'min-h-32': loading.assets,
							})}
						>
							{loading.assets && <Spinner />}
							{assets?.assets
								?.filter((asset) =>
									supportedCoinsList.includes(asset.symbol.toUpperCase())
								)
								.map((item) => (
									<div
										key={item?.symbol}
										className="flex  pb-2 pt-4 w-full flex-col sm:flex-row sm:items-center justify-between flex-wrap"
									>
										<div className="flex items-center w-full w-full">
											<div className="bg-grey-300 p-2 rounded-full w-fit mr-2">
												<div
													key={item?.symbol}
													className="bg-white p-2 rounded-full w-3.5 h-3.5 bg-cover bg-center rounded-full overflow-hidden shrink-0"
													style={{
														backgroundImage: `url(${item.image_url || '/no-image.png'})`,
													}}
												/>
											</div>
											<div className="flex justify-between w-full items-center">
												<div className="flex gap-x-2">
													<span className="truncate text-sm font-normal font-robotto">
														{item?.symbol}
													</span>
													<span className="text-sm font-normal font-robotto truncate">
														{item?.amount}
													</span>
												</div>
											</div>
											{walletById?.riskLevel !== RiskLvl.critical && (
												<Button
													variant="icon"
													size="icon"
													className=" w-10 h-10 aspece-square"
													onClick={() => handleMigrate(item)}
												>
													<img src={migrate} alt="Migrate Logo" />
												</Button>
											)}
										</div>

										<div className="flex items-center gap-6 mt-4 sm:mt-0 sm:ml-auto justify-end">
											{/* <Button
										// disabled={!item.canSend}
										disabled
										iconPosition="right"
										icon={
											<ResponsiveIcon
												icon={iconMap.arrow}
												className="w-4 h-5"
											/>
										}
									>
										Go to Send
									</Button> */}
										</div>
									</div>
								))}
						</div>
						<div>
							<div className="text-sm font-normal font-robotto">
								Wallet Address
							</div>
							<p className="truncate max-w-80 text-sm font-bold	 font-robotto">
								{walletById?.address}
							</p>
						</div>

						<div className="flex items-center gap-2">
							<p className="text-sm font-normal font-robotto">Risk Level</p>
							<Badge variant={walletById?.riskLevel} className="bg-grey-10">
								{walletById?.riskLevel}
							</Badge>
						</div>
					</div>

					{walletById?.riskFactors && walletById?.riskFactors.length > 0 && (
						<div className="flex gap-2 items-center flex-wrap border  p-[10px] rounded-xl	">
							<p className="text-sm font-normal font-robotto">Risk Factors</p>
							<div className="flex gap-2 flex-wrap">
								{walletById?.riskFactors.map((factor, index) => {
									const factorBg =
										riskLevelColorMap[walletById?.riskLevel] || 'bg-gey-table';

									return (
										<Badge
											key={index}
											className={cn('text-black px-2 py-0 min-w-20', factorBg)}
										>
											{factor}
										</Badge>
									);
								})}
							</div>
						</div>
					)}
				</CardContent>

				<CardFooter className="justify-end">
					<Button
						size={'icon'}
						variant={'icon'}
						icon={<ResponsiveIcon icon={iconMap.delete} className="w-4 h-5" />}
						className="bg-transparent border-none"
					/>
				</CardFooter>
			</Card>

			<Card className="col-span-3 sm:px-2 bg-grey-10">
				<TransactionHistoryTable
					data={formattedTransactions}
					isLoading={loading.transactions}
					columns={historyColumns}
					error={error.transactions}
					paginationData={pagination.transactions}
					page={page}
					onPageChange={setPage}
				/>
			</Card>
		</div>
	);
};

export default WalletDetailsPage;
