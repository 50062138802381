import { useEffect, useMemo, useState } from 'react';
import useDashboardDataService from '../../controllers/dashboard/service';
import CurrenciesWidget from '../specialized/widgets/CurrenciesWidget';
import { dashboardColumns } from '../specialized/TransactionTable/columns';
import { TransactionTable } from '../specialized/TransactionTable/TransactionTable';
import DateFilterPanel, { Range } from '../specialized/DateFilterPanel';
import Volume from '../specialized/charts/Volume';
import TransactionsInRisk from '../specialized/charts/TransactionInRisk';
import MonitoredWalletsPanel from '../specialized/MonitoredWalletsPanel';
import QuickTransaction from '../specialized/QuickTransaction';
import BaseMultiSelect from '../organisms/BaseMultiSelect';
import { Progress } from '../atoms/Progress';

import { useNavigate } from 'react-router-dom';
import { TransactionTimeRange } from '../../types/transaction';
import useWalletsService from '../../controllers/wallets/service';
import dayjs from 'dayjs';
import useSendTransactionsService from '../../controllers/transactions/service';

const TransactionTimeRangeMap = {
	[Range.today]: TransactionTimeRange.day,
	[Range.month]: TransactionTimeRange.month,
	[Range.week]: TransactionTimeRange.week,
	[Range.custom]: null,
};

const Dashboard: React.FC = () => {
	const navigate = useNavigate();

	const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
	const [customDateRangeStart, setCustomDateRangeStart] = useState<
		Date | undefined
	>(new Date());
	const [customDateRangeEnd, setCustomDateRangeEnd] = useState<Date>();

	const { loadTransactions } = useDashboardDataService();
	const {
		data: walletsData,
		getWallets,
		// loading: walletsLoading,
	} = useWalletsService();

	const [selectedRange, setSelectedRange] = useState<Range>(Range.today);
	const [selectedCurrency, setSelectedCurrency] = useState<string[]>([]);

	const { supportedCoinsList } = useSendTransactionsService();

	useEffect(() => {
		loadTransactions({
			pn: 1,
			ps: 5,
			sort_order: 'desc',
			sort_by: 'created_at',
		});
	}, [loadTransactions]);

	const {
		data,
		error,
		loading,
		// loadTransactions,
		loadMonitoredWallets,
		loadTransactionsStats,
		loadVolume,
		byRiskLevel,
	} = useDashboardDataService();

	useEffect(() => {
		// loadTransactions();
		loadMonitoredWallets();
		loadTransactionsStats();
		loadVolume();
		getWallets();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const timeRange = TransactionTimeRangeMap[selectedRange];

		if (
			!timeRange &&
			((!customDateRangeStart && !customDateRangeEnd) || isDatePickerVisible)
		)
			return;

		loadTransactionsStats({
			...(timeRange && { time_range: timeRange }),
			start_date: dayjs(customDateRangeStart).format('YYYY-MM-DD'),
			end_date: dayjs(customDateRangeEnd).format('YYYY-MM-DD'),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedRange,
		loadTransactionsStats,
		customDateRangeStart,
		customDateRangeEnd,
	]);

	const handleFilterChange = (range: Range) => {
		if (range === Range.custom && selectedRange === Range.custom) {
			setIsDatePickerVisible(false);
		}
		if (range === Range.custom) {
			setIsDatePickerVisible(true);
		}
		setSelectedRange(range);
	};

	const handleDateRangeFilterChange = (dates: (Date | null)[]) => {
		const [start, end] = dates;
		setCustomDateRangeStart(start || undefined);
		setCustomDateRangeEnd(end || undefined);
		if (!start || !end) return;
		setIsDatePickerVisible(false);
	};

	const volumeChartData = useMemo(() => {
		return [
			{
				...(data.transactionStats?.current_period || {}),
				name: `${selectedRange !== Range.custom ? `This ${selectedRange}` : 'Custom'}`,
				type: 'last_24',
			},
			{
				...(data.transactionStats?.previous_period || {}),
				name: 'Previous matching period',
				type: 'prev_matching_period',
			},
		].map((period) => ({
			name: period.name,
			total: period?.total_transactions,
			type: period.type,
			value: period?.mbg_transactions,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.transactionStats]);

	if (loading.transactions || loading.wallets || loading.volume) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-5 flex-1">
			<div className="flex flex-col gap-8 md:flex-row items-center">
				<CurrenciesWidget />

				<div className="flex justify-center items-center gap-8">
					<BaseMultiSelect
						selectedValue={selectedCurrency}
						setSelectedValue={setSelectedCurrency}
						triggerClassName="border-input"
						placeholder="USD, BTC..."
						values={supportedCoinsList.map((item) => item as string)}
					/>
				</div>
			</div>

			<div className="relative">
				<DateFilterPanel
					selectedRange={selectedRange}
					onFilterChange={handleFilterChange}
					customDateRange={{
						startDate: customDateRangeStart,
						endDate: customDateRangeEnd,
						onChange: handleDateRangeFilterChange,
						visible: isDatePickerVisible,
						onClickOutside: () => setIsDatePickerVisible(false),
					}}
				/>
			</div>

			<div className="grid grid-cols-1 xl:grid-cols-12 gap-5">
				<div className="col-span-1 xl:col-span-8 2xl:col-span-9 space-y-5">
					<div className="grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-12 gap-5">
						<div className="col-span-1  xl:col-span-1 2xl:col-span-4">
							<Volume
								data={volumeChartData}
								totalVolume={data.transactionStats?.current_period.total_volume}
								isLoading={loading.transactionStats}
							/>
						</div>

						<div className="col-span-1 order-3 2xl:order-none xl:col-span-3 2xl:col-span-4">
							<TransactionsInRisk
								data={byRiskLevel}
								totalTransaction={
									data.transactionStats?.current_period.total_transactions || 0
								}
								isLoading={loading.transactionStats}
							/>
						</div>

						<div className="col-span-1 xl:col-span-2 2xl:col-span-4">
							<MonitoredWalletsPanel data={walletsData.wallets} />
						</div>
					</div>

					<div className="flex-1">
						<TransactionTable
							columns={dashboardColumns}
							isLoading={loading.transactions}
							error={error.transactions}
							title="Latest Transactions"
							// subtitle="Showing 5 of 32"
							data={data.transactions}
							onClick={() => {
								navigate('/transactions');
							}}
							type="simple"
							btnText="Manage All Transaction"
						/>
					</div>
				</div>

				<div className="col-span-1 xl:col-span-4 2xl:col-span-3">
					<QuickTransaction />
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
