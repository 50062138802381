import { API_BASE_TRANSACTION_URL, API_BASE_WALLET_URL } from '../../config';
import { axiosInstanceApi } from '../../services/axios';
import { ResponseData } from '../../types/common';
import {
	ResponsePostTransaction,
	ResponseSupportedCoinData,
} from '../../types/transaction';
import { SendTransaction } from './slice';

export const postTransactionApi = async (data: SendTransaction[]) => {
	const results = await Promise.allSettled(
		data.map((transaction, index) =>
			axiosInstanceApi
				.post<ResponseData<ResponsePostTransaction>>(
					`${API_BASE_TRANSACTION_URL}/v1/transaction`,
					{
						outputs: [
							{
								address: transaction.address,
								amount: Number(transaction.amount),
							},
						],
						coin: transaction.coin,
						chain: transaction.chain,
						use_mbg: transaction.useMbg,
					}
				)
				.then((response) => ({
					success: true,
					index,
					data: response.data,
					transaction,
				}))
				.catch((error) => ({
					success: false,
					index,
					transaction,
					error: error.response?.data || error.message,
				}))
		)
	);

	const successfulResults = results.filter(
		(
			result
		): result is PromiseFulfilledResult<{
			success: true;
			index: number;
			data: ResponseData<ResponsePostTransaction>;
			transaction: SendTransaction;
		}> => result.status === 'fulfilled' && result.value.success
	);

	const failedResults = results.filter(
		(
			result
		): result is PromiseFulfilledResult<{
			success: false;
			index: number;
			transaction: SendTransaction;
			error: any;
		}> => result.status === 'fulfilled' && !result.value.success
	);

	const successfulTransactions = successfulResults.map((result) => ({
		...result.value.transaction,
		response: result.value.data.data,
		status: 'success' as const,
	}));

	const failedTransactions = failedResults.map((result) => ({
		...result.value.transaction,
		error: result.value.error,
		status: 'failed' as const,
	}));

	if (failedTransactions.length > 0) {
		console.log('Failed transactions:', failedTransactions);
	}

	return {
		successful: successfulTransactions,
		failed: failedTransactions,
		allTransactions: [...successfulTransactions, ...failedTransactions].sort(
			(a, b) => data.indexOf(a) - data.indexOf(b)
		),
	};
};

export const postQuickTransactionApi = async (data: SendTransaction) => {
	const response = await axiosInstanceApi.post<
		ResponseData<ResponsePostTransaction>
	>(`${API_BASE_TRANSACTION_URL}/v1/transaction`, {
		outputs: [
			{
				address: data.address,
				amount: Number(data.amount),
			},
		],
		coin: data.coin,
		chain: data.chain,
		use_mbg: data.useMbg,
	});

	return response.data;
};

export const getMarketDataPrice = async (
	params: {
		fsym?: string;
		tsyms?: string;
	} = {}
) => {
	const queryString = new URLSearchParams(params).toString();
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<Record<string, number>>
		>(`${API_BASE_WALLET_URL}/v1/marketdata/price?${queryString}`, {});

		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getSupportedCoins = async () => {
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<ResponseSupportedCoinData>
		>(`${API_BASE_TRANSACTION_URL}/v1/transaction/supported-coin`);
		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getPauseEligibility = async (transactionId: string) => {
	try {
		const response = await axiosInstanceApi.get<ResponseData<boolean>>(
			`${API_BASE_TRANSACTION_URL}/v1/transaction/${transactionId}/pause-eligibility`
		);
		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getTransactionProgress = async (transactionId: string) => {
	const response = await axiosInstanceApi.get<
		ResponseData<{
			percent_progress: number;
		}>
	>(`${API_BASE_TRANSACTION_URL}/v1/transaction/${transactionId}/progress`);
	return response.data;
};

export const getTransactionExcelReport = async () => {
	const response = await axiosInstanceApi.get<
		ResponseData<{
			download_link: string;
			filter_applied: boolean;
		}>
	>(`${API_BASE_TRANSACTION_URL}/v1/transaction/report/excel`);
	return response.data;
};
