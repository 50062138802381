import { _wallets } from '../../_mock';
import { API_BASE_WALLET_URL } from '../../config';
import { walletTransactionsResponseMapper } from '../../mapper/wallet';
import { axiosInstanceApi } from '../../services/axios';
import { ResponseWalletTransaction } from '../../types/wallets';
import {
	ResponseData,
	ResponsePaginated,
	ResponseWalletSupportedChains,
} from './../../types/common';

export const getWalletByIdApi = (id: number) => {
	return new Promise((resolve) => {
		const mockData = _wallets.find((wallet) => wallet.id === id);

		setTimeout(() => {
			resolve({ data: mockData });
		}, 1000);
	});
};

export interface GetTransactionsByWalletIdParams {
	pn?: number;
	ps?: number;
	sort_order?: string;
	sort_by?: string;
}

export const getTransactionsByWalletIdApi = async (
	walletId: string,
	params?: GetTransactionsByWalletIdParams
) => {
	const newParams = params || {
		pn: 1,
		ps: 10,
	};
	try {
		const queryParams = {
			...newParams,
			pn: `${newParams?.pn}`,
			ps: `${newParams?.ps}`,
			wallet_id: walletId,
		};
		const queryString = new URLSearchParams(queryParams).toString();

		const response = await axiosInstanceApi.get<
			ResponseData<ResponsePaginated<ResponseWalletTransaction>>
		>(
			`${API_BASE_WALLET_URL}/v1/wallet/transactions?${queryString.toString()}`
		);

		const formattedResponse = response.data.data.data.map((transaction) =>
			walletTransactionsResponseMapper(walletId, transaction)
		);

		return {
			...response.data.data,
			data: formattedResponse,
		};
	} catch (error) {
		console.log('error', error);
	}
};

export const postNewWallet = async ({
	walletAddress,
	walletChain,
	walletName,
}: {
	walletAddress: string;
	walletChain: string;
	walletName: string;
}) => {
	const response = await axiosInstanceApi.post<any>(
		`${API_BASE_WALLET_URL}/v1/wallet`,
		{
			wallet_address: walletAddress,
			wallet_chain: walletChain.toLowerCase(),
			wallet_name: walletName,
		}
	);
	return response.data;
};

export const getWalletExposure = async ({
	walletAddress,
	chain,
}: {
	walletAddress: string;
	chain: string;
}) => {
	const response = await axiosInstanceApi.get<any>(
		`${API_BASE_WALLET_URL}/v1/wallet/exposure`,
		{
			params: {
				address: walletAddress,
				chain: chain.toLowerCase(),
			},
		}
	);
	return response.data;
};

export const putWalletName = async ({
	walletAddress,
	walletName,
}: {
	walletAddress: string;
	walletName: string;
}) => {
	const response = await axiosInstanceApi.put<any>(
		`${API_BASE_WALLET_URL}/v1/wallet/${walletAddress}/name`,
		{
			new_name: walletName,
		}
	);
	return response.data;
};

export const getWalletSupportedCoins = async () => {
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<ResponseWalletSupportedChains>
		>(`${API_BASE_WALLET_URL}/v1/wallet/supported-chain`);
		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};
