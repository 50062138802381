import React from 'react';
import { cn } from '../../libs/cn';

export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
	progress: number; // 0-100
	type?: 'default' | 'line';
	isError?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
	progress,
	className,
	type = 'default',
	isError,
	...props
}) => {
	// Ensure progress is within bounds
	const clampedProgress = Math.min(100, Math.max(0, progress));

	if (type === 'line') {
		return (
			<div className="relative mt-2">
				<div className="h-2 relative overflow-hidden rounded-full">
					<div
						style={{ width: isError ? '8px' : `${clampedProgress}%` }}
						className={cn(
							'h-2 bg-gradient-primary absolute z-10 rounded-full',
							isError && 'bg-gradient-high right-0'
						)}
					></div>
					<div className="absolute inset-0 flex items-center">
						<div className="w-full h-1 bg-[#363636]"></div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className={cn(
				'w-full h-3 rounded-full border border-grey-300',
				className
			)}
			{...props}
		>
			<div
				className={cn('h-full rounded-full bg-gradient-primary', className)}
				style={{ width: `${clampedProgress}%` }}
			/>
		</div>
	);
};

export default ProgressBar;
