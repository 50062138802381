import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';
import {
	SendTransaction,
	sendTransactionsActions,
	SendTransactionsState,
	SendTransactionWithResponse,
} from './slice';
import { ResponseData } from '../../types/common';
import { ResponsePostTransaction } from '../../types/transaction';
import { store } from '../../store/config.store';

type SendTransactionServiceOperators = SendTransactionsState & {
	addDraftTransaction: (data: SendTransaction) => void;
	removeDraftTransaction: (id: string) => void;
	sendQuickTransaction: (
		data: Omit<SendTransaction, 'id'>
	) => Promise<ResponseData<ResponsePostTransaction>>;
	sendTransactions: (
		transactions: SendTransaction[]
	) => Promise<SendTransactionWithResponse[]>;
	fetchMarketDataPrice: (fsym: string, tsyms: string) => void;
	fetchSupportedCoins: () => void;
	supportedCoinsList: string[];
	clearQuickTransaction: () => void;
};

const useSendTransactionsService =
	(): Readonly<SendTransactionServiceOperators> => {
		const dispatch = useDispatch();
		const sendTransactionState = useAppSelector(
			(state) => state.sendTransactions as SendTransactionsState
		);

		const addDraftTransaction = useCallback(
			async (data: SendTransaction) => {
				dispatch(
					sendTransactionsActions.addTransaction({
						...data,
						id: new Date().getTime().toString(),
						chain: data.chain,
						addedDate: new Date().getTime(),
					})
				);
			},
			[dispatch]
		);

		const removeDraftTransaction = useCallback(
			(id: string) => {
				dispatch(sendTransactionsActions.removeTransaction(id));
			},
			[dispatch]
		);

		const sendQuickTransaction = useCallback(
			async (
				data: Omit<SendTransaction, 'id'>
			): Promise<ResponseData<ResponsePostTransaction>> => {
				return new Promise((resolve, reject) => {
					const subscription = store.subscribe(() => {
						const state = store.getState().sendTransactions;

						if (state.data.quickTransaction) {
							subscription();
							resolve(state.data.quickTransaction);
						}

						if (state.error.quickTransaction) {
							subscription();
							reject(new Error(state.error.quickTransaction));
						}
					});

					dispatch(sendTransactionsActions.sendQuickTransactionRequest(data));
				});
			},
			[dispatch]
		);

		const sendTransactions = useCallback(
			async (
				transactions: SendTransaction[]
			): Promise<SendTransactionWithResponse[]> => {
				return new Promise((resolve, reject) => {
					const subscription = store.subscribe(() => {
						const state = store.getState().sendTransactions;

						if (state.data.transactionsSent) {
							subscription();
							resolve(state.data.transactionsSent);
						}

						if (state.error.transactionsSent) {
							subscription();
							reject(new Error(state.error.transactionsSent));
						}
					});

					dispatch(
						sendTransactionsActions.sendTransactionsRequest(transactions)
					);
				});
			},
			[dispatch]
		);

		const fetchMarketDataPrice = useCallback(
			(fsym: string, tsyms: string) => {
				dispatch(
					sendTransactionsActions.getMarketDataPriceRequest({ fsym, tsyms })
				);
			},
			[dispatch]
		);

		const fetchSupportedCoins = useCallback(() => {
			dispatch(sendTransactionsActions.getSupportedCoinsRequest());
		}, [dispatch]);

		useEffect(() => {
			if (
				!sendTransactionState.data.supportedCoins?.supported_coins?.length &&
				!sendTransactionState.loading.supportedCoins
			) {
				fetchSupportedCoins();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const supportedCoinsList = useMemo(() => {
			return sendTransactionState.data.supportedCoins?.supported_coins
				? sendTransactionState.data.supportedCoins?.supported_coins?.map(
						(coin) => coin.coin.toUpperCase().trim()
					)
				: [];
		}, [sendTransactionState.data.supportedCoins?.supported_coins]);

		const clearQuickTransaction = useCallback(() => {
			dispatch(sendTransactionsActions.clearQuickTransaction());
		}, [dispatch]);

		return {
			data: sendTransactionState.data,
			loading: sendTransactionState.loading,
			error: sendTransactionState.error,
			addDraftTransaction,
			removeDraftTransaction,
			fetchMarketDataPrice,
			sendQuickTransaction,
			fetchSupportedCoins,
			sendTransactions,
			supportedCoinsList,
			clearQuickTransaction,
		};
	};

export default useSendTransactionsService;
