import { _monitoredWallets, _volume } from '../../_mock';
import { API_BASE_TRANSACTION_URL } from '../../config';
import { transactionResponseMapper } from '../../mapper';
import { axiosInstanceApi } from '../../services/axios';
import {
	ResponseData,
	ResponsePaginated,
	ResponsePeriods,
} from '../../types/common';
import {
	ResponseTransaction,
	ResponseTransactionStats,
	TransactionTimeRange,
} from '../../types/transaction';

export const getTransactionsApi = async (
	params: {
		pn?: number;
		ps?: number;
		sort_order?: string;
		sort_by?: string;
	} = {
		pn: 1,
		ps: 10,
	}
) => {
	const queryParams = {
		...params,
		pn: `${params?.pn}`,
		ps: `${params?.ps}`,
	};
	const queryString = new URLSearchParams(queryParams).toString();
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<ResponsePaginated<ResponseTransaction>>
		>(`${API_BASE_TRANSACTION_URL}/v1/transaction?${queryString}`, {});
		const formattedList = response.data.data.data.map((transaction) =>
			transactionResponseMapper(transaction)
		);
		const data = { ...response.data.data, data: formattedList };
		return data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getTransactionsStatsApi = async (params: {
	time_range?: TransactionTimeRange;
	start_date?: string;
	end_date?: string;
}) => {
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<
				ResponsePeriods<ResponseTransactionStats, ResponseTransactionStats>
			>
		>(`${API_BASE_TRANSACTION_URL}/v1/transaction/stat`, {
			params,
		});
		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getMonitoredWalletsApi = () => {
	//Monitored Wallets

	return new Promise((resolve) => {
		const mockData = _monitoredWallets;

		setTimeout(() => {
			resolve({ data: mockData });
		}, 2000);
	});
};

export const getVolumesApi = () => {
	//volume
	return new Promise((resolve) => {
		const mockData = _volume;

		setTimeout(() => {
			resolve({ data: mockData });
		}, 2000);
	});
};
