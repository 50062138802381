import { ResponseTransaction, Transaction } from '../types/transaction';
import { RecursivePartial } from '../types/util';

export const transactionResponseMapper = (
	transaction: ResponseTransaction
): RecursivePartial<Transaction> => {
	const timestamp = transaction.created_at;
	const date = new Date(timestamp * 1000);
	const dateTime = date.toLocaleString();

	return {
		id: transaction.transaction_id,
		dateTime,
		from: {
			code: transaction.from_address,
			wallet: transaction.from_address,
			walletId: 1,
		},
		proxy: '',
		to: {
			code: transaction.outputs?.[0].address,
			wallet: transaction.outputs?.[0].address,
			walletId: 1,
		},
		hash: transaction.hash,
		totalAmount: {
			from: {
				value: transaction.amount,
				unit: transaction.coin,
			},
			to: {
				value: transaction.outputs?.[0].amount,
				unit: transaction.coin,
			},
			fee: transaction.fee,
		},
		riskLevel: transaction.outputs?.[0].risk_score.toLowerCase(),
		riskFactor: transaction.outputs?.[0].risk_factors,
		status: transaction.status.toLowerCase(),
		moneyBackGuarantee: transaction.mbg ? 'yes' : 'no',
		chainId: transaction.chain,
		coinName: transaction.coin,
		outputs: transaction.outputs,
		fiatTotalAmount: transaction.fiat_total_amount,
	};
};
