import { SagaIterator } from 'redux-saga';
import { notificationActions } from './slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getNotificationsApi } from './api';

function* fetchNotificationSaga(): SagaIterator {
	try {
		const response = yield call(getNotificationsApi);
		yield put(notificationActions.fetchNotificationsSuccess(response.data));
	} catch (error) {
		yield put(
			notificationActions.fetchNotificationsFailure('Failed to load wallets')
		);
	}
}

function* notificationWatcherSaga(): SagaIterator {
	yield takeLatest(
		notificationActions.fetchNotificationsRequest.type,
		fetchNotificationSaga
	);
}

export default notificationWatcherSaga;
