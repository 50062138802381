export const getRandomDate = (startDate: Date, endDate: Date) => {
	const start = new Date(startDate);
	const end = new Date(endDate);

	if (isNaN(start.getTime()) || isNaN(end.getTime())) {
		throw new Error(
			'Invalid date provided. Ensure both startDate and endDate are valid Date objects or valid date strings.'
		);
	}

	if (start > end) {
		throw new Error('The startDate should be before or equal to the endDate.');
	}

	const randomTimestamp =
		Math.random() * (end.getTime() - start.getTime()) + start.getTime();

	const randomDate = new Date(randomTimestamp);

	return randomDate.toUTCString();
};

export const getRandomHashString = (length: number) => {
	return [...Array(length)]
		.map(() => Math.floor(Math.random() * 16).toString(16))
		.join('');
};

export const getRandomFromArray = (arr: (string | number)[]) => {
	return arr[Math.floor(Math.random() * arr.length)];
};
