import axios from 'axios';
import { store } from '../store/config.store';
import { deleteSecureToken } from '../utils/tokenStorage';

export const axiosInstanceApi = axios.create();

axiosInstanceApi.interceptors.request.use(async (config) => {
	const state = store.getState();
	const accessToken = state.auth.data.accessToken;
	config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : '';
	return config;
});

axiosInstanceApi.interceptors.response.use(
	(response) => response,
	async (error) => {
		const is401 =
			error.response?.status === 401 ||
			error.message?.includes('401') ||
			error.message?.includes('Unauthorized');

		if (is401) {
			await handleAuthFailure();

			// const refreshToken = getSecureToken('refreshToken');
			// if (!refreshToken) {
			// 	await handleAuthFailure();
			// 	return Promise.reject(error);
			// }

			// originalRequest._retry = true;

			// try {
			// 	await store.dispatch(refreshTokenWorker());

			// 	const newState = store.getState();
			// 	const newToken = newState.auth.data.accessToken;

			// 	if (!newToken) {
			// 		throw new Error('Token refresh failed');
			// 	}

			// 	originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
			// 	return axiosInstanceApi(originalRequest);
			// } catch (refreshError) {
			// 	await handleAuthFailure();
			// 	return Promise.reject(refreshError);
			// }
		}

		return Promise.reject(error);
	}
);

const handleAuthFailure = async () => {
	deleteSecureToken('accessToken');
	deleteSecureToken('refreshToken');
	window.location.href = '/';
	try {
		await store.dispatch(store.getState().auth.logout());
	} catch (error) {
		// console.log('error', error);
	}
};
