import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { notificationActions, NotificationState } from './slice';
import { GetNotificationsParams } from './api';

type NotificationServiceOperators = NotificationState & {
	getNotifications: (params?: GetNotificationsParams) => void;
};

const useNotificationService = (): Readonly<NotificationServiceOperators> => {
	const dispatch = useAppDispatch();
	const notificationState = useAppSelector((state) => state.notification);

	return {
		data: notificationState.data,
		loading: notificationState.loading,
		error: notificationState.error,
		getNotifications: useCallback(
			(params) => {
				dispatch(notificationActions.fetchNotificationsRequest(params));
			},
			[dispatch]
		),
	};
};

export default useNotificationService;
