import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from 'react-redux';

import { AppDispatch, RootState } from './config.store';

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
