import { API_BASE_WALLET_URL } from '../../config';
import { walletResponseMapper } from '../../mapper/wallet';
import { axiosInstanceApi } from '../../services/axios';
import { ResponseData, ResponsePaginated } from '../../types/common';
import { ResponseWallet, ResponseWalletAsset } from '../../types/wallets';

export const getWalletsApi = async (
	params: { pn?: number; ps?: number } = { pn: 1, ps: 10 }
) => {
	const queryParams = {
		pn: `${params?.pn}`,
		ps: `${params?.ps}`,
	};
	const queryString = new URLSearchParams(queryParams).toString();
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<ResponsePaginated<ResponseWallet>>
		>(`${API_BASE_WALLET_URL}/v1/wallet?${queryString}`, {});
		const formattedList = response.data.data.data.map((wallet) =>
			walletResponseMapper(wallet)
		);

		const data = { ...response.data.data, data: formattedList };
		return data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getWalletAssetsApi = async (address: string) => {
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<ResponseWalletAsset>
		>(`${API_BASE_WALLET_URL}/v1/wallet/${address}/assets`, {});

		return response.data.data;
	} catch (error) {
		console.log('error', error);
	}
};

// export const getWalletsApi = () => {
// 	return new Promise((resolve) => {
// 		const mockData = _wallets;
// 		setTimeout(() => {
// 			resolve({ data: mockData });
// 		}, 1000);
// 	});
// };
