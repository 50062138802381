import { type SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';

import { walletsActions } from './slice';
import { getWalletAssetsApi, getWalletsApi } from './api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	ResponseData,
	ResponseWalletSupportedChains,
} from '../../types/common';
import { getWalletSupportedCoins } from '../wallet/api';

// Worker Sagas
function* fetchWalletsSaga(): SagaIterator {
	try {
		const response = yield call(getWalletsApi);
		yield put(walletsActions.fetchWalletsSuccess(response.data));
	} catch (error) {
		yield put(walletsActions.fetchWalletsFailure('Failed to load wallets'));
	}
}

function* fetchWalletAssetsSaga(action: PayloadAction<string>): SagaIterator {
	try {
		const response = yield call(getWalletAssetsApi, action.payload);
		yield put(walletsActions.fetchWalletAssetsSuccess(response.data));
	} catch (error) {
		yield put(
			walletsActions.fetchWalletAssetsFailure({
				address: action.payload,
				error: 'Failed to load wallet assets',
			})
		);
	}
}

function* fetchSupportedChains(): SagaIterator {
	try {
		const response: ResponseData<ResponseWalletSupportedChains> = yield call(
			getWalletSupportedCoins
		);
		yield put(walletsActions.getSupportedChainsSuccess(response.data));
	} catch (error) {
		yield put(
			walletsActions.getSupportedChainsFailure(
				'Failed to load supported chains'
			)
		);
	}
}

// Watcher Saga
function* walletWatcherSaga(): SagaIterator {
	yield takeLatest(walletsActions.fetchWalletsRequest.type, fetchWalletsSaga);
	yield takeLatest(
		walletsActions.fetchWalletAssetsRequest.type,
		fetchWalletAssetsSaga
	);
	yield takeLatest(
		walletsActions.getSupportedChainsRequest.type,
		fetchSupportedChains
	);
}

export default walletWatcherSaga;
