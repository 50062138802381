import { PieChartData } from '../components/organisms/Chart/PieChart';

export type TotalAmountType = {
	from: {
		value: number;
		unit: string;
	};
	to: {
		value: number;
		unit: string;
	};
	fee: string;
	fiatTotalAmount: string;
};

export type WalletDataType = { code: string; wallet: string; walletId: number };

export type Transaction = {
	id: string; // transaction_id
	dateTime: string; //
	from: WalletDataType; //from_address
	proxy: string; //
	to: WalletDataType;
	hash: string;
	totalAmount: TotalAmountType; //
	riskLevel: string; // risk score
	riskFactor?: string[]; //
	status: string; // status
	moneyBackGuarantee: string;
	coinName: string;
	direction: 'in' | 'out';
	counterparty: string;
	type: string;
	chainId?: string;
	outputs?: ResponseOutputsEntity[] | null;
	fiatTotalAmount?: string;
};

export interface ResponseTransaction {
	transaction_id: string;
	coin: string;
	chain: string;
	hash?: string;
	from_address?: string;
	amount: number;
	fiat_amount: number;
	outputs?: ResponseOutputsEntity[] | null;
	fee: string;
	status: string;
	mbg: boolean;
	mbg_policy_id?: string;
	created_at: number;
	fiat_total_amount: string;
}
export interface ResponseOutputsEntity {
	address: string;
	amount: number;
	hash: string;
	approved: boolean;
	risk_score: string;
	risk_factors: string[];
}

export interface ResponsePostTransaction {
	transaction_id: string;
	input_address: string;
	outputs: ResponsePostOutput[];
	total_amount: string;
	fee: string;
	mbg_policy: boolean;
	policy: null;
}

export interface ResponsePostOutput {
	address: string;
	amount: number;
	hash: null;
	risk_score: string;
	Approved: boolean;
}

export type StatsByStatus = {
	status: string;
	value: number;
};

export type TransactionStats = {
	byRiskLevel: PieChartData[];
	byStatus: PieChartData[];
};

export enum TransactionStatus {
	transferring = 'transferring',
	rejected = 'rejected',
	expired = 'expired',
	completed = 'completed',
	paused = 'paused',
	unknown = 'unknown',
}

export enum BackGuarantee {
	yes = 'yes',
	no = 'no',
}

export enum RiskLvl {
	low = 'low',
	medium = 'med',
	high = 'high',
	critical = 'critical',
}

export enum TransactionTimeRange {
	all = 'all',
	month = 'month',
	week = 'week',
	day = 'day',
}

export interface ResponseTransactionStats {
	status: ResponseStatus;
	total_volume: number;
	total_transactions: number;
	mbg_transactions: number;
	risk_level_totals: ResponseStatusType;
	status_totals: ResponseStatusTotals;
	grand_total: number;
}
export interface ResponseStatus {
	PENDING: ResponseStatusType;
	COMPLETED: ResponseStatusType;
	FAILED: ResponseStatusType;
	IN_PROGRESS: ResponseStatusType;
	PAUSED: ResponseStatusType;
	CANCELLED: ResponseStatusType;
	EXPIRED: ResponseStatusType;
	REJECTED: ResponseStatusType;
	REFUND: ResponseStatusType;
	REFUNDED: ResponseStatusType;
	ERROR_PENDING: ResponseStatusType;
}
export interface ResponseStatusType {
	LOW: number;
	MED: number;
	HIGH: number;
	CRITICAL: number;
}
export interface ResponseStatusTotals {
	PENDING: number;
	COMPLETED: number;
	FAILED: number;
	IN_PROGRESS: number;
	PAUSED: number;
	CANCELLED: number;
	EXPIRED: number;
	REJECTED: number;
	REFUND: number;
	REFUNDED: number;
	ERROR_PENDING: number;
}

export interface ResponseSupportedCoinData {
	total_coins: number;
	total_chains: number;
	supported_coins: ResponseSupportedCoin[];
}

export interface ResponseSupportedCoin {
	coin: string;
	chains: string[];
}
