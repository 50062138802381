import BaseCard from '../../organisms/BaseCard';
import { getChartBgColor } from '../../../utils/getChartColor';
import TransactionStatusChart from '../../organisms/Chart/TransactionStatusChart';
import { Spinner } from '../../atoms/Spinner';
import { EmptyPlaceholder } from '../../atoms/EmptyPlaceholder';
import { iconMap } from '../../../theme/Icons';

const TransactionsInRisk: React.FC<{
	data: any[];
	totalTransaction: number;
	isLoading: boolean;
}> = ({ data, totalTransaction, isLoading }) => {
	const updatedRiskData = data
		.map((item) => {
			const { color } = getChartBgColor(item?.code);
			return { ...item, color };
		})
		?.filter((item) => !!item.value);

	return (
		<BaseCard
			title="Transaction Status & Distribution"
			subtitle={`Total transactions: ${totalTransaction}`}
			cardContentStyle="h-[240px]"
			cardContentWrapperStyle="h-[240px]"
			cardStyle="relative"
		>
			{isLoading && <Spinner />}
			{!isLoading && (
				<>
					{data?.length ? (
						<TransactionStatusChart data={updatedRiskData} />
					) : (
						<EmptyPlaceholder className="flex-1 h-full">
							<EmptyPlaceholder.Icon
								icon={iconMap.noTransactionShadow}
								size={50}
							/>
							<EmptyPlaceholder.Description
								description="No data"
								className="text-md"
							/>
						</EmptyPlaceholder>
					)}
				</>
			)}
		</BaseCard>
	);
};

export default TransactionsInRisk;
