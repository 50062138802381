import { Button } from '../../atoms/Button';
import dayjs from 'dayjs';
import { ResponseNotificationData } from '../../../types/notification';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../atoms/Spinner';
import {
	NotificationTypeBadge,
	NotificationTypeBadgeVariant,
} from '../../molecules/NotificationTypeBadge';

type NotificationPopUpProps = {
	data: ResponseNotificationData | null;
	loading?: boolean;
	handleClose: () => void;
};

const NotificationPopUp: React.FC<NotificationPopUpProps> = ({
	data,
	loading,
	handleClose,
}) => {
	const navigate = useNavigate();

	return (
		<div
			className="absolute right-3 top-20 bg-white rounded-md w-fit min-w-[546px] space-y-4"
			onClick={handleClose}
		>
			<div className="flex justify-between items-center px-8 py-4 border-b border-grey-300">
				<div>
					Notifications{' '}
					{data?.unread_notifications !== undefined
						? `(${data?.unread_notifications})`
						: ''}
				</div>
				<div className="flex gap-x-4 items-center">
					<div className="text-base font-normal">Mark all as read</div>
					<Button
						variant="outline"
						size="sm"
						className="font-normal"
						onClick={() => {
							navigate('/notifications');
						}}
					>
						All Notifications
					</Button>
				</div>
			</div>
			<div className="space-y-4 max-h-[377px] overflow-y-auto relative">
				{loading && (
					<div className="h-[377px]">
						<Spinner />
					</div>
				)}
				{data?.notifications.map((notification) => {
					return (
						<div
							className="space-y-3 px-8 border-b border-grey-300 py-4"
							key={notification.id}
						>
							<div className="flex gap-x-4 font-roboto-condense font-normal items-center">
								<div>
									<NotificationTypeBadge
										variant={
											notification.notification_type as NotificationTypeBadgeVariant
										}
									/>
								</div>
								<div>
									{dayjs.unix(notification.timestamp).format('DD MMM YYYY')}
								</div>
								<div>{dayjs.unix(notification.timestamp).format('H:mm')}</div>
								{!notification.read ? (
									<div className="bg-gradient-primary border border-primary rounded-full py-1 px-4">
										New
									</div>
								) : (
									<div className="text-sm">Read</div>
								)}
							</div>
							<div className="text-[20px]">{notification.title}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export { NotificationPopUp };
