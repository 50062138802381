import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { Wallet } from '../../types/wallets';
import { Transaction } from '../../types/transaction';
import { ResponsePaginated } from '../../types/common';
import { MakeOptional } from '../../types/util';
import { GetTransactionsByWalletIdParams } from './api';

export type WalletState = {
	data: {
		walletById: Wallet | null;
		transactions: Transaction[];
		newWallet: Wallet | null;
	};
	pagination: {
		transactions: Omit<ResponsePaginated<Transaction>, 'data'> | null;
	};
	loading: {
		walletById: boolean;
		transactions: boolean;
		newWallet: boolean;
	};
	error: {
		walletById: string | null;
		transactions: string | null;
		newWallet: string | null;
	};
};

const initialState: WalletState = {
	data: {
		walletById: null,
		transactions: [],
		newWallet: null,
	},
	pagination: {
		transactions: null,
	},
	loading: {
		walletById: false,
		transactions: false,
		newWallet: false,
	},
	error: {
		walletById: null,
		transactions: null,
		newWallet: null,
	},
};

const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		// wallet by id
		fetchWalletByIdRequest: (
			state,
			_action: PayloadAction<{
				id: string;
				wallets?: Wallet[];
			}>
		) => {
			state.loading.walletById = true;
			state.error.walletById = null;
		},
		fetchWalletByIdSuccess: (state, action: PayloadAction<Wallet | null>) => {
			state.loading.walletById = false;
			state.data.walletById = action.payload;
		},
		fetchWalletByIdFailure: (state, action: PayloadAction<string>) => {
			state.loading.walletById = false;
			state.error.walletById = action.payload;
		},
		// transactions
		fetchTransactionsByWalletIdRequest: (
			state,
			_action: PayloadAction<{
				walletId: string;
				params?: GetTransactionsByWalletIdParams;
			}>
		) => {
			state.loading.transactions = true;
			state.error.transactions = null;
		},
		fetchTransactionsByWalletIdSuccess: (
			state,
			action: PayloadAction<ResponsePaginated<Transaction>>
		) => {
			state.loading.transactions = false;
			state.data.transactions = action.payload.data;
			const paginationData = { ...action.payload } as MakeOptional<
				ResponsePaginated<Transaction>,
				'data'
			>;
			delete paginationData.data;
			state.pagination.transactions = paginationData;
		},
		fetchTransactionsByWalletIdFailure: (
			state,
			action: PayloadAction<string>
		) => {
			state.loading.transactions = false;
			state.error.transactions = action.payload;
		},

		createNewWalletRequest: (
			state,
			_action: PayloadAction<{
				walletAddress: string;
				walletChain: string;
				walletName: string;
			}>
		) => {
			state.error.newWallet = null;
			state.loading.newWallet = true;
		},
		createNewWalletSuccess: (state, action: PayloadAction<Wallet>) => {
			state.loading.newWallet = false;
			state.error.newWallet = null;
			state.data.newWallet = action.payload;
		},
		createNewWalletFailure: (state, action: PayloadAction<string>) => {
			state.loading.newWallet = false;
			state.error.newWallet = action.payload;
		},
	},
});

export const walletActions = {
	fetchWalletByIdRequest: walletSlice.actions.fetchWalletByIdRequest,
	fetchWalletByIdSuccess: walletSlice.actions.fetchWalletByIdSuccess,
	fetchWalletByIdFailure: walletSlice.actions.fetchWalletByIdFailure,
	//
	fetchTransactionsByWalletIdRequest:
		walletSlice.actions.fetchTransactionsByWalletIdRequest,
	fetchTransactionsByWalletIdSuccess:
		walletSlice.actions.fetchTransactionsByWalletIdSuccess,
	fetchTransactionsByWalletIdFailure:
		walletSlice.actions.fetchTransactionsByWalletIdFailure,
	//
	createNewWalletRequest: walletSlice.actions.createNewWalletRequest,
	createNewWalletSuccess: walletSlice.actions.createNewWalletSuccess,
	createNewWalletFailure: walletSlice.actions.createNewWalletFailure,
};

// Reducer
export default walletSlice.reducer;
