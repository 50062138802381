import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import useAuthService from '../../../../controllers/auth/service';
import { Button } from '../../../atoms/Button';

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../../atoms/Form';
import { Input } from '../../../atoms/Input';

export const formSchema = z.object({
	email: z.string().email(),
	password: z
		.string()
		.regex(new RegExp('.*[A-Z].*'), 'One uppercase character')
		.regex(new RegExp('.*[a-z].*'), 'One lowercase character')
		.regex(new RegExp('.*\\d.*'), 'One number')
		.min(8, 'Must be at least 8 characters length'),
});

interface LoginFormProps {
	onCredentials: (values: z.infer<typeof formSchema>) => void; // Callback function when sign-in is successful
	onForgotPasswordClick?: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
	onCredentials,
	onForgotPasswordClick,
}) => {
	const { error, clearError, loading } = useAuthService();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		mode: 'onChange',
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const { isSubmitting, isValid } = form.formState;

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onCredentials)}
				className="flex flex-col gap-8 h-full 3xl:gap-14 justify-between"
			>
				<div className="flex flex-col gap-8 3xl:gap-16">
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem className="space-y-1">
								<div className="flex flex-col gap-y-4">
									<FormLabel>Email</FormLabel>
									<FormControl>
										<Input
											className="py-3.5 border-black"
											disabled={isSubmitting}
											{...field}
											onChange={(e) => {
												field.onChange(e);
												clearError();
											}}
										/>
									</FormControl>
								</div>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormItem className="space-y-1">
								<div className="flex flex-col gap-y-4">
									<FormLabel>Password</FormLabel>
									<FormControl>
										<Input
											className="py-3.5 border-black"
											disabled={isSubmitting}
											type={'password'}
											{...field}
											onChange={(e) => {
												field.onChange(e);
												clearError();
											}}
										/>
									</FormControl>
								</div>
								<FormMessage />

								{error.login && (
									<FormMessage className="text-right">
										{error.login}
									</FormMessage>
								)}
							</FormItem>
						)}
					/>
					<div
						className="ml-auto underline text-sm"
						onClick={onForgotPasswordClick}
					>
						Forget Password
					</div>
				</div>

				<div className="mr-0 ml-auto">
					<Button
						size={'lg'}
						type="submit"
						className="bg-white"
						disabled={!isValid || isSubmitting}
						loading={loading.login}
					>
						{isSubmitting
							? 'Sending Verification Code...'
							: 'Send Verification Code'}
					</Button>
				</div>
			</form>
		</Form>
	);
};

export default LoginForm;
