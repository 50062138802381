import { useLocation, useMatches } from 'react-router-dom';

import { CustomRouteObject } from '../../../routes/router';
import { Header } from '../../organisms/Header/Header';
import { MobileSidebar } from './MobileNavigation';

interface NavbarRoutesProps {
	routes: CustomRouteObject[];
	className?: string;
}

const Navbar: React.FC<NavbarRoutesProps> = ({ routes, className }) => {
	const matches = useMatches();
	const location = useLocation();

	const currentMatch: any = matches.find(
		(m) => m.pathname === location.pathname
	);

	const params = currentMatch?.params;
	const { handle } = currentMatch;

	const pageTitle = handle?.(params)?.pageTitle ?? '';

	return (
		<div className="px-3 pr-0 sm:p-4 h-full flex items-center backdrop-blur">
			<MobileSidebar routes={routes} />
			<Header title={pageTitle} className={className} />
		</div>
	);
};

export default Navbar;
