import { Command as CommandPrimitive } from 'cmdk';
import React, {
	createContext,
	forwardRef,
	useCallback,
	useContext,
	useState,
} from 'react';
import { Command, CommandEmpty, CommandItem, CommandList } from './Command';
import ResponsiveIcon from './Icon';
import { iconMap } from '../../theme/Icons';
import { Checkbox } from './Checkbox';

import { cn } from '../../libs/cn';

type MultiSelectorProps = {
	values: string[];
	onValuesChange: (value: any) => void;
	loop?: boolean;
	disabled?: boolean;
} & React.ComponentPropsWithoutRef<typeof CommandPrimitive>;

interface MultiSelectContextProps {
	value: string[];
	onValueChange: (value: any) => void;
	open: boolean;
	setOpen: (value: boolean) => void;
	inputValue: string;
	setInputValue: React.Dispatch<React.SetStateAction<string>>;
	activeIndex: number;
	setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
	disabled?: boolean;
}

const MultiSelectContext = createContext<MultiSelectContextProps | null>(null);

const useMultiSelect = () => {
	const context = useContext(MultiSelectContext);
	if (!context) {
		throw new Error('useMultiSelect must be used within MultiSelectProvider');
	}
	return context;
};

const MultiSelector = ({
	values: value,
	onValuesChange: onValueChange,
	className,
	children,
	dir,
	disabled,
	...props
}: MultiSelectorProps) => {
	const [inputValue, setInputValue] = useState('');
	const [open, setOpen] = useState<boolean>(false);
	const [activeIndex, setActiveIndex] = useState<number>(-1);

	const onValueChangeHandler = useCallback(
		(val: string) => {
			if (value.includes(val)) {
				onValueChange(value.filter((item) => item !== val));
			} else {
				onValueChange([...value, val]);
			}
		},
		[onValueChange, value]
	);

	return (
		<MultiSelectContext.Provider
			value={{
				value,
				onValueChange: onValueChangeHandler,
				open,
				setOpen,
				inputValue,
				setInputValue,
				activeIndex,
				setActiveIndex,
				disabled,
			}}
		>
			<Command
				onBlur={() => setOpen(false)}
				className={cn(
					'overflow-visible bg-transparent flex flex-col w-40',
					className
				)}
				dir={dir}
				{...props}
			>
				{children}
			</Command>
		</MultiSelectContext.Provider>
	);
};

const MultiSelectorTrigger = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & {
		hideArrow?: boolean;
		placeholder?: string;
	}
>(({ className, hideArrow, placeholder, ...props }, ref) => {
	const { value, setOpen, open, disabled } = useMultiSelect();

	return (
		<div
			ref={ref}
			onClick={() => !disabled && setOpen(!open)}
			className={cn(
				'flex justify-between gap-2 min-h-10 w-full rounded-full items-center border border-black bg-transparent px-4 py-2 ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
				className
			)}
		>
			<div
				className={cn(
					'inline-block gap-1 whitespace-nowrap overflow-hidden text-ellipsis'
				)}
				{...props}
			>
				{value.length === 0 && placeholder && (
					<span className="text-grey-600">{placeholder}</span>
				)}
				{value.map((item, index) => (
					<span key={item}>
						{item}
						{index < value.length - 1 && ', '}
					</span>
				))}
			</div>
			{!hideArrow && <ResponsiveIcon icon={iconMap.arrowDown} />}
		</div>
	);
});

MultiSelectorTrigger.displayName = 'MultiSelectorTrigger';

const MultiSelectorContent = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ children }, ref) => {
	const { open } = useMultiSelect();
	return (
		<div ref={ref} className="relative top-2">
			{open && children}
		</div>
	);
});

MultiSelectorContent.displayName = 'MultiSelectorContent';

const MultiSelectorList = forwardRef<
	React.ElementRef<typeof CommandPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, children, ...props }, ref) => {
	return (
		<CommandList
			ref={ref}
			className={cn(
				'p-2 styled-scroll flex flex-col gap-2 rounded-md scrollbar-thin scrollbar-track-transparent transition-colors scrollbar-thumb-muted-foreground dark:scrollbar-thumb-muted scrollbar-thumb-rounded-lg w-full absolute bg-background shadow-md z-30 top-0 bg-white',
				className
			)}
			{...props}
		>
			{children}
			<CommandEmpty>
				<span className="text-muted-foreground">No results found</span>
			</CommandEmpty>
		</CommandList>
	);
});

MultiSelectorList.displayName = 'MultiSelectorList';

const MultiSelectorItem = forwardRef<
	React.ElementRef<typeof CommandPrimitive.Item>,
	{
		value: string;
		checkBoxClassName?: string;
		color?: string;
	} & React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, checkBoxClassName, color, value, children, ...props }, ref) => {
	const { value: Options, onValueChange, setInputValue } = useMultiSelect();

	const mousePreventDefault = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const isIncluded = Options.includes(value);
	return (
		<CommandItem
			ref={ref}
			{...props}
			onSelect={() => {
				onValueChange(value);
				setInputValue('');
			}}
			className={cn(
				'rounded-md cursor-pointer px-2 py-1 transition-colors flex gap-4',
				className,
				props.disabled && 'opacity-50 cursor-not-allowed'
			)}
			onMouseDown={mousePreventDefault}
		>
			<Checkbox
				checked={isIncluded}
				className={checkBoxClassName}
				color={color}
			/>
			{children}
		</CommandItem>
	);
});

MultiSelectorItem.displayName = 'MultiSelectorItem';

export {
	MultiSelector,
	MultiSelectorTrigger,
	MultiSelectorContent,
	MultiSelectorList,
	MultiSelectorItem,
};
