import { CustomRouteObject } from '../../../routes/router';
import { iconMap } from '../../../theme/Icons';
import { DialogTitle } from '../../atoms/Dialog';
import ResponsiveIcon from '../../atoms/Icon';
import { Sheet, SheetContent, SheetTrigger } from '../../atoms/sheet';
import Sidebar from './Sidebar';

interface MobileSidebarProps {
	routes: CustomRouteObject[];
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({ routes }) => {
	return (
		<Sheet>
			<SheetTrigger className="md:hidden hover:opacity-75 transition">
				<ResponsiveIcon icon={iconMap.burger} className="h-7 w-7" />
			</SheetTrigger>

			<SheetContent side="left" className="p-0">
				<DialogTitle className="hidden">Menu</DialogTitle>
				<Sidebar routes={routes} />
			</SheetContent>
		</Sheet>
	);
};

export { MobileSidebar };
