import ResponsiveIcon from '../atoms/Icon';
import { Button } from '../atoms/Button';
import { iconMap } from '../../theme/Icons';
import DatePicker from 'react-datepicker';

export enum Range {
	today = 'day',
	week = 'week',
	month = 'month',
	custom = 'custom',
}

const filterOptions = {
	[Range.today]: 'Last 24h',
	[Range.week]: 'This Week',
	[Range.month]: 'This Month',
	[Range.custom]: 'Custom',
};

type DateFilterPanelType = {
	selectedRange: Range;
	onFilterChange: (range: Range) => void;
	customDateRange?: {
		startDate?: Date;
		endDate?: Date;
		onChange: (dates: (Date | null)[]) => void;
		visible: boolean;
		onClickOutside: () => void;
	};
};

const DateFilterPanel: React.FC<DateFilterPanelType> = ({
	selectedRange,
	onFilterChange,
	customDateRange,
}) => {
	return (
		<div className="flex flex-wrap gap-2.5">
			{Object.entries(filterOptions).map(([range, title]) => {
				return (
					<div className="relative" key={`${range}-${title}`}>
						<Button
							key={range}
							onClick={() => onFilterChange(range as Range)}
							variant={selectedRange === range ? 'default' : 'outline'}
							className="border-primary-100 text-sm"
						>
							{title}
							{range === Range.custom && (
								<ResponsiveIcon icon={iconMap.simpleCalendarIcon} />
							)}
						</Button>
						{range === Range.custom && customDateRange?.visible && (
							<div className="absolute z-[50] left-0">
								<DatePicker
									selected={customDateRange?.startDate}
									startDate={customDateRange?.startDate}
									onChange={customDateRange?.onChange}
									endDate={customDateRange?.endDate}
									selectsRange
									inline
									onClickOutside={customDateRange?.onClickOutside}
								/>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default DateFilterPanel;
