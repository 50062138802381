import {
	addresses,
	cryptocurrencies,
	currencies,
	guarantee,
	number,
	riskLevels,
	statuses,
} from './data';
import {
	getRandomDate,
	getRandomFromArray,
	getRandomHashString,
} from './helpers';

const _mock = {
	date: getRandomDate(new Date(2023, 0, 1), new Date()),
	hash: getRandomHashString(16),
	guarantee: (idx: number) => guarantee[idx] || getRandomFromArray(guarantee),
	wallet: (idx: number) => `Wallet ${number[idx]}`,
	status: (idx: number) => statuses[idx] || getRandomFromArray(statuses),
	riskLevel: (idx: number) => riskLevels[idx] || getRandomFromArray(riskLevels),
	address: (idx: number) => addresses[idx] || getRandomFromArray(addresses),
	number: {
		amount: (idx: number) => number[idx] || getRandomFromArray(number),
	},
	currency: {
		cryptocurrencies: (idx: number) =>
			cryptocurrencies[idx] || getRandomFromArray(cryptocurrencies),
		currencies: (idx: number) =>
			currencies[idx] || getRandomFromArray(currencies),
	},
};

export default _mock;
