import { useMemo } from 'react';
import { iconMap } from '../../../theme/Icons';
import { ResponsePaginated } from '../../../types/common';
import { Transaction } from '../../../types/transaction';
import { Button } from '../../atoms/Button';
import ResponsiveIcon from '../../atoms/Icon';
import { BaseTable } from '../../organisms/Table/BaseTable';

interface TransactionHistoryTableProps {
	data: Transaction[];
	columns: any;
	isLoading: boolean;
	error: string | null;
	paginationData: Omit<ResponsePaginated<Transaction>, 'data'> | null;
	page?: number;
	onPageChange?: (page: number) => void;
}

const PAGE_SIZE = 10;

const TransactionHistoryTable: React.FC<TransactionHistoryTableProps> = ({
	data,
	columns,
	isLoading,
	error,
	page = 1,
	paginationData,
	onPageChange,
}) => {
	const pagination = useMemo(() => {
		const pagination = paginationData;
		if (!pagination || !data?.length) {
			return null;
		}
		const firstItemIndex = (pagination.current_page - 1) * PAGE_SIZE + 1;
		const lastItemIndex = firstItemIndex + data.length - 1;
		const maxPage = Math.ceil(pagination.total_items / PAGE_SIZE);
		return {
			label: `Showing ${firstItemIndex}-${lastItemIndex} of ${pagination.total_items}`,
			maxPage,
		};
	}, [paginationData, data?.length]);

	return (
		<div className="flex flex-col gap-3">
			<div className="flex justify-between items-center">
				<p className="text-lg font-bold px-3">Transaction History</p>
				<Button
					onClick={() => {}}
					iconPosition="left"
					icon={<ResponsiveIcon icon={iconMap.report} className="h-5 w-5" />}
					className="bg-transparent border-none"
				>
					Report
				</Button>
			</div>
			{paginationData?.total_items &&
				paginationData.total_items > PAGE_SIZE && (
					<div className="flex gap-x-2 px-3">
						{paginationData.current_page > 1 && (
							<span
								className="underline cursor-pointer"
								onClick={() => onPageChange?.(Math.max(page - 1, 1))}
							>
								Prev
							</span>
						)}
						{paginationData.current_page < (pagination?.maxPage || 1) && (
							<span
								className="underline cursor-pointer"
								onClick={() =>
									onPageChange?.(Math.min(page + 1, pagination?.maxPage || 1))
								}
							>
								Next
							</span>
						)}

						<span className="pr-2">{pagination?.label}</span>
					</div>
				)}

			<BaseTable
				columns={columns}
				data={data}
				isLoading={isLoading}
				error={error}
			/>
		</div>
	);
};

export default TransactionHistoryTable;
