import { iconMap } from '../../../theme/Icons';
import ResponsiveIcon from '../../atoms/Icon';
import { Checkbox } from '../../atoms/Checkbox';

interface BackGuaranteeWidgetProps {
	checked: boolean;
	onChange: (checked: boolean) => void;
}

const BackGuaranteeWidget: React.FC<BackGuaranteeWidgetProps> = ({
	checked,
	onChange,
}) => {
	return (
		<div className="flex gap-2 border-b border-t border-primary py-2">
			<ResponsiveIcon icon={iconMap.secureFill} className="w-7 h-7" />
			<div className="flex flex-col gap-1">
				<h2 className="font-bold text-lg text-primary bg-gradient-primary bg-clip-text text-transparent-fill">
					Secure your Crypto with <br /> Money Back Guarantee
				</h2>
				<div className="flex items-center gap-2 text-white">
					<Checkbox
						checked={checked}
						onCheckedChange={onChange}
						color="black"
						iconClassNames="text-black"
						className="border-white data-[state=checked]:bg-white"
					/>
					<p className="font-bold">I’ll Take It!</p>
				</div>
			</div>
		</div>
	);
};

export default BackGuaranteeWidget;
