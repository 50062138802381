import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { cn } from '../../../libs/cn';
import ResponsiveIcon from '../../atoms/Icon';
import { iconMap } from '../../../theme/Icons';
import { IconType } from '../../../types/common';

type SidebarItemProps = {
	to?: string;
	icon: IconType;
	label: string;
	onClick?: () => void;
};

const SidebarItem: React.FC<SidebarItemProps> = ({
	to,
	icon,
	label,
	onClick,
}) => {
	const location = useLocation();
	const pathname = location.pathname;

	const isActive =
		(pathname === '/' && to === '/') ||
		pathname === to ||
		pathname?.startsWith(`${to}/`);

	return (
		<>
			{to ? (
				<NavLink
					to={to}
					className={() =>
						cn(
							`flex items-center text-base text-grey-200 p-2 transition-all w-full justify-between hover:text-primary py-4`,
							isActive && 'text-primary-100'
						)
					}
				>
					<div className="flex items-center justify-between w-full">
						<div className="flex items-center justify-between gap-x-3">
							<ResponsiveIcon
								icon={icon}
								className={cn('', isActive && 'text-primary-gradient')}
							/>
							<span className="text-grey-200">{label}</span>
						</div>
						{isActive && (
							<ResponsiveIcon icon={iconMap.menu} className="text-grey-900" />
						)}
					</div>
				</NavLink>
			) : (
				<button
					type="button"
					className={cn(
						`flex items-center gap-x-2 text-base text-grey-200 p-2 transition-all w-full justify-between hover:text-primary`
					)}
				>
					<div className="flex items-center pag-x-2" onClick={onClick}>
						<ResponsiveIcon icon={icon} />
						<span className="ml-3 text-grey-200">{label}</span>
					</div>
				</button>
			)}
		</>
	);
};

export { SidebarItem };
