import { useState } from 'react';
import { cn } from '../../libs/cn';
import { ResponseAsset, Wallet } from '../../types/wallets';
import WalletListItem from '../molecules/WalletListItem';
import { riskLevelColorMap } from '../pages/wallets/WalletsPage';
import WalletPopUp from '../molecules/WalletPopUp';

interface WalletsListProps {
	data: Wallet[];
	className?: string;
	goToWalletDetails: (id: string) => void;
	onDeleteClick?: (wallet: Wallet) => void;
	onEditTitleClick?: (wallet: Wallet) => void;
}

const WalletsList: React.FC<WalletsListProps> = ({
	data,
	className,
	goToWalletDetails,
	onDeleteClick,
	onEditTitleClick,
}) => {
	const [isOpenPopUp, setIsOpenPopUp] = useState<
		(Wallet & { assets?: ResponseAsset[] }) | null
	>(null);
	return (
		<div className={cn('grid gap-4', className)}>
			{data?.map((wallet) => {
				const factorBg = riskLevelColorMap[wallet?.riskLevel] || 'bg-gray-100';

				return (
					<WalletListItem
						key={wallet?.id}
						item={wallet}
						bgColor={factorBg}
						goToWalletDetails={goToWalletDetails}
						handleMigrate={(item, assets) =>
							setIsOpenPopUp({
								...item,
								assets,
							})
						}
						onDeleteClick={onDeleteClick}
						onEditTitleClick={onEditTitleClick}
					/>
				);
			})}
			{isOpenPopUp && (
				<WalletPopUp
					walletName={isOpenPopUp.name}
					riskLevel={isOpenPopUp.riskLevel}
					assets={isOpenPopUp.assets}
					walletAddress={isOpenPopUp.address}
					handleClose={() => setIsOpenPopUp(null)}
				/>
			)}
		</div>
	);
};

export default WalletsList;
