import { API_BASE_MEMBER_URL } from '../../config';
import { axiosInstanceApi } from '../../services/axios';
import {
	ResponseData,
	ResponsePaginatedNonIteration,
} from '../../types/common';
import { ResponseNotificationData } from '../../types/notification';

export type GetNotificationsParams = {
	pn?: number;
	ps?: number;
	unread?: boolean;
};

export const getNotificationsApi = async (
	params: GetNotificationsParams = {
		pn: 1,
		ps: 10,
	}
) => {
	const queryParams = {
		pn: `${params?.pn}`,
		ps: `${params?.ps}`,
		...(params?.unread === true && {
			unread: 'true',
		}),
		...(params?.unread === false && {
			unread: 'false',
		}),
	};
	const queryString = new URLSearchParams(queryParams).toString();

	try {
		const response = await axiosInstanceApi.get<
			ResponseData<ResponsePaginatedNonIteration<ResponseNotificationData>>
		>(`${API_BASE_MEMBER_URL}/v1/member/notification?${queryString}`, {});
		return response.data.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const putAcceptNotificationApi = async (id: string) => {
	try {
		const response = await axiosInstanceApi.put(
			`${API_BASE_MEMBER_URL}/v1/member/notification`,
			{
				notification_id: id,
			}
		);
		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};
