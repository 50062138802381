import transactionLogo from '../../assets/images/transaction.png';
import summaryLogo from '../../assets/images/report.png';
import walletLogo from '../../assets/images/Wallet.png';
import shieldLogo from '../../assets/images/shield.png';

const badgeData = {
	security: {
		label: 'Security',
		image: <img src={shieldLogo} alt="Shield Logo" />,
		className: 'text-[#6027A9]',
	},
	wallet: {
		label: 'Wallet',
		image: <img src={walletLogo} alt="Wallet Logo" />,
		className: 'text-[#1565c0]',
	},
	transaction: {
		label: 'Transaction',
		image: <img src={transactionLogo} alt="Transaction Logo" />,
		className: 'text-[#27A9A1]',
	},
	summary: {
		label: 'Summary',
		image: <img src={summaryLogo} alt="Summary Logo" />,
		className: 'text-[#6027A9]',
	},
};

export type NotificationTypeBadgeVariant = keyof typeof badgeData;
interface NotificationTypeBadgeProps {
	variant: NotificationTypeBadgeVariant;
	count?: number;
}

const NotificationTypeBadge = ({
	variant,
	count,
}: NotificationTypeBadgeProps) => {
	const badge = badgeData[variant];
	return (
		<>
			<div className={`${badge.className} flex items-center gap-x-2`}>
				<div className="w-6 h-6">{badge.image}</div>
				<div className="font-normal font-roboto text-base">
					{' '}
					{badge.label} {count}
				</div>
			</div>
		</>
	);
};

export { NotificationTypeBadge };
