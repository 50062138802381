import {
	MultiSelector,
	MultiSelectorContent,
	MultiSelectorItem,
	MultiSelectorList,
	MultiSelectorTrigger,
} from '../atoms/MultiSelect';

import { cn } from '../../libs/cn';
import { capitalizeFirstLetter } from '../../utils/string';
import { useCallback, useMemo } from 'react';

type CryptocurrencyProps = {
	selectedValue: string[];
	setSelectedValue: (v: string[]) => void;
	values: string[];
	className?: string;
	triggerClassName?: string;
	placeholder?: string;
};

const BaseMultiSelect: React.FC<CryptocurrencyProps> = ({
	selectedValue,
	setSelectedValue,
	values,
	className,
	triggerClassName,
	placeholder,
	...props
}) => {
	const memoizedValues = useMemo(() => values, [values]);

	const handleValuesChange = useCallback(
		(newValues: string[]) => {
			setSelectedValue(newValues);
		},
		[setSelectedValue]
	);

	return (
		<MultiSelector
			values={selectedValue}
			onValuesChange={handleValuesChange}
			className={cn('space-y-0', className)}
			loop
			{...props}
		>
			<MultiSelectorTrigger
				className={triggerClassName}
				placeholder={placeholder}
			/>
			<MultiSelectorContent>
				<MultiSelectorList>
					{memoizedValues.map((item, index) => (
						<MultiSelectorItem key={index} value={item as string}>
							{capitalizeFirstLetter(item)}
						</MultiSelectorItem>
					))}
				</MultiSelectorList>
			</MultiSelectorContent>
		</MultiSelector>
	);
};

export default BaseMultiSelect;
