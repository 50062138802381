import HorizontalBarChart from '../../organisms/Chart/HorizontalBarChart';
import BaseCard from '../../organisms/BaseCard';
import { Spinner } from '../../atoms/Spinner';
import { formatNumberWithPrefix } from '../../../utils/number';
import { EmptyPlaceholder } from '../../atoms/EmptyPlaceholder';
import { iconMap } from '../../../theme/Icons';

const Volume: React.FC<{
	data: any[];
	totalVolume?: number;
	isLoading?: boolean;
}> = ({ data, totalVolume, isLoading }) => {
	return (
		<BaseCard
			title={formatNumberWithPrefix(totalVolume || 0, '$')}
			subtitle="Total Volume"
			cardContentStyle="h-[240px]"
			cardStyle="relative"
			cardContentWrapperStyle="h-[240px]"
		>
			{isLoading && <Spinner />}
			<div className="grid grid-cols-1 gap-4 h-full">
				{!isLoading && (
					<>
						{data?.length ? (
							<HorizontalBarChart data={data} />
						) : (
							<EmptyPlaceholder className="flex-1 h-full">
								<EmptyPlaceholder.Icon
									icon={iconMap.noTransactionShadow}
									size={50}
								/>
								<EmptyPlaceholder.Description
									description="No data"
									className="text-md"
								/>
							</EmptyPlaceholder>
						)}
					</>
				)}
			</div>
		</BaseCard>
	);
};

export default Volume;
