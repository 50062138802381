type Chain = 'ETH' | 'BTC' | 'TRX' | 'UNKNOWN';

interface ChainPatterns {
	[key: string]: RegExp;
}

export interface WalletValidationResult {
	chain: Chain;
	address: string;
	isValid: boolean;
}

const CHAIN_PATTERNS: ChainPatterns = {
	ETH: /^0x[a-fA-F0-9]{40}$/,
	BTC: /^(bc1|tb1|bcrt1|[13])[a-zA-HJ-NP-Z0-9]{25,90}$/,
	TRX: /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
} as const;

export const getWalletChain = (address: string): WalletValidationResult => {
	if (!address || typeof address !== 'string') {
		throw new Error('Invalid input: Address must be a non-empty string');
	}

	const matchedChain = Object.entries(CHAIN_PATTERNS).find(([_, pattern]) =>
		pattern.test(address)
	);

	return {
		chain: (matchedChain ? matchedChain[0] : 'UNKNOWN') as Chain,
		address,
		isValid: Boolean(matchedChain),
	};
};

export const isValidAddress = (address: string, chain: Chain): boolean => {
	if (!CHAIN_PATTERNS[chain]) {
		throw new Error(`Unsupported chain: ${chain}`);
	}

	return CHAIN_PATTERNS[chain].test(address);
};
