import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { CurrentUserResponse, MfaOption } from '../../types/auth';

export interface AuthState {
	data: {
		user: CurrentUserResponse | null;
		isAuthenticated: boolean;
		isVerified: boolean;
		mfaToken?: string | null;
		oobCode?: string | null;
		selectedMfaOption?: MfaOption | null;
		accessToken?: string;
		idToken?: string;
	};
	loading: {
		login: boolean;
		verifyLogin: boolean;
		refreshToken: boolean;
		user: boolean;
		resendMfa: boolean;
	};
	error: {
		login: string | null;
		verifyLogin: string | null;
		user: string | null;
		resendMfa: string | null;
	};
}

const initialState: AuthState = {
	data: {
		user: null,
		isAuthenticated: false,
		isVerified: false,
	},
	loading: {
		login: false,
		verifyLogin: false,
		refreshToken: false,
		user: false,
		resendMfa: false,
	},
	error: {
		login: null,
		verifyLogin: null,
		user: null,
		resendMfa: null,
	},
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginRequest: (
			state,
			_action: PayloadAction<{
				email: string;
				password: string;
				signInOption: string;
			}>
		) => {
			state.loading.login = true;
			state.error.login = null;
		},
		loginSuccess: (
			state,
			action: PayloadAction<{
				mfaToken: string;
				oobCode: string;
				selectedMfaOption: MfaOption;
			}>
		) => {
			state.loading.login = false;
			state.data.isAuthenticated = true;
			state.data.mfaToken = action.payload.mfaToken;
			state.data.oobCode = action.payload.oobCode;
			state.data.selectedMfaOption = action.payload.selectedMfaOption;
		},
		loginFailure: (state, action: PayloadAction<string>) => {
			state.loading.login = false;
			state.error.login = action.payload;
		},
		//
		verifyLoginRequest: (
			state,
			_action: PayloadAction<{
				code: string[];
				mfaToken: string;
				oobCode?: string | null;
			}>
		) => {
			state.loading.verifyLogin = true;
			state.error.verifyLogin = null;
		},
		verificationSuccess: (
			state,
			action: PayloadAction<{
				accessToken: string;
				idToken: string;
			}>
		) => {
			//TODO: query current user and save to store
			state.loading.verifyLogin = false;
			state.data.isVerified = true;
			state.data.accessToken = action.payload.accessToken;
			state.data.idToken = action.payload.idToken;
			state.data.mfaToken = null;
			state.data.oobCode = null;
			state.data.selectedMfaOption = null;
		},
		verificationFailure: (state, action: PayloadAction<string>) => {
			state.loading.verifyLogin = false;
			state.error.verifyLogin = action.payload;
		},
		refreshTokenRequest: (state) => {
			state.loading.refreshToken = true;
		},
		refreshTokenSuccess: (
			state,
			action: PayloadAction<{
				accessToken: string;
				idToken?: string;
			}>
		) => {
			state.data.accessToken = action.payload.accessToken;
			state.data.idToken = action.payload.idToken;
			state.loading.refreshToken = false;

			state.data.isAuthenticated = true;
			state.data.isVerified = true;
		},
		refreshTokenFailure: (state) => {
			state.data.user = null;
			// state.data.isAuthenticated = false;
			// state.data.isVerified = false;
			state.loading.refreshToken = false;
		},
		sendMfaRequest: (
			state,
			_action: PayloadAction<{
				mfaToken: string;
				selectedMfaOption?: MfaOption;
			}>
		) => {
			state.loading.resendMfa = true;
		},
		sendMfaRequestSuccess: (state) => {
			state.loading.resendMfa = false;
		},
		sendMfaRequestFailure: (state) => {
			state.loading.resendMfa = false;
			state.error.resendMfa = null;
		},
		currentUserRequest: (state) => {
			state.loading.user = true;
		},
		currentUserSuccess: (state, action: PayloadAction<CurrentUserResponse>) => {
			state.data.user = action.payload;
			state.loading.user = false;
			state.error.user = null;
		},
		currentUserFailure: (state) => {
			state.loading.user = false;
			state.error.user = null;
		},
		logout: (state) => {
			state.data.user = null;
			state.data.isAuthenticated = false;
			state.data.isVerified = false;
		},
		clearError: (state) => {
			state.error.login = '';
			state.error.verifyLogin = '';
		},
	},
});

export const authActions = {
	loginRequest: authSlice.actions.loginRequest,
	loginSuccess: authSlice.actions.loginSuccess,
	loginFailure: authSlice.actions.loginFailure,
	refreshTokenRequest: authSlice.actions.refreshTokenRequest,
	refreshTokenSuccess: authSlice.actions.refreshTokenSuccess,
	refreshTokenFailure: authSlice.actions.refreshTokenFailure,

	sendMfaRequest: authSlice.actions.sendMfaRequest,
	sendMfaRequestSuccess: authSlice.actions.sendMfaRequestSuccess,
	sendMfaRequestFailure: authSlice.actions.sendMfaRequestFailure,

	currentUserRequest: authSlice.actions.currentUserRequest,
	currentUserSuccess: authSlice.actions.currentUserSuccess,
	currentUserFailure: authSlice.actions.currentUserFailure,

	//
	verificationRequest: authSlice.actions.verifyLoginRequest,
	verificationSuccess: authSlice.actions.verificationSuccess,
	verificationFailure: authSlice.actions.verificationFailure,
	logout: authSlice.actions.logout,
	clearError: authSlice.actions.clearError,
};

// Reducer
export default authSlice.reducer;
