import logo from '../../assets/logo.png';

import { cn } from '../../libs/cn';

interface LogoProps {
	className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
	return (
		<img
			src={logo}
			alt="Sailo Tech logo"
			className={cn('w-[90px] h-[52px]', className)}
		/>
	);
};

export default Logo;
