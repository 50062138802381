import toast from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
import { ReactNode } from 'react';
import { cn } from '../libs/cn';

interface ToastBoxProps {
	message: string;
	action?: ReactNode;
	sentiment: 'SUCCESS' | 'WARNING' | 'NEUTRAL';
	onClose: () => void;
}

const TextClassNameMap: Record<ToastBoxProps['sentiment'], string> = {
	SUCCESS: 'text-white-0',
	WARNING: 'text-white-0',
	NEUTRAL: 'text-white-0',
};

const BgColorClassNameMap: Record<ToastBoxProps['sentiment'], string> = {
	SUCCESS: 'bg-indigo-300',
	WARNING: 'bg-red-300',
	NEUTRAL: 'bg-black-600',
};

const ToastBox = ({ message, action, sentiment, onClose }: ToastBoxProps) => {
	const textClassName = TextClassNameMap[sentiment];
	const bgColorClassName = BgColorClassNameMap[sentiment];

	return (
		<div
			className={cn(
				'flex items-center gap-x-4 px-6 py-4 border border-[.1px] border-black-100 rounded-xl',
				textClassName,
				bgColorClassName
			)}
		>
			<div className="grow leading-6 text-base">{message}</div>
			{action && <div>{action}</div>}
			<IoMdClose onClick={onClose} className="w-4 h-4 cursor-pointer" />
		</div>
	);
};

export const useToast = () => {
	const success = (message: string) => {
		toast.custom(
			(t) => {
				return (
					<ToastBox
						message={message}
						sentiment={'SUCCESS'}
						onClose={() => toast.dismiss(t.id)}
					/>
				);
			},
			{
				position: 'top-right',
			}
		);
	};

	const warning = (message: string) => {
		toast.custom(
			(t) => {
				return (
					<ToastBox
						message={message}
						sentiment={'WARNING'}
						onClose={() => toast.dismiss(t.id)}
					/>
				);
			},
			{
				position: 'top-right',
			}
		);
	};

	const neutral = (message: string) => {
		toast.custom(
			(t) => {
				return (
					<ToastBox
						message={message}
						sentiment={'NEUTRAL'}
						onClose={() => toast.dismiss(t.id)}
					/>
				);
			},
			{
				position: 'top-right',
			}
		);
	};
	return {
		success,
		warning,
		neutral,
	};
};
