import React, { useCallback } from 'react';
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuCheckboxItem,
} from './DropdownMenu';
import ResponsiveIcon from './Icon';
import { iconMap } from '../../theme/Icons';
import { cn } from '../../libs/cn';

type MultiSelectDropdownProps = {
	selectedValue: string[];
	setSelectedValue: (values: string[]) => void;
	values: string[];
	className?: string;
	triggerClassName?: string;
	placeholder?: string;
};

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
	selectedValue,
	setSelectedValue,
	values,
	className,
	triggerClassName,
	placeholder,
}) => {
	const handleSelect = useCallback(
		(value: string) => {
			if (selectedValue.includes(value)) {
				setSelectedValue(selectedValue.filter((item) => item !== value));
			} else {
				setSelectedValue([...selectedValue, value]);
			}
		},
		[selectedValue, setSelectedValue]
	);

	return (
		<DropdownMenu>
			<DropdownMenuTrigger
				className={cn(
					'flex justify-between gap-2 min-h-10 w-full rounded-full items-center border border-gray-200 bg-white px-4 py-2 text-sm focus:outline-none',
					triggerClassName
				)}
			>
				<div className="inline-block gap-1 whitespace-nowrap overflow-hidden text-ellipsis">
					{selectedValue.length === 0 && placeholder && (
						<span className="text-gray-500">{placeholder}</span>
					)}
					{selectedValue.length > 0 && selectedValue.join(', ')}
				</div>
				<ResponsiveIcon icon={iconMap.arrowDown} className="text-gray-400" />
			</DropdownMenuTrigger>

			<DropdownMenuContent
				className={cn('min-w-[200px] p-0', className)}
				align="start"
			>
				{values.map((value) => (
					<DropdownMenuCheckboxItem
						key={value}
						checked={selectedValue.includes(value)}
						onSelect={(e) => {
							e.preventDefault();
							handleSelect(value);
						}}
						className="py-3 px-4 hover:bg-emerald-100 focus:bg-emerald-100 cursor-pointer text-base data-[state=checked]:bg-emerald-100"
					>
						<div
							className={cn({
								'pl-3': selectedValue.includes(value),
							})}
						>
							{value}
						</div>
					</DropdownMenuCheckboxItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
