import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import addWalletLogo from '../../assets/images/addwallet.png';
import { putWalletName } from '../../controllers/wallet/api';
import { usePromise } from '../../hooks/usePromise';
import { cn } from '../../libs/cn';
import { iconMap } from '../../theme/Icons';
import { Button } from '../atoms/Button';
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../atoms/Form';
import ResponsiveIcon from '../atoms/Icon';
import { Input } from '../atoms/Input';

type EditWalletTitlePopUpProps = {
	currentName?: string;
	address?: string;
	handleClose?: () => void;
	onSuccess?: () => void;
};

type FormValues = {
	nickName: string;
};

const formSchema = z.object({
	nickName: z.string(),
});

const EditWalletTitlePopup: React.FC<EditWalletTitlePopUpProps> = ({
	currentName,
	address,
	onSuccess,
	handleClose,
}) => {
	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		mode: 'onChange',
		defaultValues: {
			nickName: '',
		},
	});

	const { reset, handleSubmit } = form;

	const onClose = () => {
		reset();
		handleClose && handleClose();
	};

	const updateWalletNameRequest = usePromise({
		promiseFunction: async (params: FormValues) => {
			const { nickName } = params;
			if (!address) return;
			const response = await putWalletName({
				walletAddress: address,
				walletName: nickName,
			});
			return response;
		},
	});

	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		await updateWalletNameRequest.call(data);
		onSuccess && onSuccess();
		onClose();
	};

	return (
		<div
			className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10"
			onClick={onClose}
		>
			<div
				className="bg-white rounded-lg shadow-lg p-8 relative w-full max-w-lg space-y-8"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex border-b border-b-[#A7D0C7] pb-2 gap-x-2.5 items-center">
					<div>
						<img src={addWalletLogo} className="w-9	h-9" alt="Add Wallet Logo" />
					</div>
					<div className="font-roboto-condensed text-3xl	font-normal">
						Edit Wallet: <span className="text-grey-600">{currentName}</span>
					</div>
				</div>

				<FormProvider {...form}>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="flex flex-col gap-y-8"
					>
						<div className="flex gap-x-4 justify-between">
							<FormField
								control={form.control}
								name="nickName"
								render={({ field }) => (
									<FormItem className="grow">
										<FormLabel>Nick Name</FormLabel>
										<FormControl>
											<Input
												{...field}
												disabled={updateWalletNameRequest.pending}
												className="w-full"
												autoComplete="off"
												placeholder="Enter new wallet nick name"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<Button type="submit" disabled={updateWalletNameRequest.pending}>
							Save{' '}
							<ResponsiveIcon
								size={5}
								className={cn('animate-spin', {
									hidden: !updateWalletNameRequest.pending,
								})}
								icon={iconMap.spinner}
							/>
						</Button>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};

export { EditWalletTitlePopup };
