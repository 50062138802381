import { ColumnDef, ColumnMeta } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import useNotificationService from '../../controllers/notification/service';
import { ResponseNotification } from '../../types/notification';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import {
	NotificationTypeBadge,
	NotificationTypeBadgeVariant,
} from '../molecules/NotificationTypeBadge';
import {
	BaseTable,
	FilterOptionsType,
	TypeRangeFilterData,
} from '../organisms/Table/BaseTable';
import DateFilterPanel, { Range } from '../specialized/DateFilterPanel';
import { ColumnStyleType } from '../specialized/TransactionTable/columns';
import dayjs from 'dayjs';
import { iconMap } from '../../theme/Icons';
import { EmptyPlaceholder } from '../atoms/EmptyPlaceholder';
import { usePromise } from '../../hooks/usePromise';
import {
	getNotificationsApi,
	GetNotificationsParams,
	putAcceptNotificationApi,
} from '../../controllers/notification/api';
import { Spinner } from '../atoms/Spinner';

const PAGE_SIZE = 10;

const NotificationsPage: React.FC = () => {
	const { data, getNotifications } = useNotificationService();

	// const TransactionTimeRangeMap = {
	// 	[Range.today]: TransactionTimeRange.day,
	// 	[Range.month]: TransactionTimeRange.month,
	// 	[Range.week]: TransactionTimeRange.week,
	// 	[Range.custom]: null,
	// };
	const [selectedRange, setSelectedRange] = useState<Range>(Range.today);
	const [activeFilter, setActiveFilter] = useState<'all' | 'new' | 'read'>(
		'all'
	);
	const [pagination, setPagination] = useState({
		page: 1,
		size: 10,
	});

	const getNotificationsRequest = usePromise({
		promiseFunction: async (params?: GetNotificationsParams) => {
			const response = await getNotificationsApi(params);
			return response;
		},
	});

	const refetchNotifications = () => {
		getNotificationsRequest.call({
			pn: pagination.page,
			ps: pagination.size,
			unread: activeFilter === 'all' ? undefined : activeFilter === 'new',
		});
	};

	useEffect(() => {
		refetchNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilter, pagination]);

	const handleFilterChange = (range: Range) => {
		setSelectedRange(range);
	};

	const acceptNotificationRequest = usePromise({
		promiseFunction: async (id: string) => {
			const response = await putAcceptNotificationApi(id);
			return response;
		},
	});

	const columns: (ColumnDef<
		ResponseNotification,
		string | Record<string, string>
	> & {
		style?: ColumnStyleType;
		meta?: ColumnMeta<
			{
				filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
			},
			unknown
		>;
	})[] = [
		{
			accessorKey: 'read',
			header: '',
			cell: (props) => (
				<div className="w-fit">
					{!props.getValue<boolean>() ? (
						<div className="bg-gradient-primary border border-primary rounded-full py-1 px-4">
							New
						</div>
					) : (
						<div className="text-sm">Read</div>
					)}
				</div>
			),
			size: 50,
		},
		{
			accessorKey: 'notification_type',
			header: '',
			cell: (props) => (
				<NotificationTypeBadge
					variant={props.getValue<NotificationTypeBadgeVariant>()}
				/>
			),
			size: 120,
		},
		{
			accessorKey: 'timestamp',
			header: '',
			cell: (props) => (
				<div className="flex gap-x-4">
					<div>
						{dayjs.unix(props.getValue<number>()).format('DD MMM YYYY')}
					</div>
					<div>{dayjs.unix(props.getValue<number>()).format('H:mm')}</div>
				</div>
			),
			size: 120,
		},
		{
			accessorKey: 'title',
			header: '',
			cell: (props) => <div>{props.getValue<string>()}</div>,
			size: 130,
		},
		{
			accessorKey: 'message',
			header: '',
			cell: (props) => (
				<div className="whitespace-pre-line">{props.getValue<string>()}</div>
			),
			size: 130,
		},
		{
			accessorKey: 'id',
			header: '',
			size: 140,
			cell: (props) => {
				const id = props.getValue<string>();
				return (
					<div className="w-full h-[52px] flex items-center gap-x-2">
						<Button
							variant="outline"
							onClick={async () => {
								await acceptNotificationRequest.call(id);
								refetchNotifications();
								getNotifications();
							}}
						>
							Accept
						</Button>
					</div>
				);
			},
		},
	];

	const paginationData = useMemo(() => {
		const pagination = getNotificationsRequest.value;
		if (!pagination) {
			return null;
		}
		const firstItemIndex = (pagination.current_page - 1) * PAGE_SIZE + 1;
		const lastItemIndex =
			firstItemIndex + pagination.data.notifications.length - 1;
		const maxPage = Math.ceil(pagination.total_items / PAGE_SIZE);
		return {
			label: `Showing ${firstItemIndex}-${lastItemIndex} of ${pagination.total_items}`,
			maxPage,
		};
	}, [getNotificationsRequest.value]);

	return (
		<>
			{data.notification?.notifications.length === 0 ? (
				<EmptyPlaceholder className="flex-1">
					<EmptyPlaceholder.Icon icon={iconMap.noBellShadow} size={100} />
					<EmptyPlaceholder.Description description="There is no notifications to show at the moment" />
				</EmptyPlaceholder>
			) : (
				<div className="px-3 w-full h-full flex flex-col gap-y-5">
					<div className="flex justify-between items-center">
						<DateFilterPanel
							selectedRange={selectedRange}
							onFilterChange={handleFilterChange}
						/>
						<div className="flex items-center gap-x-3 rounded-full px-2.5 py-1.5 bg-grey-300 h-fit">
							<div className="flex gap-x-5 pr-10">
								<Badge
									className="items-center cursor-pointer"
									onClick={() => setActiveFilter('new')}
									variant={activeFilter === 'new' ? undefined : 'outline'}
								>
									<div className="capitalize">New</div>
								</Badge>
								<Badge
									className="items-center cursor-pointer"
									variant={activeFilter === 'read' ? undefined : 'outline'}
									onClick={() => setActiveFilter('read')}
								>
									<div className="capitalize">Read</div>
								</Badge>
							</div>
							<Badge
								className="items-center"
								onClick={() => setActiveFilter('all')}
								variant={activeFilter === 'all' ? undefined : 'outline'}
							>
								<div className="capitalize">All</div>
							</Badge>
							<div className="flex  gap-x-3 items-center">
								<NotificationTypeBadge
									variant="security"
									count={
										getNotificationsRequest.value?.data
											.notification_type_summary.security
									}
								/>
								<NotificationTypeBadge
									variant="wallet"
									count={
										getNotificationsRequest.value?.data
											.notification_type_summary.wallet
									}
								/>
								<NotificationTypeBadge
									variant="transaction"
									count={
										getNotificationsRequest.value?.data
											.notification_type_summary.transaction
									}
								/>
								<NotificationTypeBadge
									variant="summary"
									count={
										getNotificationsRequest.value?.data
											.notification_type_summary.summary
									}
								/>
							</div>
						</div>
					</div>
					<div className="text-grey-600 text-sm">
						<span className="pr-2">{paginationData?.label}</span>
						<span
							className="underline cursor-pointer"
							onClick={() =>
								setPagination({
									page: Math.min(pagination.page + 1),
									size: paginationData?.maxPage || 1,
								})
							}
						>
							Next
						</span>
					</div>
					<div className="grow overflow-y-auto relative">
						{getNotificationsRequest.pending ? (
							<Spinner />
						) : (
							<BaseTable
								columns={columns}
								tableHeaderStyle="hidden"
								data={getNotificationsRequest.value?.data.notifications || []}
								isLoading={false}
								error={null}
								enableExpanding={false}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default NotificationsPage;
