import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { dashboardActions, DashboardState } from './slice';
import { TransactionTimeRange } from '../../types/transaction';

type TransactionServiceOperators = DashboardState & {
	loadTransactions: (params: {
		pn?: number;
		ps?: number;
		sort_order?: string;
		sort_by?: string;
	}) => void;
	loadTransactionsStats: (params?: {
		time_range?: TransactionTimeRange;
		start_date?: string;
		end_date?: string;
	}) => void;
	loadMonitoredWallets: () => void;
	loadVolume: () => void;
	byRiskLevel: {
		name: string;
		value: any;
		group: string;
		code: string;
	}[];
};

const useDashboardDataService = (): Readonly<TransactionServiceOperators> => {
	const dispatch = useAppDispatch();
	const dashboardState = useAppSelector((state) => state.transactions);

	const loadTransactions = useCallback(
		(params: {
			pn?: number;
			ps?: number;
			sort_order?: string;
			sort_by?: string;
		}) => {
			dispatch(dashboardActions.fetchTransactionsRequest(params));
		},
		[dispatch]
	);

	const loadTransactionsStats = useCallback(
		(params?: {
			time_range?: TransactionTimeRange;
			start_date?: string;
			end_date?: string;
		}) => {
			dispatch(dashboardActions.fetchStatsRequest(params));
		},
		[dispatch]
	);

	const loadMonitoredWallets = useCallback(() => {
		dispatch(dashboardActions.fetchMonitoredWalletsRequest());
	}, [dispatch]);

	const loadVolume = useCallback(() => {
		dispatch(dashboardActions.fetchVolumeRequest());
	}, [dispatch]);

	const byRiskLevel = useMemo(() => {
		if (!dashboardState?.data?.transactionStats?.current_period?.status) {
			return [];
		}
		const stats = dashboardState?.data.transactionStats?.current_period
			.status as any;

		const statKeys = Object.keys(stats);

		return (
			statKeys
				?.map((key) => {
					const stat = stats[key];
					return Object.keys(stat).map((statKey) => {
						return {
							name: `${statKey} risk`,
							value: stat[statKey],
							group: key,
							code: statKey,
						};
					});
				})
				.flat() || []
		);
	}, [dashboardState?.data?.transactionStats]);

	return {
		data: dashboardState.data,
		loading: dashboardState.loading,
		error: dashboardState.error,
		loadTransactions,
		loadTransactionsStats,
		loadMonitoredWallets,
		loadVolume,
		byRiskLevel,
	};
};

export default useDashboardDataService;
