import { type SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';

import { dashboardActions } from './slice';
import {
	getMonitoredWalletsApi,
	getTransactionsApi,
	getTransactionsStatsApi,
	getVolumesApi,
} from './api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ResponseData, ResponsePeriods } from '../../types/common';
import {
	ResponseTransactionStats,
	TransactionTimeRange,
} from '../../types/transaction';

// Worker Sagas
function* fetchTransactions(
	action: PayloadAction<{
		pn?: number;
		ps?: number;
		sort_order?: string;
		sort_by?: string;
	}>
): SagaIterator {
	try {
		const response = yield call(getTransactionsApi, action.payload);
		yield put(dashboardActions.fetchTransactionsSuccess(response));
	} catch (error) {
		yield put(
			dashboardActions.fetchTransactionsFailure('Failed to load transactions') // set custom error message
		);
	}
}

function* fetchTransactionsStats(
	action: PayloadAction<{
		time_range?: TransactionTimeRange;
		start_date?: string;
		end_date?: string;
	}>
): SagaIterator {
	try {
		const response: ResponseData<
			ResponsePeriods<ResponseTransactionStats, ResponseTransactionStats>
		> = yield call(getTransactionsStatsApi, action.payload);
		yield put(dashboardActions.fetchStatsSuccess(response.data));
	} catch (error) {
		yield put(
			dashboardActions.fetchStatsFailure('Failed to load transactions stats')
		);
	}
}

function* fetchMonitoredWallets(): SagaIterator {
	try {
		const response = yield call(getMonitoredWalletsApi);
		yield put(dashboardActions.fetchMonitoredWalletsSuccess(response.data));
	} catch (error) {
		yield put(
			dashboardActions.fetchMonitoredWalletsFailure(
				'Failed to load monitored wallets'
			)
		);
	}
}

function* fetchVolume(): SagaIterator {
	try {
		const response = yield call(getVolumesApi);
		yield put(dashboardActions.fetchVolumeSuccess(response.data));
	} catch (error) {
		yield put(
			dashboardActions.fetchVolumeFailure('Failed to load volume stats')
		);
	}
}

// Watcher Saga
function* transactionWatcherSaga(): SagaIterator {
	yield takeLatest(
		dashboardActions.fetchTransactionsRequest.type,
		fetchTransactions
	);
	yield takeLatest(
		dashboardActions.fetchStatsRequest.type,
		fetchTransactionsStats
	);
	yield takeLatest(
		dashboardActions.fetchMonitoredWalletsRequest.type,
		fetchMonitoredWallets
	);
	yield takeLatest(dashboardActions.fetchVolumeRequest.type, fetchVolume);
	// yield takeLatest(
	// 	dashboardActions.fetchTransactionStats.type,
	// 	fetchTransactionStats
	// );
}

export default transactionWatcherSaga;
