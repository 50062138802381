import { RiskLvl } from '../types/transaction';

export const getChartBgColor = (code: string): { color: string[] } => {
	const flag = code?.toLowerCase();

	switch (flag) {
		case RiskLvl.critical:
			return { color: ['#A92727', '#C22A2A', '#AC1515'] };
		case RiskLvl.medium:
			return { color: ['#F0C737', '#FFD850'] };
		case RiskLvl.high:
			return { color: ['#E15136', '#EC4B2E', '#F04121'] };
		case RiskLvl.low:
			return { color: ['#1CE7DC', '#1CE7AC', '#1CE7A2'] };
		default:
			return { color: ['transparent'] };
	}
};
