import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import addWalletLogo from '../../assets/images/addwallet.png';
import { postNewWallet } from '../../controllers/wallet/api';
import useWalletsService from '../../controllers/wallets/service';
import { usePromise } from '../../hooks/usePromise';
import { cn } from '../../libs/cn';
import { iconMap } from '../../theme/Icons';
import { Button } from '../atoms/Button';
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../atoms/Form';
import ResponsiveIcon from '../atoms/Icon';
import { Input } from '../atoms/Input';
import { MultiSelectDropdown } from '../atoms/MultiselectDropdown';

type NewWalletPopUpProps = {
	handleClose?: () => void;
	onSuccess?: () => void;
};

type FormValues = {
	address: string;
	nickName: string;
	chain: string[];
};

const formSchema = z
	.object({
		nickName: z.string(),
		chain: z.array(z.string()),
		address: z.string().min(1, 'Address is required'),
	})
	.refine(
		(data: any) => {
			const patterns = {
				ETH: /^0x[a-fA-F0-9]{40}$/,
				BTC: /^(bc1|tb1|bcrt1|[13])[a-zA-HJ-NP-Z0-9]{25,90}$/,
				TRX: /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
			};
			const chain = data.chain?.[0]?.toUpperCase();
			const pattern = (patterns as any)[chain];
			if (!pattern) return true;

			return pattern.test(data.address);
		},
		{
			message: 'Invalid address format for selected coin',
			path: ['address'],
		}
	);

const NewWalletPopUp: React.FC<NewWalletPopUpProps> = ({
	onSuccess,
	handleClose,
}) => {
	const { data } = useWalletsService();

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		mode: 'onChange',
		defaultValues: {
			address: '',
			nickName: '',
			chain: [],
		},
	});

	const { reset, handleSubmit } = form;

	const onClose = () => {
		reset();
		handleClose && handleClose();
	};

	const createNewWallet = usePromise({
		promiseFunction: async (params: FormValues) => {
			const { address, chain, nickName } = params;
			const response = await postNewWallet({
				walletAddress: address,
				walletChain: chain[0],
				walletName: nickName,
			});
			return response;
		},
	});

	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		await createNewWallet.call(data);
		onSuccess && onSuccess();
		onClose();
	};

	return (
		<div
			className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10"
			onClick={onClose}
		>
			<div
				className="bg-white rounded-lg shadow-lg p-8 relative w-full max-w-lg space-y-8"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex border-b border-b-[#A7D0C7] pb-2 gap-x-2.5 items-center">
					<div>
						<img src={addWalletLogo} className="w-9	h-9" alt="Add Wallet Logo" />
					</div>
					<div className="font-roboto-condensed text-3xl	font-normal">
						Add New Wallet
					</div>
				</div>

				<FormProvider {...form}>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="flex flex-col gap-y-8"
					>
						<FormField
							control={form.control}
							name="address"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Address</FormLabel>
									<FormControl>
										<Input
											{...field}
											disabled={createNewWallet.pending}
											autoComplete="off"
											placeholder="Enter wallet address"
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex gap-x-4 justify-between">
							<FormField
								control={form.control}
								name="nickName"
								render={({ field }) => (
									<FormItem className="grow">
										<FormLabel>Nick Name</FormLabel>
										<FormControl>
											<Input
												{...field}
												disabled={createNewWallet.pending}
												className="w-full"
												autoComplete="off"
												placeholder="Enter wallet nick name"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="chain"
								render={({ field }) => (
									<FormItem className="w-1/3">
										<FormLabel>Chain</FormLabel>
										<FormControl>
											<MultiSelectDropdown
												className="max-h-64 overflow-y-auto"
												selectedValue={field.value || []}
												setSelectedValue={(values) => {
													if (createNewWallet.pending) return;
													const lastItem = values?.pop();
													field.onChange([lastItem]);
												}}
												values={
													data.supportedChains?.chains?.map((supportedChain) =>
														supportedChain.chain?.toUpperCase()
													) || []
												}
												triggerClassName="!border-input flex-1"
												placeholder="Select Chain"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<Button type="submit" disabled={createNewWallet.pending}>
							Add Wallet{' '}
							<ResponsiveIcon
								size={5}
								className={cn('animate-spin', {
									hidden: !createNewWallet.pending,
								})}
								icon={iconMap.spinner}
							/>
						</Button>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};

export { NewWalletPopUp };
