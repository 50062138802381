import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import useAuthService from '../../../controllers/auth/service';
import ForgotPasswordForm from '../../organisms/auth/Login/ForgotPasswordForm';
import LoginForm, { formSchema } from '../../organisms/auth/Login/LoginForm';
import { TSignInOption } from '../../organisms/auth/Login/SignInOptions';

const LoginPage: React.FC = () => {
	const navigate = useNavigate();
	const {
		login,
		data: { isAuthenticated },
	} = useAuthService();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [credentials, setCredentials] = useState({ email: '', password: '' });
	const [showFogetPassword, setShowForgetPassword] = useState(false);

	const onCredentials = (values: z.infer<typeof formSchema>) => {
		const { email, password } = values;
		if (email && password) {
			setCredentials({ email, password });
			onLoginFormSubmit(email, password, 'digital-code');
		}
	};

	const onLoginFormSubmit = async (
		email: string,
		password: string,
		signInOption?: TSignInOption
	) => {
		try {
			// const { email, password } = credentials;
			if (signInOption === 'passkey' || !signInOption) {
				// TODO: go to third party page
				return;
			}
			login(email, password, signInOption);
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/auth/verify-code');
		}
	}, [isAuthenticated, navigate]);

	const loginStep = (
		<>
			<div className="flex flex-col gap-3">
				<h2 className="text-5xl font-bold">Log In</h2>
				<div>
					<p className="leading-5">Get a verification code to access your</p>
					<p>SailoTech account</p>
				</div>
			</div>

			<LoginForm
				onCredentials={onCredentials}
				onForgotPasswordClick={() => setShowForgetPassword(true)}
			/>
		</>
	);
	const forgotPasswordStep = (
		<>
			<div className="flex flex-col gap-3">
				<h2 className="text-5xl font-bold">Forgot Password</h2>
				<div>
					<p className="leading-5">Get a verification code to access your</p>
					<p>SailoTech account</p>
				</div>
			</div>

			<ForgotPasswordForm
				onBackButtonClick={() => setShowForgetPassword(false)}
				onComplete={() => setShowForgetPassword(false)}
			/>
		</>
	);

	// const optionsStep = (
	// 	<>
	// 		<div className="flex flex-col gap-3">
	// 			<h2 className="text-5xl font-bold">Sign in options.</h2>
	// 		</div>
	// 		<SignInOptions
	// 			onLoginFormSubmit={(selectedOption) =>
	// 				onLoginFormSubmit(
	// 					credentials.email,
	// 					credentials.password,
	// 					selectedOption
	// 				)
	// 			}
	// 		/>
	// 	</>
	// );

	if (showFogetPassword) {
		return forgotPasswordStep;
	}

	return loginStep;
};

export default LoginPage;
