import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
	ResponseTransactionStats,
	Transaction,
	TransactionTimeRange,
} from '../../types/transaction';
import { PieChartData } from '../../components/organisms/Chart/PieChart';
import { ResponsePaginated, ResponsePeriods } from '../../types/common';
import { MakeOptional } from '../../types/util';

export type DashboardState = {
	data: {
		transactions: Transaction[];
		transactionStats?: ResponsePeriods<
			ResponseTransactionStats,
			ResponseTransactionStats
		>;
		wallets: any[];
		volume: any[]; // TODO: define type when they are defined
		pagination?: Omit<ResponsePaginated<Transaction>, 'data'>;
		stats: any[];
		quickTransaction: any[];
	};
	loading: {
		transactions: boolean;
		transactionStats: boolean;
		wallets: boolean;
		volume: boolean;
		stats: boolean;
		quickTransaction: boolean;
	};
	error: {
		transactions: string | null;
		transactionStats: string | null;
		wallets: string | null;
		volume: string | null;
		quickTransaction: string | null;
	};
};

const initialState: DashboardState = {
	data: {
		transactions: [],
		wallets: [],
		volume: [],
		stats: [],
		quickTransaction: [],
	},
	loading: {
		transactions: false,
		transactionStats: false,
		wallets: false,
		volume: false,
		stats: false,
		quickTransaction: false,
	},
	error: {
		transactions: null,
		transactionStats: null,
		wallets: null,
		volume: null,
		quickTransaction: null,
	},
};

const dashboardSlice = createSlice({
	name: 'transactions',
	initialState,
	reducers: {
		fetchTransactionsRequest: (
			state,
			_action: PayloadAction<{
				pn?: number;
				ps?: number;
				sort_order?: string;
				sort_by?: string;
			}>
		) => {
			state.loading.transactions = true;
			state.error.transactions = null;
		},
		fetchTransactionsSuccess: (
			state,
			action: PayloadAction<ResponsePaginated<Transaction>>
		) => {
			state.loading.transactions = false;
			state.data.transactions = action.payload.data;
			const paginationData = { ...action.payload } as MakeOptional<
				ResponsePaginated<Transaction>,
				'data'
			>;
			delete paginationData.data;
			state.data.pagination = paginationData;
		},
		fetchTransactionsFailure: (state, action: PayloadAction<string>) => {
			state.loading.transactions = false;
			state.error.transactions = action.payload;
		},
		// stats
		fetchStatsRequest: (
			state,
			_action: PayloadAction<
				| {
						time_range?: TransactionTimeRange;
						start_date?: string;
						end_date?: string;
				  }
				| undefined
			>
		) => {
			state.loading.transactionStats = true;
			state.error.transactionStats = null;
		},
		fetchStatsSuccess: (
			state,
			action: PayloadAction<
				ResponsePeriods<ResponseTransactionStats, ResponseTransactionStats>
			>
		) => {
			state.loading.transactionStats = false;
			state.data.transactionStats = action.payload;
		},
		fetchStatsFailure: (state, action: PayloadAction<string>) => {
			state.loading.transactionStats = false;
			state.error.transactionStats = action.payload;
		},
		//
		fetchMonitoredWalletsRequest: (state) => {
			state.loading.wallets = true;
			state.error.wallets = null;
		},
		fetchMonitoredWalletsSuccess: (
			state,
			action: PayloadAction<PieChartData[]>
		) => {
			state.loading.wallets = false;
			state.data.wallets = action.payload;
		},
		fetchMonitoredWalletsFailure: (state, action: PayloadAction<string>) => {
			state.loading.wallets = false;
			state.error.wallets = action.payload;
		},
		//
		fetchVolumeRequest: (state) => {
			state.loading.volume = true;
			state.error.volume = null;
		},
		fetchVolumeSuccess: (state, action: PayloadAction<PieChartData[]>) => {
			state.loading.volume = false;
			state.data.volume = action.payload;
		},
		fetchVolumeFailure: (state, action: PayloadAction<string>) => {
			state.loading.volume = false;
			state.error.volume = action.payload;
		},
		// fetchTransactionStats: (state) => {
		// 	state.loading.volume = true;
		// 	state.error.volume = null;
		// },
		// fetchTransactionStatsSuccess: (
		// 	state,
		// 	action: PayloadAction<PieChartData[]>
		// ) => {
		// 	state.loading.volume = false;
		// 	state.data.volume = action.payload;
		// },
		// fetchTransactionStatsFailure: (state, action: PayloadAction<string>) => {
		// 	state.loading.volume = false;
		// 	state.error.volume = action.payload;
		// },
	},
});

export const dashboardActions = {
	fetchTransactionsRequest: dashboardSlice.actions.fetchTransactionsRequest,
	fetchTransactionsSuccess: dashboardSlice.actions.fetchTransactionsSuccess,
	fetchTransactionsFailure: dashboardSlice.actions.fetchTransactionsFailure,
	//
	fetchStatsRequest: dashboardSlice.actions.fetchStatsRequest,
	fetchStatsSuccess: dashboardSlice.actions.fetchStatsSuccess,
	fetchStatsFailure: dashboardSlice.actions.fetchStatsFailure,
	//
	fetchMonitoredWalletsRequest:
		dashboardSlice.actions.fetchMonitoredWalletsRequest,
	fetchMonitoredWalletsSuccess:
		dashboardSlice.actions.fetchMonitoredWalletsSuccess,
	fetchMonitoredWalletsFailure:
		dashboardSlice.actions.fetchMonitoredWalletsFailure,
	//
	fetchVolumeRequest: dashboardSlice.actions.fetchVolumeRequest,
	fetchVolumeSuccess: dashboardSlice.actions.fetchVolumeSuccess,
	fetchVolumeFailure: dashboardSlice.actions.fetchVolumeFailure,
	//
	// fetchTransactionStats: dashboardSlice.actions.fetchTransactionStats,
	// fetchTransactionStatsSuccess:
	// 	dashboardSlice.actions.fetchTransactionStatsSuccess,
	// fetchTransactionStatsFailure:
	// 	dashboardSlice.actions.fetchTransactionStatsFailure,
};

// Reducer
export default dashboardSlice.reducer;
