import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '../../../atoms/Button';

import { forgotPasswordApi } from '../../../../controllers/auth/api';
import { usePromise } from '../../../../hooks/usePromise';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../../atoms/Form';
import { Input } from '../../../atoms/Input';

export const formSchema = z.object({
	email: z.string().email(),
});

interface ForgotPasswordFormProps {
	onBackButtonClick?: () => void;
	onComplete?: () => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
	onBackButtonClick,
	onComplete,
}) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		mode: 'onChange',
		defaultValues: {
			email: '',
		},
	});

	const { isSubmitting, isValid } = form.formState;

	const forgotPasswordRequest = usePromise({
		promiseFunction: async (email) => {
			const response = await forgotPasswordApi(email);

			return response.data;
		},
	});

	const onSubmit = async (data: { email: string }) => {
		await forgotPasswordRequest.call(data.email);
		onComplete?.();
	};

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="flex flex-col gap-8 h-full 3xl:gap-14 justify-between"
			>
				<div className="flex flex-col gap-8 3xl:gap-16">
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem className="space-y-1">
								<div className="flex flex-col gap-y-4">
									<FormLabel>Email</FormLabel>
									<FormControl>
										<Input
											className="py-3.5 border-black"
											disabled={isSubmitting}
											{...field}
											onChange={(e) => {
												field.onChange(e);
											}}
										/>
									</FormControl>
								</div>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>

				<div className="flex gap-x-4">
					<Button
						size={'lg'}
						type="button"
						className="bg-white flex-1"
						onClick={onBackButtonClick}
					>
						Back
					</Button>
					<Button
						size={'lg'}
						type="submit"
						className="bg-white flex-1"
						disabled={!isValid || isSubmitting}
						loading={forgotPasswordRequest.pending}
					>
						{isSubmitting ? 'loading' : 'Forgot Password'}
					</Button>
				</div>
			</form>
		</Form>
	);
};

export default ForgotPasswordForm;
