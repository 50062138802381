import {
	Bar,
	BarChart,
	Cell,
	LabelList,
	Legend,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import { Props } from 'recharts/types/component/Label';
import { LegendWrapper } from './legends/LegendWrapper';

const chartColorMap: Record<string, string> = {
	last_24: 'url(#baseChartColor)',
	prev_matching_period: 'url(#baseChartColor)',
	total: 'url(#baseChartBackground)',
};

const renderTopLabel = (props: Props) => {
	const { x, y = 0 } = props;

	return (
		<text
			x={x}
			y={+y - 20}
			fill="#000"
			textAnchor="start"
			dominantBaseline="middle"
			className="tracking-wide font-bold"
		>
			{props.name}
		</text>
	);
};

const renderLeftLabel = (props: Props) => {
	const { x = 0, y = 0, width = 0, value } = props;
	const offset = -20;

	return (
		<text
			x={+x + +width + offset}
			y={+y + 35}
			fill="#000"
			textAnchor="start"
			dominantBaseline="middle"
			className="text-sm"
		>
			{value}
		</text>
	);
};

type HorizontalBarChartProps = {
	data: { name: string; value: number; type: string; total: number }[];
};

const CustomLegend = () => {
	return (
		<LegendWrapper>
			<div className="flex items-center">
				<div
					className="w-[16px] h-[16px] rounded-[4px] mr-1"
					style={{
						background:
							'linear-gradient(4.16deg, rgba(28, 231, 220, 0.3) 3.1%, rgba(28, 231, 172, 0.3) 73.87%, rgba(28, 231, 162, 0.3) 96.9%)',
					}}
				/>
				<span className="text-sm">Total Transactions</span>
			</div>
			<div className="flex items-center">
				<div
					className="w-[16px] h-[16px] rounded-[4px] mr-1"
					style={{
						background:
							'linear-gradient(4.16deg, #1CE7DC 3.1%, #1CE7AC 73.87%, #1CE7A2 96.9%)',
					}}
				/>
				<span className="text-sm">Money Back Guarantee</span>
			</div>
		</LegendWrapper>
	);
};

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({ data }) => {
	const mapTotal = data.map((item) => {
		return {
			...item,
			totalDiff: item.total - item.value,
		};
	});
	return (
		<ResponsiveContainer width="100%" className="card-wrapper">
			<BarChart data={mapTotal} layout="vertical" margin={{ right: 30 }}>
				<defs>
					<linearGradient id="baseChartColor" x1="0" y1="0" x2="1" y2="0">
						<stop offset="0%" stopColor="#1CE7DC" stopOpacity={0.8} />
						<stop offset="75.5%" stopColor="#1CE7AC" stopOpacity={0.8} />
						<stop offset="100%" stopColor="#1CE7A2" stopOpacity={0.8} />
					</linearGradient>
					<linearGradient id="baseChartBackground" x1="0" y1="0" x2="1" y2="0">
						<stop offset="0%" stopColor="#1CE7DC" stopOpacity={0.26} />
						<stop offset="75.5%" stopColor="#1CE7AC" stopOpacity={0.26} />
						<stop offset="100%%" stopColor="#1CE7A2" stopOpacity={0.26} />
					</linearGradient>
				</defs>
				<XAxis type="number" hide />
				<YAxis type="category" dataKey="String" hide />
				<Legend content={<CustomLegend />} />
				<Bar stackId="a" dataKey="value" barSize={18} radius={5}>
					<LabelList dataKey="name" content={renderTopLabel} />
					<LabelList dataKey="value" content={renderLeftLabel} />
					{data.map((entry) => {
						return (
							<Cell
								key={entry.name + entry.value}
								fill={chartColorMap[entry.type]}
							/>
						);
					})}
				</Bar>
				<Bar stackId="a" dataKey="totalDiff" barSize={18} radius={[0, 5, 5, 0]}>
					<LabelList dataKey="total" content={renderLeftLabel} />
					{data.map((entry) => {
						return (
							<Cell
								key={entry.name + entry.value}
								fill={chartColorMap['total']}
							/>
						);
					})}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default HorizontalBarChart;
