import { RiskLvl } from '../../types/transaction';

export const _wallets = [
	{
		id: 0,
		name: 'Wallet 1',
		address: 'bc1d42UNb54eBiGm0qEM0h6r2h8n532o9tjp186ns',
		cryptocurrencies: [
			{
				code: 'BTC',
				name: 'Bitcoin',
				amount: 32,
				canSend: true,
			},
			{
				code: 'ETH',
				name: 'Ethereum',
				amount: 25,
				canSend: false,
			},
			{
				code: 'USDT',
				name: 'Tether',
				amount: 17,
				canSend: true,
			},
			{
				code: 'XRP',
				name: 'Ripple',
				amount: 12,
				canSend: false,
			},
		],
		riskLevel: RiskLvl.critical,
		riskFactors: ['Factor 1', 'Factor 2', 'Factor 3'],
		canSend: false,
	},
	{
		id: 1,
		name: 'Wallet 2',
		address: 'bc1d42UNb54eBiGm0qEM0h6r2h8n532o9tjp186ns',
		cryptocurrencies: [
			{
				code: 'BTC',
				name: 'Bitcoin',
				amount: 32,
				canSend: true,
			},
			{
				code: 'ETH',
				name: 'Ethereum',
				amount: 25,
				canSend: true,
			},
			{
				code: 'USDT',
				name: 'Tether',
				amount: 17,
				canSend: true,
			},
		],
		riskLevel: RiskLvl.low,
		riskFactors: [],
		canSend: true,
	},
	{
		id: 2,
		name: 'Wallet 3',
		address: 'bc1d42UNb54eBiGm0qEM0h6r2h8n532o9tjp186ns',
		cryptocurrencies: [
			{
				code: 'BTC',
				name: 'Bitcoin',
				amount: 32,
				canSend: true,
			},
			{
				code: 'ETH',
				name: 'Ethereum',
				amount: 25,
				canSend: false,
			},
			{
				code: 'USDT',
				name: 'Tether',
				amount: 17,
				canSend: true,
			},
		],
		riskLevel: RiskLvl.high,
		riskFactors: ['Factor 1', 'Factor 2'],
		canSend: true,
	},
	{
		id: 3,
		name: 'Wallet 4',
		address: 'bc1d42UNb54eBiGm0qEM0h6r2h8n532o9tjp186ns',
		cryptocurrencies: [
			{
				code: 'BTC',
				name: 'Bitcoin',
				amount: 32,
				canSend: true,
			},
			{
				code: 'ETH',
				name: 'Ethereum',
				amount: 25,
				canSend: false,
			},
			{
				code: 'USDT',
				name: 'Tether',
				amount: 17,
				canSend: true,
			},
			{
				code: 'XRP',
				name: 'Ripple',
				amount: 12,
				canSend: false,
			},
		],
		riskLevel: RiskLvl.medium,
		riskFactors: ['Factor 1', 'Factor 2'],
		canSend: true,
	},
	{
		id: 4,
		name: 'Wallet 5',
		address: 'bc1d42UNb54eBiGm0qEM0h6r2h8n532o9tjp186ns',
		cryptocurrencies: [
			{
				code: 'BTC',
				name: 'Bitcoin',
				amount: 32,
				canSend: true,
			},
			{
				code: 'ETH',
				name: 'Ethereum',
				amount: 25,
				canSend: true,
			},
			{
				code: 'USDT',
				name: 'Tether',
				amount: 17,
				canSend: false,
			},
		],
		riskLevel: RiskLvl.low,
		riskFactors: [],
		canSend: true,
	},
	{
		id: 5,
		name: 'Wallet 6',
		address: 'bc1d42UNb54eBiGm0qEM0h6r2h8n532o9tjp186ns',
		cryptocurrencies: [
			{
				code: 'BTC',
				name: 'Bitcoin',
				amount: 32,
				canSend: false,
			},
			{
				code: 'ETH',
				name: 'Ethereum',
				amount: 25,
				canSend: false,
			},
			{
				code: 'USDT',
				name: 'Tether',
				amount: 17,
				canSend: true,
			},
		],
		riskLevel: RiskLvl.low,
		riskFactors: [],
		canSend: true,
	},
];
