import { useEffect } from 'react';
import useSendTransactionsService from '../../controllers/transactions/service';
import useWalletService from '../../controllers/wallet/service';
import { iconMap } from '../../theme/Icons';
import { ResponseAsset, Wallet } from '../../types/wallets';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import { Card, CardContent } from '../atoms/Card';
import ResponsiveIcon from '../atoms/Icon';
import { RiskLvl } from '../../types/transaction';

interface WalletListItemProps {
	item: Wallet;
	bgColor?: string;
	goToWalletDetails: (id: string) => void;
	onDeleteClick?: (wallet: Wallet) => void;
	onEditTitleClick?: (wallet: Wallet) => void;
	handleMigrate?: (item: Wallet, assets?: ResponseAsset[]) => void;
}

const WalletListItem: React.FC<WalletListItemProps> = ({
	item,
	goToWalletDetails,
	onDeleteClick,
	handleMigrate,
	onEditTitleClick,
}) => {
	const {
		getWalletAssets,
		data: { assets },
	} = useWalletService();

	const { supportedCoinsList } = useSendTransactionsService();

	useEffect(() => {
		if (!item.address || !!assets) return;

		getWalletAssets(item.address);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item.address]);

	return (
		<Card
			key={item.id}
			className="sm:py-2 sm:px-2 flex flex-col lg:flex-row items-center gap-4 hover:shadow-hover-lg hover:cursor-pointer transition-all"
			onClick={() => goToWalletDetails(item.id)}
		>
			<CardContent
				className={`grid lg:grid-cols-[30%,1fr,15%] gap-x-4 bg-wallets-${item.riskLevel}bg rounded-[10px] p-2 w-full shrink-0 lg:w-2/3 items-center`}
				// className={`flex flex-row items-center justify-between flex-grow bg-wallets-${item.riskLevel}bg rounded-[10px] p-2`}
			>
				<div className="flex items-center gap-x-2">
					<p className="text-2xl font-roboto-condensed capitalize">
						{item.name}
					</p>
					<div className="flex items-center justify-start w-48">
						<div className="flex gap-2 overflow-x-auto">
							{assets?.assets
								?.filter((asset) => {
									return supportedCoinsList.includes(
										asset?.symbol.toUpperCase()
									);
								})
								.map((item) => (
									<div
										key={item?.symbol}
										className="bg-white p-2 rounded-full w-9 h-9 bg-cover bg-center rounded-full overflow-hidden shrink-0"
										style={{
											backgroundImage: `url(${item.image_url || '/no-image.png'})`,
										}}
									/>
								))}
						</div>
					</div>
				</div>
				<div className="">
					<p>Wallet address</p>
					<p className="truncate font-bold">{item.address}</p>
				</div>
				<div className="flex items-center gap-1">
					<span>Risk Level</span>
					<Badge className="bg-white" variant={item?.riskLevel}>
						{item?.riskLevel}
					</Badge>
				</div>
			</CardContent>
			<div className="flex items-center justify-start text-grey-600">
				{item?.riskLevel === RiskLvl.critical
					? 'Wallet migration disabled'
					: 'Safely migrate your cryptocurrency to a new wallet address.'}
			</div>
			<div className="flex gap-1 xl:gap-6 items-center">
				{item?.riskLevel !== RiskLvl.critical && (
					<Button
						// disabled={!item.canSend}
						iconPosition="right"
						icon={<ResponsiveIcon icon={iconMap.arrow} className="w-4 h-5" />}
						onClick={(event) => {
							event.stopPropagation();
							handleMigrate?.(item, assets?.assets);
						}}
					>
						Migrate
					</Button>
				)}
				<div className="flex gap-2">
					<Button
						size={'icon'}
						variant={'icon'}
						icon={<ResponsiveIcon icon={iconMap.edit} className="w-4 h-5" />}
						className="bg-grey-table border-none"
						onClick={(e) => {
							e.stopPropagation();
							onEditTitleClick && onEditTitleClick(item);
						}}
					/>
					<Button
						size={'icon'}
						variant={'icon'}
						icon={
							<ResponsiveIcon
								icon={iconMap.delete}
								color="#7B7B7B"
								className="w-4 h-5"
							/>
						}
						className="bg-grey-table border-none"
						onClick={(e) => {
							e.stopPropagation();
							onDeleteClick && onDeleteClick(item);
						}}
					/>
				</div>
			</div>
		</Card>
	);
};

export default WalletListItem;
