export enum CRYPTOCURRENCY {
	BTC = 'BTC',
	ETH = 'ETH',
	USDT = 'USDT',
	BNB = 'BNB',
	SOL = 'SOL',
	XRP = 'XRP',
	TRX = 'TRX',
	MATIC = 'MATIC',
	NEAR = 'NEAR',
	DOT = 'DOT',
	LEO = 'LEO',
	ETC = 'ETC',
	CRO = 'CRO',
	ATOM = 'ATOM',
}

export enum CURRENCY {
	USD = 'USD',
	NZD = 'NZD',
	EUR = 'EUR',
	BOB = 'BOB',
	AUD = 'AUD',
	CAD = 'CAD',
	UAN = 'UAN',
}

export const cryptocurrencySignMap = {
	[CRYPTOCURRENCY.BTC]: '₿',
	[CRYPTOCURRENCY.ETH]: 'Ξ',
	[CRYPTOCURRENCY.USDT]: '₮',
	[CRYPTOCURRENCY.BNB]: 'Ƀ',
	[CRYPTOCURRENCY.SOL]: '◎',
	[CRYPTOCURRENCY.XRP]: '✕',
	[CRYPTOCURRENCY.TRX]: 'T',
	[CRYPTOCURRENCY.MATIC]: 'M',
	[CRYPTOCURRENCY.NEAR]: 'Ⓝ',
	[CRYPTOCURRENCY.DOT]: '⚪',
	[CRYPTOCURRENCY.LEO]: '🦁',
	[CRYPTOCURRENCY.ETC]: '⟠',
	[CRYPTOCURRENCY.CRO]: '₡',
	[CRYPTOCURRENCY.ATOM]: '⚛',
};

export const currencySignMap = {
	[CURRENCY.USD]: '$',
	[CURRENCY.NZD]: '$',
	[CURRENCY.EUR]: '€',
	[CURRENCY.BOB]: 'Bs.',
	[CURRENCY.AUD]: '$',
	[CURRENCY.CAD]: '$',
	[CURRENCY.UAN]: '₴',
};
